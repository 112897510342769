import * as React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 14.653c0-2.131-1.732-3.056-2.982-3.375 1.029-.371 2.227-1.896 1.744-3.713-.409-1.537-1.832-2.9-5.429-3.022V2h-1.666v2.5h-.834V2H8.667v2.5H4.5V7h1.362C6.597 7 7 7.477 7 8.17v7.407C7 16.292 6.578 17 5.842 17h-.899l-.416 2.482h4.14V22h1.666v-2.518h.834V22h1.666v-2.5c4.427 0 6.667-1.945 6.667-4.847zm-4.518-5.57c0 1.954-2.807 2.084-4.649 2.084V7c2.917 0 4.649.417 4.649 2.083zm-4.649 3.745V17c3.307 0 5.574-.313 5.574-2.097 0-1.913-2.449-2.075-5.574-2.075z"
        fill="#000"
        fillOpacity={0.5}
      />
    </svg>
  );
};

export default SvgComponent;
