export const palette = {
  lightgrey: {
    dark: "#eaedf0",
    main: "#fafbfc",
    sub: "",
    light: "",
    pale: "",
  },
  mediumgrey: {
    dark: "#B9C5CE",
    main: "#d9e0e5",
    sub: "#eaeef1",
    light: "",
    pale: "",
  },
  darkgrey: {
    dark: "#060607",
    main: "#3a4044",
    sub: "#748089",
    light: "#a1b2be",
    pale: "",
  },
  primary: {
    dark: "#025537",
    main: "#02794E",
    sub: "#50BF83",
    light: "#AEF5CA",
    pale: "#DEFFE5",
  },
  blue: {
    dark: "#201658",
    main: "#1D24CA",
    sub: "#98ABEE",
    light: "",
    pale: "",
  },
  bluegrey: {
    dark: "#313A45",
    main: "#465365",
    sub: "#737E91",
    light: "#d7dbe0",
    pale: "#f3f4f6",
  },
  orange: {
    dark: "#e84809",
    main: "#ff622e",
    sub: "#ff9966",
    light: "#ffd7b8",
    pale: "#ffede0",
  },
  red: {
    dark: "#E61244",
    main: "#f5405b",
    sub: "#fd7994",
    light: "#ffcfd7",
    pale: "#ffedf0",
  },
  green: {
    dark: "#018262",
    main: "#05a67b",
    sub: "#55c9a4",
    light: "#b7e6d5",
    pale: "#e2faeb",
  },
  cyan: {
    dark: "#0b868f",
    main: "#05aab0",
    sub: "#5bd0d4",
    light: "#b4edec",
    pale: "#e6faf9",
  },
  purple: {
    dark: "#8433DB",
    main: "#AB50FB",
    sub: "#D19BFD",
    light: "#E8CDFE",
    pale: "#FAF0FF",
  },
  white: {
    dark: "#fff",
    main: "#fff",
    sub: "#fff",
    light: "#fff",
    pale: "#fff",
  },
};

export type PaletteType = keyof typeof palette;
