import { requestAPI } from "src/apis/requestApi";
import {
  ClientWalletControllerApiFactory,
  WalletWithRequestDto,
  BalanceDto,
  Blockchain,
  ClientRequestApprovalPolicy,
  EstimatedFeeDto,
} from "src/__generate__/api";
import { AxiosPromise } from "axios";

const clientWalletControllerApiFactory = () =>
  ClientWalletControllerApiFactory(undefined, "", requestAPI());

export type Balance = BalanceDto;

export const getEstimatedFee = async (
  walletId: string,
): Promise<EstimatedFeeDto> => {
  const response =
    await clientWalletControllerApiFactory().getEstimatedFee1(walletId);
  return response.data;
};

export const getMasterWallets = async (): Promise<WalletWithRequestDto[]> => {
  const response = await clientWalletControllerApiFactory().getWallets1();
  return response.data;
};

export const getBalance = async (walletId: string): Promise<BalanceDto[]> => {
  const response =
    await clientWalletControllerApiFactory().getWalletBalances2(walletId);
  return (await response).data;
};

export const getMasterWalletById = async ({
  id,
}: {
  id: string;
}): Promise<WalletWithRequestDto> => {
  const response = await clientWalletControllerApiFactory().getWallet1(id);
  return response.data;
};

const prefixUrl = "/api/v1/client/wallets";

export type UpdateWalletPolicyType =
  | "transfer"
  | "address_book_creation"
  | "address_book_deletion";

export const updateWalletPolicy = async (request: {
  walletId: string;
  type: UpdateWalletPolicyType;
  passphrase: string;
  otpCode: string;
  policy: ClientRequestApprovalPolicy;
}): Promise<void> => {
  const { walletId, type, ...rest } = request;
  const byType: Record<UpdateWalletPolicyType, () => AxiosPromise<void>> = {
    transfer: () =>
      clientWalletControllerApiFactory().updateTransferApprovalPolicy1(
        walletId,
        rest,
      ),
    address_book_creation: () =>
      clientWalletControllerApiFactory().updateAddressBookCreationApprovalPolicy1(
        walletId,
        rest,
      ),
    address_book_deletion: () =>
      clientWalletControllerApiFactory().updateAddressBookDeletionApprovalPolicy1(
        walletId,
        rest,
      ),
  };
  await byType[type]();
};

export const changeWalletName = async (request: {
  walletId: string;
  name: string;
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { walletId, name, passphrase, otpCode } = request;
  await clientWalletControllerApiFactory().updateWalletName1(walletId, {
    newName: name,
    passphrase,
    otpCode,
  });
};

export const walletInitialized = async (walletId: string): Promise<void> => {
  await clientWalletControllerApiFactory().initializeWallet1(walletId);
};

export const validateAddress = async (request: {
  address: string;
  blockchain: Blockchain;
}): Promise<void> => {
  const { address, blockchain } = request;
  await clientWalletControllerApiFactory().validateAddress1({
    address,
    blockchain,
  });
};
