import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.59 12L6 16.59 7.41 18l6-6-6-6L6 7.41 10.59 12zM16 6v12h2V6h-2z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
