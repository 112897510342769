import _ from "lodash";
import { flow, getParent, Instance, types } from "mobx-state-tree";

import { Blockchain, ListedCoinProjection } from "src/__generate__/api";
import {
  getListedCoins,
  addListedCoin,
  deleteListedCoin,
} from "src/apis/organizations";

const DEFAULT_PAGE_SIZE = 15;

const OrganizationCoins = types
  .model("OrganizationCoins", {
    data: types.optional(types.map(types.frozen<ListedCoinProjection>()), {}),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      get coinViews() {
        return _.orderBy(
          Array.from(self.data.values()),
          ["createdAt", "blockchain", "name"],
          ["desc", "asc", "asc"],
        );
      },
      getCoinById(coinId: string) {
        return self.data.get(coinId);
      },
      get coinPaginationViews() {
        return _.slice(
          this.coinViews,
          self.page * self.size,
          (self.page + 1) * self.size,
        );
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
      self.data.clear();
    };

    const initialize = flow(function* () {
      clear();
      const parent = getParent<{
        selectedOrganizationId: string;
      }>(self);
      const orgId = parent.selectedOrganizationId;
      const response: RetrieveAsyncFunc<typeof getListedCoins> =
        yield getListedCoins(orgId);
      for (const coin of response) {
        self.data.set(coin.id ?? "", coin);
      }
      self.totalCount = Array.from(self.data).length;
    });

    const setPage = flow(function* ({
      page,
      size,
    }: {
      page: number;
      size?: number;
    }) {
      self.page = page;
      self.size = size ?? self.size;
    });

    const addCoin = flow(function* ({
      coinId,
      otpCode,
    }: {
      coinId: string;
      otpCode: string;
    }) {
      const parent = getParent<{
        selectedOrganizationId: string;
      }>(self);
      const orgId = parent.selectedOrganizationId;
      const coin: RetrieveAsyncFunc<typeof addListedCoin> = yield addListedCoin(
        {
          orgId,
          coinId,
          otpCode,
        },
      );
    });

    const removeCoin = flow(function* ({
      listedCoinId,
      otpCode,
    }: {
      listedCoinId: string;
      otpCode: string;
    }) {
      const parent = getParent<{
        selectedOrganizationId: string;
      }>(self);
      const orgId = parent.selectedOrganizationId;
      yield deleteListedCoin({
        orgId,
        listedCoinId,
        otpCode,
      });
    });

    return {
      initialize,
      setPage,
      addCoin,
      removeCoin,
    };
  });

export type IOrganizationCoins = Instance<typeof OrganizationCoins>;
export default OrganizationCoins;
