import React from "react";
import { CircularProgress, LinearProgress } from "@mui/material";
import styled from "@emotion/styled";
import { shouldForwardProp } from "src/themes/config";

export const CIRCULAR_PROGRESS_HEIGHT = "48px";

const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp,
})<{ $color: string }>`
  padding: 0;
  margin: 0;
  &.MuiLinearProgress-colorPrimary {
    background-color: ${({ $color }) => $color};
  }
  &.MuiLinearProgress-barColorPrimary {
    background-color: ${({ $color }) => $color};
  }
`;

const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp,
})<{ $color: string }>`
  padding: 0;
  margin: 0;
  &.MuiCircularProgress-colorPrimary {
    color: ${({ $color }) => `${$color}`};
  }
  &.MuiCircularProgress-colorSecondary {
    color: #ffffff;
  }
`;

export type ProgressProps = {
  className?: string;
  type?: "circular" | "linear";
  color: string;
  size?: "small" | "large" | number;
};

const Progress: React.FC<ProgressProps> = (props: ProgressProps) => {
  const { className, type = "circular", size = "large" } = props;
  const sizeMap: { [size: string]: string } = {
    large: "42px",
    small: "20px",
  };
  switch (type) {
    case "linear":
      return (
        <StyledLinearProgress className={className} $color={props.color} />
      );
    case "circular":
    default:
      return (
        <StyledCircularProgress
          className={className}
          thickness={size === "small" ? 7 : 5.5}
          size={
            ["small", "large"].some((sizeType) => sizeType === size)
              ? sizeMap[size]
              : size
          }
          $color={props.color}
        />
      );
  }
};

export default Progress;
