/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from "react";

import { ErrorCode, errorMessageByCode } from "src/libs/error";
import { testOTP } from "src/utils/regex";

type States = FormStates & FormErrorStates;

type FormStates = {
  otp: string;
};

type FormErrorStates = {
  errors: { [key in keyof FormStates]: string };
};

const MAX_LENGTH = 6;

const INITIAL_STATES = {
  otp: "",
  errors: {
    otp: "",
  },
};

function useOTP({
  defaultOTP,
  onSubmit,
}: {
  defaultOTP?: string;
  onSubmit: (otp: string) => any;
}) {
  const [form, setForm] = useState<States>({
    ...INITIAL_STATES,
    otp: defaultOTP ?? INITIAL_STATES.otp,
  });
  const disabledOTP = !(form.otp.length === MAX_LENGTH);

  const onChangeOTP = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      if (testOTP(value)) {
        setForm((prevForm) => {
          return {
            ...prevForm,
            otp: value,
          };
        });
      }
    },
    [],
  );

  const onSubmitOTP = useCallback(async () => {
    try {
      await onSubmit(form.otp);
      setForm(INITIAL_STATES);
    } catch (error: any) {
      if (error.status === ErrorCode.OTP_AUTHENTICATION_FAILED) {
        setForm((prevForm) => {
          return {
            ...prevForm,
            errors: {
              otp: errorMessageByCode(ErrorCode.OTP_AUTHENTICATION_FAILED),
            },
          };
        });
      }
    }
  }, [form.otp, onSubmit]);

  return {
    otpForm: form,
    disabledOTP,
    onChangeOTP,
    onSubmitOTP,
  };
}

export default useOTP;
