import { AxiosResponse } from "axios";
import _ from "lodash";
import qs from "qs";

export function parseQuery<T>(queryString: string): T | null {
  const queryStringLastIndex = queryString.lastIndexOf("?");
  if (queryStringLastIndex === -1) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryParams: any = qs.parse(
    queryString.substring(queryStringLastIndex + 1),
  );
  return queryParams as T;
}

export function stringifyQuery(params: Record<string, unknown>) {
  return qs.stringify(params, { arrayFormat: "repeat" });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeQueryParams(params?: Record<string, any>) {
  return !_.isEmpty(params) ? `?${stringifyQuery(params!)}` : "";
}

export const reactotronApiResponse = (source?: AxiosResponse) => {
  if (!source) {
    return;
  }
  const DEFAULT_CONTENT_TYPES_RX = /^(image)\/.*$/i;
  const ignoreContentTypes = DEFAULT_CONTENT_TYPES_RX;
  const config = source.config || {};

  const request = {
    url: `${config.baseURL ?? ""}${config.url}`,
    method: config.method,
    data: config.data || null,
    headers: config.headers,
    params: config.params || null,
  };
  const responseHeaders = source.headers || {};
  const contentType =
    responseHeaders["content-type"] || responseHeaders["Content-Type"];
  const useRealBody =
    (typeof source.data === "string" || typeof source.data === "object") &&
    !ignoreContentTypes.test(contentType || "");
  const body = useRealBody ? source.data : `~~~ skipped ~~~`;
  const response = {
    body,
    status: source.status,
    headers: responseHeaders,
  };
  return [request, response, 0];
};
