import _ from "lodash";
import { flow, types, getParent, isAlive, Instance } from "mobx-state-tree";
import { i18n, TFunction } from "next-i18next";

import { Balance, getBalance } from "src/apis/wallet";
import { getBalance as getBalanceV2 } from "src/apis/v2/wallet";
import { numberToBalanceFormat, isZeroBalance } from "src/utils/number";
import { includesForSearch } from "src/utils/string";
import { testHangul } from "src/utils/regex";
import { getBlockchainItemBy } from "src/utils/blockchain";
import { Blockchain, BalanceDto, CoinType } from "src/__generate__/api";
import { getRootStore } from "./StoreHelper";

type Variables = {
  symbolAndName: string;
};

export type BalanceItem = {
  id: string;
  coinId: string;
  balanceType: string;
  symbol: string;
  name: string;
  amount: string;
  spendableAmount: string;
  requestPendingAmount: string;
};

const DEFAULT_COIN_ID = "DEFAULT";
const DEFAULT_PAGE_SIZE = 15;

export const EMPTY_SYMBOL = "EMPTY";
export const getSelectedAllBalance: (t: TFunction) => BalanceDto = (
  t: TFunction,
) => ({
  coin: {
    id: "ALL",
    type: CoinType.Token,
    symbol: EMPTY_SYMBOL,
    name: "전체 코인",
    decimal: 18,
    address: "",
    blockchain: Blockchain.Ethereum,
  },
  amount: "0",
  withdrawableAmount: "0",
  processingAmount: "0",
});

const Balances = types
  .model("Balances", {
    balances: types.optional(types.map(types.frozen<Balance>()), {}),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
    isLoading: types.optional(types.boolean, true),
    variables: types.frozen<Variables>({
      symbolAndName: "",
    }),
    showOnlyMyCoin: types.optional(types.boolean, true),
  })
  .views((self) => {
    return {
      makeBalanceItem(item: BalanceDto) {
        const parent = getParent<{
          blockchain: Blockchain;
        }>(self);

        const { amount, withdrawableAmount, processingAmount, coin } = item;
        const { id: coinId, name, symbol, decimal } = coin;
        return {
          id: coinId,
          coinId,
          balanceType: coin.type === "TOKEN" ? "Token" : "Coin",
          symbol,
          name,
          amount: numberToBalanceFormat({
            coin: amount,
            blockchain: parent.blockchain,
            decimals: decimal,
            disabledFixed: true,
          }),
          spendableAmount: numberToBalanceFormat({
            coin: withdrawableAmount,
            blockchain: parent.blockchain,
            decimals: decimal,
            disabledFixed: true,
          }),
          requestPendingAmount: numberToBalanceFormat({
            coin: processingAmount,
            blockchain: parent.blockchain,
            decimals: decimal,
            disabledFixed: true,
          }),
        };
      },
    };
  })
  .views((self) => {
    return {
      get balancesByCoinId() {
        const byCoinId: Record<string, Balance> = {};
        const balances = Array.from(self.balances.values());
        for (const item of balances) {
          const key = item.coin.id ? String(item.coin.id) : DEFAULT_COIN_ID;
          byCoinId[key] = item;
        }
        return byCoinId;
      },
      get balanceViews() {
        return Array.from(self.balances.values());
      },
      get existsBalances() {
        return this.balanceViews.length !== 0;
      },
      get sortedBalances() {
        const parent = getParent<{
          blockchain: Blockchain;
        }>(self);
        const sorted = _.orderBy(
          _.filter(
            this.balanceViews,
            (item) =>
              _.upperCase(item.coin.type) === parent.blockchain.toString(),
          ),
          ["coinType", "name"],
        );
        const rest = _.orderBy(
          _.filter(
            this.balanceViews,
            (item) =>
              _.upperCase(item.coin.type) !== parent.blockchain.toString(),
          ),
          ["coinType", "name"],
        );
        return [...sorted, ...rest];
      },
      get filteredOnlyCoinBalanceViews() {
        const parent = getParent<{
          isUserWallet: boolean;
          blockchain: Blockchain;
        }>(self);
        const { blockchain } = parent;
        return this.sortedBalances.filter((item) => {
          const { amount, coin } = item;
          const { decimal } = coin;
          return !isZeroBalance({
            coin: amount,
            blockchain,
            decimals: decimal,
          });
        });
      },
      get balanceItems(): BalanceItem[] {
        return _.map(this.sortedBalances, (item) => self.makeBalanceItem(item));
      },
      get mainBalanceItem() {
        const t = i18n?.t.bind(i18n) as TFunction;
        const parent = getParent<{
          blockchain: Blockchain;
        }>(self);
        const balanceItem = this.balanceItems.find(
          (balance) =>
            balance.symbol === getBlockchainItemBy(parent.blockchain, t).unit,
        );
        if (!balanceItem) {
          return null;
        }
        return balanceItem;
      },
      get filteredBalanceItems(): BalanceItem[] {
        const { symbolAndName } = self.variables;
        const balances = self.showOnlyMyCoin
          ? this.filteredOnlyCoinBalanceViews
          : this.sortedBalances;
        const result = _.map(balances, (item) => self.makeBalanceItem(item));

        if (!_.trim(symbolAndName)) {
          return result;
        }
        if (testHangul(symbolAndName)) {
          return result.filter((item) =>
            includesForSearch(item.name, symbolAndName),
          );
        }
        return result.filter(
          (item) =>
            includesForSearch(item.symbol, symbolAndName) ||
            includesForSearch(item.name, symbolAndName),
        );
      },
      get balanceItemPaginationViews() {
        return _.slice(
          this.filteredBalanceItems,
          self.page * self.size,
          (self.page + 1) * self.size,
        );
      },
      get isSearch() {
        return Boolean(self.variables.symbolAndName);
      },
    };
  })
  .views((self) => {
    return {
      withdrawBalanceViews(coinId?: string) {
        if (!coinId) {
          return self.sortedBalances;
        }
        return self.sortedBalances.filter((item) => item.coin.id === coinId);
      },
      sortedBalancesIncludeAllBalance(t: TFunction) {
        return [getSelectedAllBalance(t), ...self.sortedBalances];
      },
      balanceBySymbol(symbol: string) {
        return self.balances.get(symbol);
      },
      sortedBalancesByExcludeSymbols(excludeSymbols: string[]) {
        const balances = self.sortedBalances;
        return balances.filter(
          (item) => !excludeSymbols.includes(item.coin.symbol),
        );
      },
      balanceByCoinId(coinId: string) {
        const key = coinId ? coinId : DEFAULT_COIN_ID;
        return self.balancesByCoinId[key];
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
      self.variables = {
        symbolAndName: "",
      };
    };

    const fetch = flow(function* (blockchain: Blockchain) {
      const parent = getParent<{
        id: string;
      }>(self);
      let getFn;
      if (getRootStore(self).blockchains?.checkV2Chain(blockchain)) {
        getFn = getBalanceV2;
      } else {
        getFn = getBalance;
      }
      const balances: RetrieveAsyncFunc<typeof getFn> = yield getFn(parent.id);
      self.isLoading = false;
      updateBalances(balances);
    });

    const isSameBalance = (balance1: Balance, balance2: Balance) => {
      return (
        balance1.amount === balance2.amount &&
        balance1.withdrawableAmount === balance2.withdrawableAmount
      );
    };

    const updateBalances = (balances: Balance[]) => {
      if (!isAlive(self)) {
        return;
      }
      for (const balance of balances) {
        const existsBalance = self.balanceBySymbol(balance.coin.symbol);
        if (existsBalance && isSameBalance(balance, existsBalance)) {
          continue;
        }
        self.balances.set(balance.coin.symbol, balance);
      }
      self.totalCount = self.balanceViews.length;
    };

    const updateBalanceBySymbol = flow(function* (
      symbol: string,
      blockchain: Blockchain,
    ) {
      let getFn;
      if (getRootStore(self).blockchains?.checkV2Chain(blockchain)) {
        getFn = getBalanceV2;
      } else {
        getFn = getBalance;
      }
      const balances: RetrieveAsyncFunc<typeof getFn> = yield getFn(symbol);
      for (const balance of balances) {
        const existsBalance = self.balanceBySymbol(balance.coin.symbol);
        if (existsBalance && isSameBalance(balance, existsBalance)) {
          continue;
        }
        self.balances.set(balance.coin.symbol, balance);
      }
    });

    const setPage = ({ page, size }: { page: number; size?: number }) => {
      self.page = page;
      self.size = size ?? self.size;
    };

    const initialize = flow(function* (blockchain: Blockchain) {
      clear();
      yield fetch(blockchain);
    });

    const search = (variables: Variables) => {
      self.variables = variables;
    };

    const toggleOnlyMyCoin = () => {
      self.showOnlyMyCoin = !self.showOnlyMyCoin;
    };

    return {
      clear,
      initialize,
      search,
      setPage,
      refresh: fetch,
      updateBalances,
      updateBalanceBySymbol,
      toggleOnlyMyCoin,
    };
  });

export type IBalances = Instance<typeof Balances>;
export default Balances;
