import { css } from "@emotion/react";
import { CSSProperties } from "@mui/styles";

export type Evaluation = {
  level1: CSSProperties;
  level2: CSSProperties;
  level3: CSSProperties;
  level4: CSSProperties;
  level5: CSSProperties;
};

export const evaluationCSS = {
  level1: css`
    box-shadow: unset;
  `,
  level2: css`
    box-shadow:
      0px 1px 10px rgba(54, 62, 76, 0.15),
      0px 6px 5px rgba(54, 62, 76, 0.15),
      0px 2px 3px rgba(54, 62, 76, 0.1);
  `,
  level3: css`
    box-shadow:
      0px 10px 30px rgba(54, 62, 76, 0.15),
      0px 10px 8px rgba(54, 62, 76, 0.1),
      0px 5px 5px rgba(54, 62, 76, 0.2);
  `,
  level4: css`
    box-shadow:
      0px 20px 44px rgba(54, 62, 76, 0.1),
      0px 15px 20px rgba(54, 62, 76, 0.15),
      0px 5px 8px rgba(54, 62, 76, 0.25);
  `,
  level5: css`
    box-shadow:
      0px 15px 40px rgba(54, 62, 76, 0.2),
      0px 10px 15px rgba(54, 62, 76, 0.1),
      0px 10px 15px rgba(54, 62, 76, 0.3);
  `,
};

export const evaluation: Evaluation = {
  level1: {
    boxShadow: "unset",
  },
  level2: {
    boxShadow:
      "0px 1px 10px rgba(54, 62, 76, 0.15), 0px 6px 5px rgba(54, 62, 76, 0.15), 0px 2px 3px rgba(54, 62, 76, 0.1)",
  },
  level3: {
    boxShadow:
      "0px 10px 30px rgba(54, 62, 76, 0.15), 0px 10px 8px rgba(54, 62, 76, 0.1), 0px 5px 5px rgba(54, 62, 76, 0.2)",
  },
  level4: {
    boxShadow:
      "0px 20px 44px rgba(54, 62, 76, 0.1), 0px 15px 20px rgba(54, 62, 76, 0.15), 0px 5px 8px rgba(54, 62, 76, 0.25)",
  },
  level5: {
    boxShadow:
      "0px 15px 40px rgba(54, 62, 76, 0.2), 0px 10px 15px rgba(54, 62, 76, 0.1), 0px 10px 15px rgba(54, 62, 76, 0.3)",
  },
};
