import { Instance, flow, types } from "mobx-state-tree";
import { Vasp } from "src/__generate__";
import { getVaspList } from "src/apis/requests";

const VaspStore = types
  .model("VaspStore", {
    data: types.optional(types.array(types.frozen<Vasp>()), []),
    isInitialized: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get vasps() {
        return Array.from(self.data);
      },
    };
  })
  .actions((self) => {
    const initialize = flow(function* () {
      const response: RetrieveAsyncFunc<typeof getVaspList> =
        yield getVaspList();
      self.data.replace(response.vasps);
      self.isInitialized = true;
    });
    const getVaspById = function (id?: string) {
      const target = self.vasps.find((x) => x.entityId === id);
      return target ?? null;
    };
    return {
      initialize,
      getVaspById,
    };
  });

export type IVaspStore = Instance<typeof VaspStore>;
export default VaspStore;
