import { requestAPI } from "src/apis/requestApi";
import { Blockchain, CoinV2ControllerApiFactory } from "src/__generate__/api";

const coinV2ControllerApi = () =>
  CoinV2ControllerApiFactory(undefined, "", requestAPI());

export const getCoins = async (isApproved = true) => {
  const response = await coinV2ControllerApi().getCoins(isApproved);
  return response.data;
};

export const checkCoinWithBlockchainAndAddressRegistered = async (
  blockchain: Blockchain,
  address: string,
) => {
  const response =
    await coinV2ControllerApi().checkCoinWithBlockchainAndAddressRegistered(
      blockchain,
      address,
    );
  return response.data;
};
