import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <rect width={24} height={24} rx={4} fill="#E84809" fillOpacity={0.5} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.625 13.99c0-1.599-1.299-2.293-2.236-2.531.771-.279 1.67-1.423 1.307-2.785-.306-1.153-1.374-2.175-4.071-2.266V4.5h-1.25v1.875h-.625V4.5H9.5v1.875H6.375V8.25h1.021c.551 0 .854.357.854.877v5.555c0 .537-.316 1.068-.869 1.068h-.674l-.312 1.861H9.5V19.5h1.25v-1.889h.625V19.5h1.25v-1.875c3.32 0 5-1.459 5-3.635zm-3.389-4.178c0 1.465-2.105 1.563-3.486 1.563V8.25c2.188 0 3.486.312 3.486 1.562zm-3.486 2.81v3.128c2.48 0 4.18-.235 4.18-1.573 0-1.435-1.836-1.556-4.18-1.556z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
