import _ from "lodash";
import { flow, getSnapshot, Instance, types } from "mobx-state-tree";

import { TransferAddressUserItem } from "src/interfaces/wallet";
import { requestWallets, requestWalletById } from "src/apis/custody/requests";
import {
  CustodyRequestStatus,
  WalletCustodyRequestDto,
  OrganizationDto,
  SigningDto,
  ClientApprovalDto,
  AuthorityName,
} from "src/__generate__/api";
import {
  IntegrationRequestStatus,
  transformWalletIntegrationRequestStatus,
} from "src/interfaces/request";
import { filterEmpty } from "src/utils/array";
import { TSort } from "src/interfaces/admin-sort";

export type RegisterWalletItem = WalletCustodyRequestDto & {
  registerWallet: TransferAddressUserItem;
  integrationRequestStatus: IntegrationRequestStatus;
  organization?: OrganizationDto;
  signing?: SigningDto;
  midApprovers?: ClientApprovalDto[];
  finalApprover?: ClientApprovalDto;
};

type Variables = {
  search?: string;
  statuses: CustodyRequestStatus[];
};

export type TRequestRegisterWalletsSort = {
  createdAt?: TSort;
};

const DEFAULT_PAGE_SIZE = 15;
const INITIALIZE_VARIABLES: Variables = {
  search: undefined,
  statuses: [],
};

const RequestRegisterWallets = types
  .model("RequestRegisterWallets", {
    data: types.optional(types.map(types.frozen<RegisterWalletItem>()), {}),
    dataArray: types.optional(types.array(types.string), []),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    sort: types.optional(types.frozen<TRequestRegisterWalletsSort>(), {}),
    totalCount: types.optional(types.number, 0),
    variables: types.frozen<Variables>(INITIALIZE_VARIABLES),
    hasMore: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get requestRegisterWalletViews() {
        return filterEmpty(
          self.dataArray.map((id) => self.data.get(id)),
        ).filter((item) =>
          self.variables.statuses.some((status) => status === item.status),
        );
      },
      get searchText() {
        return self.variables.search ?? "";
      },
      get isSearch() {
        if (!this.searchText) {
          return false;
        }
        return true;
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
      self.hasMore = true;
      self.sort = {};
      self.variables = INITIALIZE_VARIABLES;
      self.data.clear();
    };

    const fetchRequestRegisterWalletById = flow(function* (requestId: string) {
      const response: RetrieveAsyncFunc<typeof requestWalletById> =
        yield requestWalletById({
          requestId,
        });
      upsertRegisterWallet(response);
      return self.data.get(response.id)!;
    });

    const fetch = flow(function* (checkChanged?: boolean) {
      const { statuses, search } = self.variables;
      let snapshot;
      if (checkChanged) snapshot = getSnapshot(self);
      const response: RetrieveAsyncFunc<typeof requestWallets> =
        yield requestWallets({
          statuses,
          search,
          pageable: {
            page: self.page,
            size: self.size,
            sort: Object.keys(self.sort)
              .filter(
                (key) => self.sort[key as keyof TRequestRegisterWalletsSort],
              )
              .map(
                (key) =>
                  `${key},${self.sort[key as keyof TRequestRegisterWalletsSort]}`,
              ),
          },
        });
      const dataArray = [];
      for (const item of response.results) {
        upsertRegisterWallet(item);
        dataArray.push(item.id);
      }
      if (checkChanged && !_.isEqual(self.sort, snapshot?.sort)) return;
      self.dataArray.replace(dataArray);
      self.totalCount = response.pagination.totalCount;
      self.hasMore = response.results.length !== 0;
    });

    const upsertRegisterWallet = (item: WalletCustodyRequestDto) => {
      const approvals: ClientApprovalDto[] = item?.approvals ?? [];
      const wallet = item.wallet;
      const { status: custodyRequestStatus } = item;
      const { status: walletStatus } = wallet;

      const midApprovers = approvals.filter(
        (approval) => approval.authority === AuthorityName.CustodyManager,
      );
      const finalApprover = approvals.find(
        (approval) => approval.authority === AuthorityName.CustodyAdmin,
      );

      self.data.set(item.id, {
        ...item,
        registerWallet: {
          userType: "master_wallet",
          address: wallet.address ?? "",
          masterWallet: {
            id: wallet.id,
            name: wallet.name,
          },
        },
        integrationRequestStatus: transformWalletIntegrationRequestStatus({
          walletStatus,
          custodyRequestStatus,
        }),
        midApprovers,
        finalApprover,
      });
    };

    const setPage = flow(function* ({
      page,
      size,
    }: {
      page: number;
      size?: number;
    }) {
      self.page = page;
      self.size = size ?? self.size;
      yield fetch();
    });

    const initialize = flow(function* (options: Partial<Variables>) {
      clear();
      const existVariables = self.variables;
      self.variables = {
        ...existVariables,
        ...options,
      };
      yield fetch();
    });

    const setSort = flow(function* (sort: TRequestRegisterWalletsSort) {
      self.sort = sort;
      yield fetch();
    });

    const search = flow(function* (params: { search?: string }) {
      const newVariables = {
        ...self.variables,
        search: params.search,
      };
      clear();
      self.variables = newVariables;
      yield fetch();
    });

    return {
      clear,
      initialize,
      setPage,
      setSort,
      search,
      refresh: fetch,
      fetchRequestRegisterWalletById,
      upsertRegisterWallet,
    };
  });

export type IRequestRegisterWallets = Instance<typeof RequestRegisterWallets>;
export default RequestRegisterWallets;
