import _ from "lodash";

import { env, isProductionAndKodaAdmin } from "src/libs/env";
import { traverseObjectKeys, traverseObjectSliceStr } from "src/utils/string";

type NewWalletEventType =
  | "newwalletPlatform_next"
  | "newwalletGasfee_addresscopy"
  | "newwalletGasfee_qrcode"
  | "newwalletGasfee_address"
  | "newwalletGasfee_next"
  | "newwalletName_next"
  | "newwalletPassphrase_next"
  | "newwalletPassphrase_back"
  | "newwalletRecoverykit_makewallet"
  | "newwalletRecoverykit_back"
  | "wallet_gasfeecheck";

export type EventType = "test" | NewWalletEventType;

export const URI_MAX_LENGTH = 20;
const EVENT_TYPE_MAX_LENGTH = 40;

export interface IEventResult {
  eventType: EventType;
  [key: string]: any;
}

export function logEvent(
  eventData: IEventResult,
  func: (eventType: EventType, param: Record<string, unknown>) => void,
) {
  const { eventType } = eventData;

  if (!eventType) {
    throw new Error("eventType is not provided!");
  }

  if (eventType.length > EVENT_TYPE_MAX_LENGTH) {
    throw new Error(
      `${eventType} has over ${EVENT_TYPE_MAX_LENGTH} characters!`,
    );
  }

  const isAllKeysUnderLength40 = traverseObjectKeys(
    _.omit(eventData, ["eventType"]),
    (key: string) => key.length <= EVENT_TYPE_MAX_LENGTH,
  );

  if (!isAllKeysUnderLength40) {
    return;
  }

  const parameters = traverseObjectSliceStr(
    _.omit(eventData, ["eventType"]),
    100,
  );
  func(eventData.eventType, parameters);
}

const getGtag = () => {
  if (isProductionAndKodaAdmin) {
    return;
  }
  return _.get(globalThis as any, "gtag");
};

export const gTagEvent = (eventData: IEventResult) => {
  logEvent(
    eventData,
    (eventType: EventType, param: Record<string, unknown>) => {
      const gtag = getGtag();
      if (!gtag) {
        return;
      }
      gtag("event", eventType, param);
    },
  );
};

export const gTagUser = (userId: string, role: string) => {
  const gtag = getGtag();
  if (!gtag) {
    return;
  }
  gtag("config", env.GA_KEY, {
    user_id: userId,
    role,
  });
};

export const gTagPageView = (url: string) => {
  const gtag = getGtag();
  if (!gtag) {
    return;
  }
  gtag("config", env.GA_KEY, {
    page_path: url,
  });
};
