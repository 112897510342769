import React, { CSSProperties, useCallback } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, TextField, StandardTextFieldProps } from "@mui/material";

import { palette } from "src/themes/palette";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import {
  body1RegularCSS,
  body2RegularCSS,
  Body2RegularSpan,
} from "src/components/Typography/Typography";
import { styleToCSS } from "src/utils/string";
import { shouldForwardProp } from "src/themes/config";

type SizeType = "large" | "medium" | "full";

export interface Props extends Omit<StandardTextFieldProps, "size"> {
  error?: boolean;
  size?: SizeType;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  placeholderStyle?: CSSProperties;
  onEnter?: (
    event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>,
  ) => Promise<void> | void;
}

const sizeMap: {
  [key in SizeType]: {
    inputCSS: any;
  };
} = {
  large: {
    inputCSS: css`
      ${body1RegularCSS}
      height: 54px;
    `,
  },
  medium: {
    inputCSS: css`
      ${body2RegularCSS}
      height: 40px;
    `,
  },
  full: {
    inputCSS: css`
      ${body2RegularCSS}
      height: 100%;
    `,
  },
};

const Container = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled(TextField, {
  shouldForwardProp,
})<{
  $multiline: boolean;
  $size?: SizeType;
  $showEndAdornment: boolean;
  $type?: string;
  $placeholderStyle?: CSSProperties;
}>`
  & .MuiInputBase-root {
    margin: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: none;
    border-radius: 4px;
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${palette.mediumgrey.main} !important;
    }
    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${palette.mediumgrey.dark} !important;
      }
    }
    ${({ $size }) => sizeMap[$size ?? "large"].inputCSS}
    ${({ $multiline }) =>
      $multiline
        ? css`
            height: auto;
          `
        : css``};
  }
  & .MuiInputBase-input {
    background-color: #fff;
    border-radius: 4px;
    padding-left: 12px;
    height: 100%;
    color: ${palette.darkgrey.main};
    padding-top: 0px;
    padding-bottom: 0px;
    ::placeholder {
      ${({ $placeholderStyle }) => styleToCSS($placeholderStyle)}
      color: ${palette.darkgrey.light};
      opacity: 1;
    }
    ::-webkit-inner-spin-button {
      appearance: none;
      margin: 0px;
    }
    ::-webkit-outer-spin-button {
      appearance: none;
      margin: 0px;
    }
    input[type="number"] {
      appearance: textfield;
    }
    ${({ type }) =>
      type === "password"
        ? css`
            ime-mode: inactive;
          `
        : css``}
    ${({ $showEndAdornment }) =>
      $showEndAdornment
        ? css`
            padding-right: 24px;
          `
        : css`
            padding-right: 12px;
          `}
  }
  & .MuiFormHelperText-root {
    ${body2RegularCSS}
    color: ${palette.red.main};
    margin-top: 8px;
  }
`;

const Label = styled(Body2RegularSpan)`
  color: ${palette.darkgrey.main};
  margin-bottom: 8px;
`;

const HelperText = styled(Body2RegularSpan)`
  color: ${palette.red.main};
`;

const EndAdornment = styled(Box, {
  shouldForwardProp,
})<{ $multiline: boolean }>`
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 24px;
  padding-right: 12px;
  background: linear-gradient(
    270deg,
    #ffffff 0%,
    #ffffff 67.19%,
    rgba(255, 255, 255, 0) 100%
  );
  ${({ $multiline }) =>
    $multiline
      ? css`
          padding-top: 10px;
          padding-bottom: 12px;
          bottom: 0px;
        `
      : css`
          padding-top: unset;
          padding-bottom: unset;
        `}
`;

const HelperIcon = styled(MaterialIcon, {
  shouldForwardProp,
})<{ $isEndAdornment: boolean }>`
  ${({ $isEndAdornment }) =>
    $isEndAdornment
      ? css`
          margin-right: 8px;
        `
      : css``}
`;

const ENTER_KEY = "Enter";

const MaterialTextField = (props: Props) => {
  const {
    className,
    size,
    multiline,
    label,
    type,
    startAdornment,
    endAdornment,
    placeholder,
    helperText,
    placeholderStyle,
    InputProps,
    disabled,
    onEnter,
    onClick,
    error = true,
    ...rest
  } = props;
  const onKeyPress = InputProps?.onKeyPress;
  const showEndAdornment = Boolean(helperText || endAdornment);

  const onInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === ENTER_KEY) {
        event.preventDefault();
        onEnter?.(event);
      }
      onKeyPress?.(event);
    },
    [onKeyPress, onEnter],
  );

  return (
    <Container className={`textfield-container ${className}`}>
      {label && <Label>{label}</Label>}
      <Content
        $size={size}
        $multiline={Boolean(multiline)}
        $type={type}
        $showEndAdornment={showEndAdornment}
        $placeholderStyle={placeholderStyle}
        className="textfield-root"
        type={type}
        multiline={multiline}
        disabled={disabled}
        error={error}
        helperText={helperText && <HelperText>{helperText}</HelperText>}
        placeholder={placeholder}
        onClick={onClick}
        InputProps={{
          ...InputProps,
          startAdornment,
          endAdornment: showEndAdornment ? (
            <EndAdornment
              className="textfield-endAdornment"
              $multiline={Boolean(multiline)}
            >
              {helperText && error ? (
                <HelperIcon
                  $isEndAdornment={Boolean(endAdornment)}
                  name="error"
                  color={palette.red.main}
                  fontSize={24}
                />
              ) : null}
              {endAdornment ? endAdornment : null}
            </EndAdornment>
          ) : null,
          onKeyPress: onInputKeyDown,
        }}
        {...rest}
      />
    </Container>
  );
};

export default MaterialTextField;
