import hoistNonReactStatic from "hoist-non-react-statics";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

import Snackbar from "src/components/Snackbar/Snackbar";
import { MessageCode, messageByCode } from "src/libs/message";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import { palette } from "src/themes/palette";
import { useRouter } from "next/router";

export type Params = { msg?: MessageCode };

const withMessageSnackbar = <P extends Record<string, any>>(
  TargetComponent: React.ComponentType<P>,
): any => {
  const WithMessageSnackbar = (props: P) => {
    const router = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const params: Params = router.query ?? ({} as Params);

    useEffect(() => {
      const messageCode = params.msg;
      if (!(messageCode && messageByCode(messageCode))) {
        return;
      }
      if (messageCode === MessageCode.VERIFIED_IP) {
        enqueueSnackbar(
          <Snackbar
            IconComponent={
              <MaterialIcon
                name="check_circle"
                fontSize={22}
                color={palette.green.main}
              />
            }
            message={messageByCode(messageCode)}
          />,
        );
        return;
      }
      enqueueSnackbar(<Snackbar message={messageByCode(messageCode)} />);
    }, []);

    return <TargetComponent {...(props as P)} />;
  };
  hoistNonReactStatic(WithMessageSnackbar, TargetComponent);
  return WithMessageSnackbar;
};

export default withMessageSnackbar;
