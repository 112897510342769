const MUI_SPACING = 4;
export default MUI_SPACING;

export const spacing = {
  small1: "4px",
  small2: "8px",
  small3: "12px",
  small4: "16px",
  small5: "20px",
  small6: "24px",
  medium1: "28px",
  medium2: "32px",
  medium3: "48px",
  medium4: "56px",
  large1: "72px",
  large2: "96px",
};
