import React from "react";
import { Box, SnackbarContent } from "@mui/material";
import styled from "@emotion/styled";

import { palette } from "src/themes/palette";
import { evaluationCSS } from "src/themes/evaluation";
import {
  Body2RegularPre,
  body2RegularCSS,
} from "src/components/Typography/Typography";

export type Props = {
  className?: string;
  IconComponent?: React.ReactNode;
  message: React.ReactNode;
  action?: React.ReactNode;
};

const Container = styled(SnackbarContent)`
  ${body2RegularCSS}
  ${evaluationCSS.level2}
  display: flex;
  flex: 1;
  width: 420px;
  flex-direction: row;
  align-items: center;
  color: ${palette.lightgrey.dark};
  background-color: ${palette.darkgrey.dark};
  border-radius: 4px;
  min-height: 44px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 13px;
  padding-bottom: 13px;

  & .MuiSnackbarContent-message {
    ${body2RegularCSS}
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .MuiSnackbarContent-action {
    ${body2RegularCSS}
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-self: flex-end;
    height: 100%;
    padding-right: 16px;
    margin-right: 0px;
  }
`;

const MessageText = styled(Body2RegularPre)`
  word-break: break-word;
`;

const Message = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SnackbarIcon = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-right: 8px;
`;

const Snackbar = (props: Props) => {
  const { className, IconComponent, message, action } = props;
  return (
    <Container
      className={className}
      message={
        <Message>
          {IconComponent ? <SnackbarIcon>{IconComponent}</SnackbarIcon> : null}
          <MessageText>{message}</MessageText>
        </Message>
      }
      action={action}
    />
  );
};

export default Snackbar;
