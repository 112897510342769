import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.41 12L18 7.41 16.59 6l-6 6 6 6L18 16.59 13.41 12zM8 18V6H6v12h2z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
