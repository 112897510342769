import _ from "lodash";
import { flow, types, getSnapshot, Instance } from "mobx-state-tree";

import User, { IUser } from "src/stores/model/User";
import { getRootStore } from "src/stores/StoreHelper";
import { getAccounts, getAccountById } from "src/apis/organizations";
import LoginIps from "src/stores/LoginIps";
import { AccountStatus } from "src/__generate__/api";
import { filterEmpty } from "src/utils/array";

const DEFAULT_PAGE_SIZE = 15;

const OrganizationMembers = types
  .model("OrganizationMembers", {
    data: types.optional(types.map(types.late(() => User)), {}),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      get memberViews() {
        return Array.from(self.data.values()).filter(
          (item) => item.status !== AccountStatus.Deleted,
        );
      },
      get memberViewsExcludedInvitedStatus() {
        return Array.from(self.data.values()).filter(
          (item) =>
            ![AccountStatus.Deleted, AccountStatus.Invited].some(
              (status) => status === item.status,
            ),
        );
      },
      get sortedMemberViews() {
        const myUserId = getRootStore(self).authStore.user.id;
        const me = [self.data.get(myUserId)];
        const memberViewsExcludedMe = this.memberViews.filter(
          (user) => user.id !== myUserId,
        );
        const superAdmins = memberViewsExcludedMe.filter(
          (user) => user.isSuperAdmin,
        );
        const memberViewsExcludedSuperAdmin = memberViewsExcludedMe.filter(
          (user) => !user.isSuperAdmin,
        );
        const walletAndUserAdmins = memberViewsExcludedSuperAdmin.filter(
          (user) => user.isUserAdmin && user.isWalletAdmin,
        );
        const memberViewsExcludedWalletAndUserAdmins =
          memberViewsExcludedSuperAdmin.filter(
            (user) => !(user.isUserAdmin && user.isWalletAdmin),
          );
        const walletAdmins = memberViewsExcludedWalletAndUserAdmins.filter(
          (user) => user.isWalletAdmin,
        );
        const memberViewsExcludedWalletAdmins =
          memberViewsExcludedWalletAndUserAdmins.filter(
            (user) => !user.isWalletAdmin,
          );
        const userAdmins = memberViewsExcludedWalletAdmins.filter(
          (user) => user.isUserAdmin,
        );
        const memberViewsExcludedUserAdmins =
          memberViewsExcludedWalletAdmins.filter((user) => !user.isUserAdmin);
        const members = memberViewsExcludedUserAdmins.filter(
          (user) => user.isMember,
        );
        const memberViewsExcludedMembers = memberViewsExcludedUserAdmins.filter(
          (user) => !user.isMember,
        );
        const custodyAdmins = memberViewsExcludedMembers.filter(
          (user) => user.isCustodyAdmin,
        );
        const memberViewsExcludedCustodyAdmins =
          memberViewsExcludedMembers.filter((user) => !user.isCustodyAdmin);
        const custodyManagers = memberViewsExcludedCustodyAdmins.filter(
          (user) => user.isCustodyManager,
        );
        const memberViewsExcludedCustodyManagers =
          memberViewsExcludedCustodyAdmins.filter(
            (user) => !user.isCustodyManager,
          );
        const custodySigners = memberViewsExcludedCustodyManagers.filter(
          (user) => user.isCustodySigner,
        );
        const memberViewsExcludedCustodSigners =
          memberViewsExcludedCustodyManagers.filter(
            (user) => !user.isCustodySigner,
          );
        const custodyOperators = memberViewsExcludedCustodSigners.filter(
          (user) => user.isCustodyOperator,
        );
        const memberViewsExcludedCustodyOperators =
          memberViewsExcludedCustodSigners.filter(
            (user) => !user.isCustodyOperator,
          );
        const custodyViews = memberViewsExcludedCustodyOperators.filter(
          (user) => user.isCustodyViewer,
        );
        const memberViewsExcludedCustodyViews =
          memberViewsExcludedCustodyOperators.filter(
            (user) => !user.isCustodyViewer,
          );

        return filterEmpty([
          ...me,
          ...superAdmins,
          ...walletAndUserAdmins,
          ...walletAdmins,
          ...userAdmins,
          ...members,
          ...custodyAdmins,
          ...custodyManagers,
          ...custodySigners,
          ...custodyOperators,
          ...custodyViews,
          ...memberViewsExcludedCustodyViews,
        ]);
      },
      get memberPaginationViews() {
        return _.slice(
          this.sortedMemberViews,
          self.page * self.size,
          (self.page + 1) * self.size,
        );
      },
      memberById(id: string) {
        return self.data.get(id);
      },
      membersByIds(ids: string[]) {
        const result = [];
        for (const id of ids) {
          result.push(this.memberById(id));
        }
        return result;
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
    };

    const fetch = flow(function* () {
      const orgId = getRootStore(self).authStore.user.orgId;
      const response: RetrieveAsyncFunc<typeof getAccounts> =
        yield getAccounts(orgId);
      for (const member of response) {
        const { loginIps, name, ...rest } = member;
        self.data.put(
          User.create({
            ...rest,
            name: name ?? "",
            loginIps: LoginIps.create({
              data: loginIps,
              totalCount: loginIps.length,
            }),
          }),
        );
      }
      self.totalCount = response.length;
    });

    const fetchMemberById = flow(function* (accountId: string) {
      const orgId = getRootStore(self).authStore.user.orgId;
      const response: RetrieveAsyncFunc<typeof getAccountById> =
        yield getAccountById({ orgId, accountId });
      const { loginIps, name, ...rest } = response;
      self.data.put(
        User.create({
          ...rest,
          name: name ?? "",
          loginIps: LoginIps.create({
            data: loginIps,
            totalCount: loginIps.length,
          }),
        }),
      );
    });

    const setPage = ({ page, size }: { page: number; size?: number }) => {
      self.page = page;
      self.size = size ?? self.size;
    };

    const initialize = flow(function* () {
      clear();
      yield fetch();
    });

    const deleteMemberById = (id: string) => {
      self.data.delete(id);
    };

    const updateOrganizationMember = (user: IUser) => {
      self.data.put(User.create(getSnapshot(user)));
    };

    return {
      clear,
      initialize,
      setPage,
      refresh: fetch,
      fetchMemberById,
      deleteMemberById,
      updateOrganizationMember,
    };
  });

export type IOrganizationMembers = Instance<typeof OrganizationMembers>;
export default OrganizationMembers;
