import _ from "lodash";
import getConfig from "next/config";

import packageJson from "../../package.json";

export type ReactEnv =
  | "local"
  | "development"
  | "production"
  | "testnet"
  | "testnet-staging"
  | "intranet-testnet"
  | "production-staging";

const { publicRuntimeConfig } = _.defaultTo(getConfig(), {
  publicRuntimeConfig: {},
});
const {
  SENTRY_DSN,
  REACT_ENV,
  NODE_ENV,
  GOOGLE_EMAIL,
  GOOGLE_PASSWORD,
  IS_KODA_ADMIN,
} = publicRuntimeConfig;

const reactEnv = (REACT_ENV as ReactEnv) || "development";

export const isKodaAdmin = IS_KODA_ADMIN === "true";

const BASE_URL: Record<ReactEnv, string> = IS_KODA_ADMIN
  ? {
      local: `http://localhost:${isKodaAdmin ? "3001" : "3000"}`,
      development: "https://app.dev.admin.kodax.com",
      testnet: "https://app.qa.admin.kodax.com",
      "testnet-staging": "https://app.qa.admin.kodax.com",
      "intranet-testnet": "https://172.17.0.22",
      production: "https://10.0.0.237",
      "production-staging": "https://10.0.0.237",
    }
  : {
      local: "http://localhost:3000",
      development: "https://app.dev.kodax.com",
      testnet: "https://app.qa.kodax.com",
      "testnet-staging": "https://app.qa.kodax.com",
      "intranet-testnet": "https://app.qa.kodax.com",
      production: "https://app.kodax.com",
      "production-staging": "https://app.kodax.com",
    };

const API_URL: Record<ReactEnv, string> = IS_KODA_ADMIN
  ? {
      local: "https://api.dev.kodax.com",
      development: "https://api.dev.kodax.com",
      testnet: "https://api.qa.kodax.com",
      "testnet-staging": "https://api.qa.kodax.com",
      "intranet-testnet": "https://172.17.0.23",
      production: "https://10.0.0.238",
      "production-staging": "https://10.0.0.238",
    }
  : {
      local: "https://api.dev.kodax.com",
      development: "https://api.dev.kodax.com",
      testnet: "https://api.qa.kodax.com",
      "testnet-staging": "https://api.qa.kodax.com",
      "intranet-testnet": "https://api.qa.kodax.com",
      production: "https://api.kodax.com",
      "production-staging": "https://api.kodax.com",
    };

const HENESIS_URL: Record<ReactEnv, string> = {
  local: "https://app.dev.wallet.henesis.io",
  development: "https://app.dev.wallet.henesis.io",
  testnet: "https://app.test.wallet.henesis.io",
  "testnet-staging": "https://app.staging.test.wallet.henesis.io",
  "intranet-testnet": "https://app.staging.test.wallet.henesis.io",
  production: "https://app.staging.wallet.henesis.io",
  "production-staging": "https://app.wallet.henesis.io",
};

const testnetGAKey = "UA-126138188-8";
const productionGAKey = "UA-126138188-9";

const GA_KEY: Record<ReactEnv, string> = {
  local: "",
  development: "",
  testnet: testnetGAKey,
  "testnet-staging": testnetGAKey,
  "intranet-testnet": testnetGAKey,
  production: productionGAKey,
  "production-staging": productionGAKey,
};

/**
 * Context:
 * 24년도 ISMS 조치사항으로 인트라넷에서 TLS 통신을 해야합니다. 이를 위해 저희가 직접 CA 인증서를 만들었습니다.
 * 내부 대시보드에서 axios 통해 API 요청 보낼 때 해당 인증서를 같이 실어서 보내야합니다.
 *
 * (27년 4월에 만료됩니다.)
 * */
const KODA_INTERNAL_CA_CERTS =
  "-----BEGIN CERTIFICATE-----\n" +
  "MIIDYTCCAkmgAwIBAgIUe2+IlPFtYSeHhzTtrSAziVzDAEkwDQYJKoZIhvcNAQEL\n" +
  "BQAwQDELMAkGA1UEBhMCS1IxDjAMBgNVBAgMBVNlb3VsMSEwHwYDVQQKDBhJbnRl\n" +
  "cm5ldCBXaWRnaXRzIFB0eSBMdGQwHhcNMjQwNDMwMDUxNTEyWhcNMjYwODAzMDUx\n" +
  "NTEyWjBAMQswCQYDVQQGEwJLUjEOMAwGA1UECAwFU2VvdWwxITAfBgNVBAoMGElu\n" +
  "dGVybmV0IFdpZGdpdHMgUHR5IEx0ZDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCC\n" +
  "AQoCggEBAKV5z5olZ0DS0Rsl6JcEaZH8QtuvmAzCO9V5XnCiKLkwI3atpu7/808w\n" +
  "JS5f3TPenSrvnBw5P0RFcC2MfkW69G69hIuteJ1LCqirPoG7KcCD0w0ivSt8qD9F\n" +
  "80sa/lEIMHPHTZavlE+JyUslu2gEHdLzAdm6sE9tBIu8OPPdHe3/V1ERr7RyjdHA\n" +
  "8U716ZKcPuS46KzsFZNHtbFZdUEYPaWnHJQ82TZ2UwKElJyxqxbdx1sL2o7zZih5\n" +
  "HxMiheCLCRw/dx/BkALC5PzRZglPSP22m6Ug1Fli4IS4alUj/Z2SVzteywYudcZ/\n" +
  "CdQ5yLHTv+VAbRX0DDB/07rhvje+NmECAwEAAaNTMFEwHQYDVR0OBBYEFKK3phk+\n" +
  "7xHENVOkLT8nKQN42p92MB8GA1UdIwQYMBaAFKK3phk+7xHENVOkLT8nKQN42p92\n" +
  "MA8GA1UdEwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBAAjXBogCgT1WkPFz\n" +
  "4cWqPVVKwn8z5qXU0W2XYL19083akyk2t9tqWi3m02KR3tHy1QDke0QW34LOn6+K\n" +
  "lwdHTGAJjRW8dy3slksGHcH/FO2vh5xLaXRiYwaj6jxfx1b1NpF21f1F9N2yP/eL\n" +
  "HBcIyvpQ/l23QduQoxeqbcwxuRFQI+S85aS0DATgzoXse48CKKxvtrvHqEBsZaIy\n" +
  "udq30VcRLScvyStgnC66EX/zllfmPbdOtmpuQCEdMyz9weAVea9jrURj57rhWsqX\n" +
  "xj5TAShf3iWEcy+U0OgWrWy7J9LAS254AOZYo+4v/3JgQJhznZr8Xbwi0dN2Kt9X\n" +
  "kVVwCI0=\n" +
  "-----END CERTIFICATE-----";
const KODA_QA_INTERNAL_CA_CERTS =
  "-----BEGIN CERTIFICATE-----\n" +
  "MIICljCCAX4CCQCM66Ce79MmMjANBgkqhkiG9w0BAQsFADANMQswCQYDVQQGEwJL\n" +
  "UjAeFw0yNDA0MjkwNjEyNTNaFw0yNzA0MjkwNjEyNTNaMA0xCzAJBgNVBAYTAktS\n" +
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2AeK8axlNWDmFadvZ6M8\n" +
  "h35+u3CewsLdqUkzLBr05F+QP7iOF8GzU47NeZkmZtRBSEithsZ1H6B0GXgiP2C6\n" +
  "jgY3mCgAeMUjnuJSoviNwAJ6d3EDawLLFS2zE0g+NqJsIx/2ruKPFdEEm4KBbgUG\n" +
  "Vbh0B+daSZVivjMaFJrF16NhAxSk6Vewx1u0OqYfVfP3O4+CXkc5n4sUKTVL10jS\n" +
  "JvT0JIBwccXzW8nExHfFtj7Rjn24QAiMWDP4RTZJp4Ij5SFl5Js+YVpSD6LYcviE\n" +
  "xTiX1UW9i2DRCKYPuLEsg+29YsCSTB6HPlZ3u/XfuRwP2QCknTxf7Ea/ixdr4rFB\n" +
  "GQIDAQABMA0GCSqGSIb3DQEBCwUAA4IBAQBCUuKf/6qBlF6/6vJVOQGhzEKSCIxw\n" +
  "5iQCpHiyT8afBiGDjItCQeTam32u30ZcqMxoHKmdn1gX3sWYaQ+IoLeZPU7/gp1C\n" +
  "f2jDnZ2Z1ZWPvttpj7o9lrwikQd0Jpa6niBln3mLR6Ss+5+sUIRUMiPcTapABpSi\n" +
  "X0X1KNqy2bdf3MsUuufZHXtJPPUtl//OuGU27bp/sW0qGOw+nlUSXlq9b1qgBFb2\n" +
  "DKGAFcZafhXXfkRCLxxy76gkkM0PtmFBvZuL7650KNJWXYZ96jxtjEY1u7ZumtI+\n" +
  "IVaoQU72HZnw7VVZewSlQ7LYjTGvqK0NDtuUBhQ64n1cIQw1RoYlaJYB\n" +
  "-----END CERTIFICATE-----";

export const isProduction =
  REACT_ENV === "production" || REACT_ENV === "production-staging";

export const isTestnet =
  REACT_ENV === "testnet" || REACT_ENV === "testnet-staging";

export const isDevelopment = REACT_ENV === "development";

export const isTestnetAndDevelopment = isTestnet || isDevelopment;

export const isTestnetStaging = REACT_ENV === "testnet-staging";

export const isProductionStaging = REACT_ENV === "production-staging";

export const isDevelopmentAndStaging =
  isDevelopment || isTestnetStaging || isProductionStaging;

export const isNodeDevelopment = REACT_ENV === "development";

export const isNodeProduction = REACT_ENV === "production";

export const isNodeProductionAndDevelopment = isDevelopment && isNodeProduction;

export const isProductionAndKodaAdmin = isKodaAdmin && !isDevelopment;

export const isNodeProductionAndKodaAdmin = isKodaAdmin && isNodeProduction;

const INTERNAL_CA_CERTS: Record<ReactEnv, string[] | null> = {
  local: null,
  development: null,
  testnet: null,
  "testnet-staging": [KODA_QA_INTERNAL_CA_CERTS],
  "intranet-testnet": [KODA_QA_INTERNAL_CA_CERTS],
  production: isNodeProductionAndKodaAdmin ? [KODA_INTERNAL_CA_CERTS] : null,
  "production-staging": [KODA_INTERNAL_CA_CERTS],
};

const firebaseProdConfig = {
  apiKey: "AIzaSyDaO10x8pBIYLE1PaI45lYv3yYabpPmfoI",
  authDomain: "heneiss-wallet-dashboard.firebaseapp.com",
  databaseURL: "https://heneiss-wallet-dashboard.firebaseio.com",
  projectId: "heneiss-wallet-dashboard",
  storageBucket: "heneiss-wallet-dashboard.appspot.com",
  messagingSenderId: "732282044905",
  appId: "1:732282044905:web:06a4a56fc1dcf740784b78",
  measurementId: "G-1BBZMXRMBM",
};

export const env = {
  API_URL: API_URL[reactEnv],
  BASE_URL: BASE_URL[reactEnv],
  HENESIS_URL: HENESIS_URL[reactEnv],
  NODE_ENV,
  REACT_ENV: reactEnv,
  GA_KEY: GA_KEY[reactEnv],
  SENTRY_DSN,
  FIREBASE_KEY: firebaseProdConfig,
  VERSION: packageJson.version,
  GOOGLE_EMAIL,
  GOOGLE_PASSWORD,
  INTERNAL_CA_CERTS: INTERNAL_CA_CERTS[reactEnv],
};
