import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_4001_22"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path d="M22 2H2V22H22V2Z" fill="white" />
      </mask>
      <g mask="url(#mask0_4001_22)">
        <path
          d="M12 2C6.478 2 2 6.478 2 12C2 12.598 2.056 13.182 2.156 13.75H4.376C4.66 13.75 4.83 13.658 4.94 13.48L5.72 12.188L6.718 15.286C6.816 15.598 7.048 15.76 7.36 15.762C7.68 15.764 7.944 15.568 8.006 15.248L8.73 11.836L9.518 17.276C9.566 17.634 9.74 17.814 10.1 17.814H10.104C10.462 17.814 10.634 17.62 10.684 17.264L12.29 6.676C12.336 6.348 12.106 6.044 11.778 5.998C11.452 5.954 11.146 6.182 11.1 6.51L10.11 13.298L9.444 8.768C9.4 8.42 9.136 8.204 8.848 8.198C8.522 8.19 8.28 8.394 8.212 8.74L7.256 13.172L6.492 10.794C6.432 10.536 6.202 10.344 5.95 10.314C5.602 10.272 5.404 10.452 5.258 10.69L4.076 12.55H3.37C3.358 12.368 3.35 12.184 3.35 12C3.35 7.222 7.222 3.35 12 3.35C15.852 3.35 19.116 5.87 20.234 9.35H21.642C20.48 5.114 16.606 2 12 2Z"
          fill="black"
        />
        <path
          d="M21.844 10.25H19.62C19.336 10.25 19.166 10.342 19.056 10.52L18.276 11.812L17.278 8.71399C17.18 8.40199 16.948 8.23999 16.636 8.23799C16.316 8.23599 16.052 8.43199 15.99 8.75199L15.266 12.164L14.478 6.72399C14.43 6.36599 14.256 6.18599 13.896 6.18599H13.892C13.534 6.18599 13.362 6.37999 13.312 6.73599L11.706 17.324C11.66 17.652 11.89 17.956 12.218 18.002C12.544 18.046 12.85 17.818 12.896 17.49L13.886 10.702L14.552 15.232C14.596 15.58 14.86 15.796 15.148 15.802C15.474 15.81 15.716 15.606 15.784 15.26L16.74 10.828L17.504 13.206C17.564 13.464 17.794 13.656 18.046 13.686C18.394 13.728 18.592 13.548 18.738 13.31L19.92 11.45H20.63C20.642 11.632 20.65 11.816 20.65 12C20.65 16.778 16.778 20.65 12 20.65C8.14797 20.65 4.88397 18.13 3.76597 14.65H2.35797C3.51997 18.886 7.39597 22 12 22C17.522 22 22 17.522 22 12C22 11.402 21.944 10.818 21.844 10.25Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
