import _ from "lodash";
import { flow, Instance, types } from "mobx-state-tree";
import { Blockchain } from "src/__generate__";

import { getCustodyBalances as getCustodyBalancesV2 } from "src/apis/v2/custody/requests";
import { TSort } from "src/interfaces/admin-sort";
import { today } from "src/utils/datetime";

export type CoinBalancesItem = {
  id: string;
  blockchain: Blockchain;
  name: string;
  symbol: string;
  decimal: number;
  amount: string;
  oneDayBeforeAmount: string;
};

const DEFAULT_PAGE_SIZE = 15;

export type TCoinBalancesV2Sort = {
  blockchain?: TSort;
  name?: TSort;
};

const CoinBalancesV2 = types
  .model("CoinBalancesV2", {
    data: types.optional(types.map(types.frozen<CoinBalancesItem>()), {}),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    sort: types.optional(types.frozen<TCoinBalancesV2Sort>(), {}),
    totalCount: types.optional(types.number, 0),
    date: types.optional(types.string, today().format("YYYY-MM-DD")),
  })
  .views((self) => {
    return {
      get coinBalancesViews() {
        let fields = [];
        let fieldOrders: Array<"desc" | "asc"> = [];
        if (self.sort.blockchain) {
          fields.push("blockchain");
          fieldOrders.push(self.sort.blockchain);
        }
        if (self.sort.name) {
          fields.push("name");
          fieldOrders.push(self.sort.name);
        }
        return _.orderBy(Array.from(self.data.values()), fields, fieldOrders);
      },
      get coinBalancesPaginationViews() {
        const { page, size } = self;
        return _.slice(this.coinBalancesViews, page * size, (page + 1) * size);
      },
    };
  })
  .actions((self) => {
    const fetchByDate = flow(function* () {
      const response: RetrieveAsyncFunc<typeof getCustodyBalancesV2> =
        yield getCustodyBalancesV2({ date: self.date });
      for (const e of response) {
        const {
          coin: { id, name, blockchain, symbol, decimal },
          amount,
          oneDayBeforeAmount,
        } = e;
        self.data.set(id, {
          id,
          blockchain,
          name,
          symbol,
          decimal,
          amount,
          oneDayBeforeAmount,
        });
      }
      self.totalCount = response.length;
    });

    const setPage = flow(function* (params: { page: number; size?: number }) {
      const { page, size } = params;
      self.page = page;
      self.size = size ?? self.size;
    });

    const setSort = flow(function* (sort: TCoinBalancesV2Sort) {
      self.sort = sort;
    });

    const setDate = flow(function* ({ date }: { date: string }) {
      self.date = date;
      self.page = 0;
      self.size = DEFAULT_PAGE_SIZE;
      yield fetchByDate();
    });

    const initialize = flow(function* () {
      self.date = today().format("YYYY-MM-DD");
      self.page = 0;
      self.size = DEFAULT_PAGE_SIZE;
      self.sort = {};
      yield fetchByDate();
    });

    return {
      initialize,
      setPage,
      setDate,
      setSort,
      fetchByDate,
    };
  });

export type ICoinBalancesV2 = Instance<typeof CoinBalancesV2>;
export default CoinBalancesV2;
