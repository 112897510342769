import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14.884 4.692c-.948 1.108-2.06 1.483-2.915 1.418-.135-1.19.389-2.301 1.043-3.095.62-.753 1.832-1.47 2.893-1.515.115 1.02-.23 2.221-1.02 3.192zM6.055 18.968C4.65 16.96 2.968 12.32 4.81 8.892A4.672 4.672 0 018.873 6.38c.687 0 1.39.273 2.006.512.482.187.91.353 1.238.353.302 0 .66-.148 1.087-.325.776-.322 1.783-.74 3.109-.54.956.144 2.248.545 3.175 1.937 0 0-2.143 1.159-2.127 3.799 0 3.015 2.638 4.047 2.638 4.047s-.417 1.434-1.362 2.847c-1.105 1.767-1.985 2.39-3.01 2.457-.563.036-1.003-.151-1.475-.353-.494-.21-1.024-.437-1.77-.437-.755 0-1.286.228-1.79.444-.452.194-.881.378-1.432.378-1.293 0-2.099-1.094-3.105-2.532z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
