import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={140} height={22} viewBox="0 0 140 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h26.907v22H0V0zm48.685 11.055v-.044c0-4.378 3.52-7.971 8.369-7.971 4.847 0 8.324 3.55 8.324 7.927v.044c0 4.378-3.521 7.97-8.369 7.97-4.848-.006-8.324-3.548-8.324-7.926zm13.083 0v-.044c0-2.64-1.972-4.844-4.759-4.844-2.78 0-4.714 2.16-4.714 4.8v.044c0 2.64 1.971 4.844 4.759 4.844 2.787 0 4.714-2.16 4.714-4.8zM34.965 3.309h3.454v6.735l6.367-6.735h4.173l-6.39 6.538 6.687 8.873h-4.151l-4.87-6.56-1.816 1.847v4.713h-3.454V3.31zm39.004 0h-6.123v15.404h6.123c4.937 0 8.346-3.368 8.346-7.702v-.044c0-4.334-3.41-7.658-8.346-7.658zm4.737 7.738c0 2.735-1.913 4.604-4.737 4.604v-.007H71.3V6.356h2.669c2.831 0 4.737 1.92 4.737 4.648v.043zm10.043-7.883h3.187l6.73 15.512h-3.61l-1.437-3.454h-6.642l-1.438 3.454h-3.52l6.73-15.512zm3.64 9.069l-2.084-4.997-2.082 4.997h4.165z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.997 2.378h-4.1L1.832 19.63h4.106l11.06-17.25zm8.079 0h-4.107l-6.174 9.695H18.9l6.175-9.695zm-8.657 15.04c0 1.257-1.04 2.277-2.32 2.277-1.282 0-2.32-1.02-2.32-2.277 0-1.257 1.038-2.276 2.32-2.276 1.28 0 2.32 1.019 2.32 2.276z"
        fill="#00E774"
      />
      <path
        d="M102.667 10h1.675l.683-1.633h3.158l.683 1.633h1.717l-3.2-7.333h-1.515L102.667 10zm2.944-3.048l.993-2.361.992 2.361h-1.985zM111.789 10h2.913c2.347 0 3.969-1.591 3.969-3.64v-.021c0-2.05-1.622-3.62-3.969-3.62h-2.913V10zm1.643-1.446v-4.39h1.27c1.344 0 2.251.905 2.251 2.195v.021c0 1.29-.907 2.174-2.251 2.174h-1.27zM120.189 10h1.611V5.278l2.08 3.079h.043l2.102-3.11V10h1.632V2.719h-1.771l-1.963 3.079-1.963-3.08h-1.771V10zM129.69 10h1.643V2.719h-1.643V10zM133.364 10h1.621V5.371L138.602 10H140V2.719h-1.622v4.483l-3.499-4.483h-1.515V10z"
        fill="#3A4044"
      />
    </svg>
  );
}

export default SvgComponent;
