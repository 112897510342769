import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.6069 9.40567H7.95912L12.283 20.3333L16.6069 9.40567Z"
        fill="black"
      />
      <path
        d="M12.283 20.3333L7.17296 9.40567H4.5L12.283 20.3333Z"
        fill="black"
      />
      <path
        d="M12.283 3.66667L4.5 8.77673H7.17296L12.283 3.66667Z"
        fill="black"
      />
      <path
        d="M12.283 3.66667L7.95912 8.77673H16.6069L12.283 3.66667Z"
        fill="black"
      />
      <path
        d="M17.3931 8.77673H20.066L12.283 3.66667L17.3931 8.77673Z"
        fill="black"
      />
      <path
        d="M20.066 9.40567H17.3931L12.283 20.3333L20.066 9.40567Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgComponent;
