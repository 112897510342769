import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3084 15.5111C17.721 13.8564 16.5558 12.8033 15.6471 12.3143C16.5173 12.2248 17.7426 11.2729 17.719 9.76923C17.6995 8.49738 16.8585 7.16365 14.0902 6.37311L14.5824 4.39888L13.2887 4.07632L12.8048 6.01691L12.158 5.85563L12.6418 3.91504L11.3481 3.59248L10.8642 5.53307L7.62993 4.72666L7.14608 6.66725L8.20306 6.93079C8.77359 7.07304 8.99442 7.5211 8.86023 8.0593L7.42677 13.8086C7.28839 14.3636 6.82399 14.8319 6.25216 14.6893L5.55484 14.5154L4.75111 16.3611L7.96473 17.1624L7.47734 19.1172L8.77107 19.4398L9.25846 17.485L9.90532 17.6462L9.41793 19.6011L10.7117 19.9236L11.1955 17.983C14.6316 18.8398 16.7468 17.7635 17.3084 15.5111ZM14.8791 10.313C14.5011 11.8293 12.2973 11.387 10.8677 11.0305L11.6741 7.79622C13.9382 8.36071 15.2017 9.01928 14.8791 10.313ZM10.5461 12.3204L9.73875 15.5586C12.3055 16.1986 14.1256 16.394 14.4708 15.0097C14.8411 13.5245 12.9719 12.9252 10.5461 12.3204Z"
        fill="#000"
      />
    </svg>
  );
};

export default SvgComponent;
