import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="url(#paint0_linear_4001_38)"
      />
      <g clipPath="url(#clip0_4001_38)">
        <mask
          id="mask0_4001_38"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="16"
          height="16"
        >
          <path d="M20 4H4V20H20V4Z" fill="white" />
        </mask>
        <g mask="url(#mask0_4001_38)">
          <path
            d="M12 4C7.5824 4 4 7.5824 4 12C4 12.4784 4.0448 12.9456 4.1248 13.4H5.9008C6.128 13.4 6.264 13.3264 6.352 13.184L6.976 12.1504L7.7744 14.6288C7.8528 14.8784 8.0384 15.008 8.288 15.0096C8.544 15.0112 8.7552 14.8544 8.8048 14.5984L9.384 11.8688L10.0144 16.2208C10.0528 16.5072 10.192 16.6512 10.48 16.6512H10.4832C10.7696 16.6512 10.9072 16.496 10.9472 16.2112L12.232 7.7408C12.2688 7.4784 12.0848 7.2352 11.8224 7.1984C11.5616 7.1632 11.3168 7.3456 11.28 7.608L10.488 13.0384L9.9552 9.4144C9.92 9.136 9.7088 8.9632 9.4784 8.9584C9.2176 8.952 9.024 9.1152 8.9696 9.392L8.2048 12.9376L7.5936 11.0352C7.5456 10.8288 7.3616 10.6752 7.16 10.6512C6.8816 10.6176 6.7232 10.7616 6.6064 10.952L5.6608 12.44H5.096C5.0864 12.2944 5.08 12.1472 5.08 12C5.08 8.1776 8.1776 5.08 12 5.08C15.0816 5.08 17.6928 7.096 18.5872 9.88H19.7136C18.784 6.4912 15.6848 4 12 4Z"
            fill="white"
          />
          <path
            d="M19.8752 10.6H18.096C17.8688 10.6 17.7328 10.6736 17.6448 10.816L17.0208 11.8496L16.2224 9.3712C16.144 9.1216 15.9584 8.992 15.7088 8.9904C15.4528 8.9888 15.2416 9.1456 15.192 9.4016L14.6128 12.1312L13.9824 7.7792C13.944 7.4928 13.8048 7.3488 13.5168 7.3488H13.5136C13.2272 7.3488 13.0896 7.504 13.0496 7.7888L11.7648 16.2592C11.728 16.5216 11.912 16.7648 12.1744 16.8016C12.4352 16.8368 12.68 16.6544 12.7168 16.392L13.5088 10.9616L14.0416 14.5856C14.0768 14.864 14.288 15.0368 14.5184 15.0416C14.7792 15.048 14.9728 14.8848 15.0272 14.608L15.792 11.0624L16.4032 12.9648C16.4512 13.1712 16.6352 13.3248 16.8368 13.3488C17.1152 13.3824 17.2736 13.2384 17.3904 13.048L18.336 11.56H18.904C18.9136 11.7056 18.92 11.8528 18.92 12C18.92 15.8224 15.8224 18.92 12 18.92C8.9184 18.92 6.3072 16.904 5.4128 14.12H4.2864C5.216 17.5088 8.3168 20 12 20C16.4176 20 20 16.4176 20 12C20 11.5216 19.9552 11.0544 19.8752 10.6Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4001_38"
          x1="0"
          y1="0"
          x2="24"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC829F" />
          <stop offset="0.34" stopColor="#FF1C53" />
          <stop offset="0.44" stopColor="#EA49F8" />
          <stop offset="0.53" stopColor="#B250FF" />
          <stop offset="0.63" stopColor="#5E5FF5" />
          <stop offset="1" stopColor="#323CFF" />
        </linearGradient>
        <clipPath id="clip0_4001_38">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
