import { Instance, types } from "mobx-state-tree";
import { Blockchain } from "src/__generate__/api";

export type DialogType =
  | "registerWallet"
  | "retryWallet"
  | "registerWalletBlockchain"
  | "registerWalletComplete"
  | "firstOrganizationMember"
  | "sessionTimeout"
  | "sessionTimeoutProcess"
  | "passwordExiredBefore"
  | "notice"
  | null;

type RetryDepositAddressParams = {
  blockchain?: Blockchain;
  masterWalletId?: string;
};

type RegisterWalletCompleteParams = {
  blockchain: Blockchain;
  walletName: string;
};

const Dialog = types
  .model("Dialog", {
    dialog: types.frozen<DialogType>(null),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: types.frozen<any>(null),
  })
  .views((self) => {
    return {
      get retryWalletDialogParams(): RetryDepositAddressParams | null {
        return self.payload;
      },
      get registerWalletCompleteDialogParams(): RegisterWalletCompleteParams | null {
        return self.payload;
      },
      get isShowSessionTimeoutDialog() {
        return ["sessionTimeout", "sessionTimeoutProcess"].some(
          (dialog) => dialog === self.dialog,
        );
      },
    };
  })
  .actions((self) => {
    const showRetryWalletDialog = (payload: RetryDepositAddressParams) => {
      showDialog("retryWallet", payload);
    };

    const showRegisterWalletCompleteDialog = (
      payload: RegisterWalletCompleteParams,
    ) => {
      showDialog("registerWalletComplete", payload);
    };

    const showFirstOrganizationMemberDialog = () => {
      showDialog("firstOrganizationMember");
    };

    const showSessionTimeoutDialog = () => {
      showDialog("sessionTimeout");
    };

    const showSessionTimeoutProcessDialog = () => {
      showDialog("sessionTimeoutProcess");
    };

    const showPasswordExiredBeforeDialog = () => {
      showDialog("passwordExiredBefore");
    };

    const showNoticeDialog = () => {
      showDialog("notice");
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const showDialog = <T extends any>(dialog: DialogType, payload?: T) => {
      self.dialog = dialog;
      if (!payload) {
        return;
      }
      self.payload = payload;
    };

    const closeDialog = () => {
      self.dialog = null;
      self.payload = null;
    };

    return {
      showDialog,
      showRetryWalletDialog,
      showRegisterWalletCompleteDialog,
      showFirstOrganizationMemberDialog,
      showSessionTimeoutDialog,
      showSessionTimeoutProcessDialog,
      showPasswordExiredBeforeDialog,
      showNoticeDialog,
      closeDialog,
    };
  });

export type IDialog = Instance<typeof Dialog>;
export default Dialog;
