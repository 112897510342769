import React, { CSSProperties, ComponentType } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { palette, PaletteType } from "src/themes/palette";
import { buttonBoldCSS } from "src/components/Typography/Typography";
import { shouldForwardProp } from "src/themes/config";
import Chroma from "src/utils/chroma";

export type SizeType = "small" | "medium" | "large";

type VariantType =
  | "text"
  | "outline"
  | "contained"
  | "link"
  | "link_highlight"
  | "link_touch";

export type Props = Omit<ButtonProps, "variant" | "size" | "color"> & {
  className?: string;
  style?: CSSProperties;
  ChildrenComponent?: ComponentType<{
    children: React.ReactNode;
  }>;
  children: React.ReactNode;
  size?: SizeType;
  variant: VariantType;
  color?: PaletteType;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void)
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>);
};

const sizeMap: { [key in SizeType]: any } = {
  small: css`
    padding: 6px 10px;
    max-height: 32px;
  `,
  medium: css`
    padding: 10px 16px;
    max-height: 40px;
  `,
  large: css`
    padding: 14px 24px;
    max-height: 48px;
  `,
};

const makeVariant = (
  variant?: VariantType,
  color?: PaletteType,
): {
  normal: any;
  hover: any;
  disabled: any;
} => {
  const variantMap: Record<
    VariantType,
    { normal: any; hover: any; disabled: any }
  > = {
    contained: {
      normal: css`
        color: #fff;
        background-color: ${palette[color ?? "bluegrey"].main};
        text-transform: uppercase;
      `,
      hover: css`
        color: #fff;
        background-color: ${palette[color ?? "bluegrey"].dark};
      `,
      disabled: css`
        color: ${palette.darkgrey.light};
        background-color: ${palette.mediumgrey.main};
      `,
    },
    outline: {
      normal: css`
        color: ${palette[color ?? "bluegrey"].dark};
        background-color: #fff;
        border: 1px solid ${palette[color ?? "bluegrey"].light};
        text-transform: uppercase;
      `,
      hover: css`
        color: ${palette[color ?? "bluegrey"].dark};
        background-color: ${palette[color ?? "bluegrey"].pale};
        border: 1px solid ${palette[color ?? "bluegrey"].light};
      `,
      disabled: css`
        color: ${palette[color ?? "bluegrey"].light};
        background-color: #fff;
        border: 1px solid ${palette[color ?? "bluegrey"].pale};
      `,
    },
    text: {
      normal: css`
        color: ${palette.bluegrey.dark};
        background-color: #fff;
        text-transform: uppercase;
      `,
      hover: css`
        color: ${palette.bluegrey.dark};
        background-color: ${palette.bluegrey.pale};
      `,
      disabled: css`
        color: ${palette.bluegrey.light};
        background-color: #fff;
      `,
    },
    link: {
      normal: css`
        font-weight: 400;
        color: ${palette[color ?? "bluegrey"].main};
        background-color: transparent;
        border-radius: 0px;
        padding: 0px;
        user-select: text;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-color: ${palette[color ?? "bluegrey"].main};
      `,
      hover: css`
        font-weight: 400;
        background-color: transparent;
        border-radius: 0px;
        padding: 0px;
        user-select: text;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-color: ${palette[color ?? "bluegrey"].main};
      `,
      disabled: css`
        font-weight: 400;
        background-color: transparent;
        border-radius: 0px;
        padding: 0px;
      `,
    },
    link_highlight: {
      normal: css`
        color: ${palette[color ?? "primary"].main};
        background-color: transparent;
        border-radius: 0px;
        padding: 0px;
        user-select: text;
      `,
      hover: css`
        color: ${new Chroma(palette[color ?? "primary"].main)
          .darken(0.4)
          .toHex()};
        background-color: transparent;
      `,
      disabled: css``,
    },
    link_touch: {
      normal: css`
        color: ${palette.darkgrey.main};
        border-radius: 0px;
      `,
      hover: css`
        color: ${palette.darkgrey.main};
        background-color: ${palette.bluegrey.pale};
      `,
      disabled: css``,
    },
  };
  return variantMap[variant ?? "contained"];
};

const Container = styled(Button, {
  shouldForwardProp,
})<{
  $size?: SizeType;
  $color?: PaletteType;
  $variant?: VariantType;
}>`
  ${buttonBoldCSS}
  min-width: auto;
  border-radius: 4px;
  box-shadow: none;
  ${({ $size }) => sizeMap[$size ?? "small"]}
  ${({ $variant, $color }) => makeVariant($variant, $color).normal}
  &:hover {
    ${({ $variant, $color }) => makeVariant($variant, $color).hover}
    box-shadow: none;
  }
  &.Mui-disabled {
    ${({ $variant, $color }) => makeVariant($variant, $color).disabled}
    &:hover {
      ${({ $variant, $color }) => makeVariant($variant, $color).disabled}
    }
  }

  & .MuiButton-startIcon {
    color: inherit;
  }

  & .MuiButton-endIcon {
    color: inherit;
  }
`;

const MaterialButton = (props: Props) => {
  const {
    className,
    ChildrenComponent,
    children,
    size,
    color,
    variant,
    onClick,
    ...rest
  } = props;
  const linkProps = ["link", "link_highlight", "link_touch"].includes(variant)
    ? {
        disableFocusRipple: true,
        disableRipple: true,
      }
    : {};
  return (
    <Container
      $size={size}
      $variant={variant}
      $color={color}
      className={className}
      onClick={onClick}
      {...(variant === "link"
        ? {
            component: "a",
          }
        : {})}
      {...linkProps}
      {...rest}
    >
      {ChildrenComponent ? (
        <ChildrenComponent>{children}</ChildrenComponent>
      ) : (
        children
      )}
    </Container>
  );
};

export default MaterialButton;
