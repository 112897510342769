import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#F0B90B"
      />
      <g clipPath="url(#clip0_6769_947)">
        <path
          d="M7.66928 6.45073L11.9151 4L16.161 6.45073L14.6 7.3561L11.9151 5.81073L9.23026 7.3561L7.66928 6.45073ZM16.161 9.54146L14.6 8.6361L11.9151 10.1815L9.23026 8.6361L7.66928 9.54146V11.3522L10.3542 12.8975V15.9883L11.9151 16.8937L13.4761 15.9883V12.8975L16.161 11.3522V9.54146ZM16.161 14.4429V12.6322L14.6 13.5375V15.3483L16.161 14.4429ZM17.2693 15.0829L14.5844 16.6283V18.439L18.8303 15.9883V11.0868L17.2693 11.9922V15.0829ZM15.7083 7.9961L17.2693 8.90146V10.7122L18.8303 9.80683V7.9961L17.2693 7.09073L15.7083 7.9961ZM10.3542 17.2839V19.0947L11.9151 20L13.4761 19.0947V17.2839L11.9151 18.1893L10.3542 17.2839ZM7.66928 14.4429L9.23026 15.3483V13.5375L7.66928 12.6322V14.4429ZM10.3542 7.9961L11.9151 8.90146L13.4761 7.9961L11.9151 7.09073L10.3542 7.9961ZM6.56097 8.90146L8.12195 7.9961L6.56097 7.09073L5 7.9961V9.80683L6.56097 10.7122V8.90146ZM6.56097 11.9922L5 11.0868V15.9883L9.24585 18.439V16.6283L6.56097 15.0829V11.9922Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6769_947">
          <rect
            width="14"
            height="16"
            fill="white"
            transform="translate(5 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
