import React from "react";
import styled from "@emotion/styled";

import { palette } from "src/themes/palette";
import { evaluationCSS } from "src/themes/evaluation";
import { spacing } from "src/themes/spacing";
import { Paper } from "@mui/material";

type Props = {
  children: React.ReactNode;
  className?: string;
  width?: string;
  maxWidth?: string;
};

const Container = styled(Paper)`
  ${evaluationCSS.level1}
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid ${palette.mediumgrey.sub};
  padding: ${spacing.medium2};
`;

function MaterialPaper(props: Props) {
  const { children, className } = props;

  return <Container className={className}>{children}</Container>;
}

export default MaterialPaper;
