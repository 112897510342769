const path = require("path");
const i18nextHttpBackend = require('i18next-http-backend').default;

module.exports = {
  i18n: {
    defaultLocale: 'ko',
    locales: ['ko', 'en'],
  },
  localePath: typeof window == "undefined" ? path.resolve('./public/static/locales') : '/static/locales',
  backend: {
    // 절대 경로 사용
    loadPath: function (lng, ns) {
      if (typeof window == "undefined") {
        return path.resolve(`./public/static/locales/${lng}/${ns}.json`);
      } else {
        return `/static/locales/${lng}/${ns}.json`;
      }
    },
  },
  use: process.browser ? [i18nextHttpBackend] : [],
  strictMode: false,
};