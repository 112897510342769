import React from "react";

import Snackbar from "src/components/Snackbar/Snackbar";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import { palette } from "src/themes/palette";

type Props = {
  message: React.ReactNode;
  action?: React.ReactNode;
};

const ErrorSnackbar = (props: Props) => {
  const { message, action } = props;
  return (
    <Snackbar
      IconComponent={
        <MaterialIcon
          name={"warning"}
          fontSize={"small"}
          color={palette.red.main}
        />
      }
      message={message}
      action={action}
    />
  );
};

export default ErrorSnackbar;
