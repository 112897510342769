export function delay(ms = 500) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function isJSON(str: string) {
  try {
    const obj = JSON.parse(str);
    if (obj && typeof obj === "object" && obj !== null) {
      return true;
    }
  } catch (err) {
    // NOTHING
  }
  return false;
}

export const getValue = <T>(func: () => T, defaultValue: T) => {
  try {
    return func();
  } catch (error) {
    return defaultValue;
  }
};

export const mergeRefs =
  <T>(...refs: Array<React.Ref<T>>) =>
  (ref: T) => {
    refs.forEach((resolvableRef) => {
      if (typeof resolvableRef === "function") {
        resolvableRef(ref);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (resolvableRef as any).current = ref;
      }
    });
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEnumKeyByEnumValue(myEnum: any, enumValue: any) {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}
