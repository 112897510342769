import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="url(#paint0_linear_47_3005)"
      />
      <path
        d="M16.6069 9.40567H7.95912L12.283 20.3333L16.6069 9.40567Z"
        fill="white"
      />
      <path
        d="M12.283 20.3333L7.17296 9.40567H4.5L12.283 20.3333Z"
        fill="white"
      />
      <path
        d="M12.283 3.66667L4.5 8.77673H7.17296L12.283 3.66667Z"
        fill="white"
      />
      <path
        d="M12.283 3.66667L7.95912 8.77673H16.6069L12.283 3.66667Z"
        fill="white"
      />
      <path
        d="M17.3931 8.77673H20.066L12.283 3.66667L17.3931 8.77673Z"
        fill="white"
      />
      <path
        d="M20.066 9.40567H17.3931L12.283 20.3333L20.066 9.40567Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_47_3005"
          x1="2.78465e-07"
          y1="24"
          x2="24"
          y2="2.78465e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C42AF1" />
          <stop offset="1" stopColor="#3E10EC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
