import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      d="M10.6996 7.84981L9.59969 8.94975L7.62817 6.97733L7.62758 17.2222H6.07203L6.07261 6.97733L4.09995 8.94975L3 7.84981L6.84981 4L10.6996 7.84981ZM18.4774 14.1502L14.6276 18L10.7778 14.1502L11.8777 13.0502L13.8504 15.0227L13.8501 9.90022L13.8498 4.77778H15.4053L15.406 15.0227L17.3775 13.0502L18.4774 14.1502Z"
      fill="#3A4044"
    />
  </svg>
);

export default SvgComponent;
