import _ from "lodash";

import { Blockchain } from "src/__generate__/api";
import { getBlockchainsByLanguage } from "src/utils/blockchain";

export const filterNull = <T>(iterable: Array<T | null> | null) => {
  if (!iterable) {
    return [];
  }
  return _.filter(iterable, (i) => i !== null) as T[];
};

export const filterEmpty = <T>(
  iterable: Array<T | null | undefined> | null | undefined,
) => {
  if (!iterable) {
    return [];
  }
  return _.filter(
    iterable,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (i) => i !== null && i !== undefined && (i as any) !== "",
  ) as T[];
};

export const orderByBlockchain = <T extends { blockchain: Blockchain }>(
  iterable: Array<T>,
  groupOrderBy?: (items: Array<T>) => Array<T>,
) => {
  const byBlockchain = _.groupBy(iterable, (item) => item.blockchain);
  return filterEmpty(
    _.flatten(
      getBlockchainsByLanguage().map(
        (blockchain) =>
          groupOrderBy?.(byBlockchain[blockchain]) ?? byBlockchain[blockchain],
      ),
    ),
  );
};
