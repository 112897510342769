import _ from "lodash";
import { AxiosResponse, AxiosInstance } from "axios";
import * as Sentry from "@sentry/node";

import {
  ErrorCode,
  makeWalletErrorByCode,
  errorMessageByCode,
  SDKError,
} from "src/libs/error";
import {
  isApiAccessTokenExpired,
  toDateTimeText,
  today,
} from "src/utils/datetime";
import { reactotronApiResponse } from "src/utils/uri";
import { getRootStore } from "src/stores/Store";
import { isJSON } from "src/utils/common";
import { isDevelopment } from "src/libs/env";
import { isBrowser } from "src/utils/browser";

export const availableErrorCode = ({
  errorCode,
  apiAccessToken,
}: {
  errorCode: ErrorCode;
  apiAccessToken: string;
}) => {
  return !(
    ErrorCode.UNAUTHORIZED === errorCode &&
    isApiAccessTokenExpired(apiAccessToken)
  );
};

export const ignoreSentryErrorCodes = (errorCode: ErrorCode) => {
  return [
    ErrorCode.EMAIL_DOES_NOT_EXISTS,
    ErrorCode.INVALID_PASSWORD,
    ErrorCode.OTP_AUTHENTICATION_FAILED,
    ErrorCode.DUPLICATED_WALLET_NAME,
    ErrorCode.NOT_VERIFIED_IP,
    ErrorCode.TIMEOUT_IP,
    ErrorCode.ALREADY_VERIFIED_IP,
    ErrorCode.INVALID_IP_VERIFY_REQUEST,
    ErrorCode.EXPIRED_INVITATION,
    ErrorCode.INVALID_INVITATION,
    ErrorCode.CANCELED_INVITATION,
    ErrorCode.ALREADY_SIGNED_UP_ACCOUNT,
    ErrorCode.SESSION_TIME_OUT,
  ].some((code) => code === errorCode);
};

export const setSentryUser = (params: { email?: string; username: string }) => {
  Sentry.getCurrentScope().setUser(params);
};

export const addMonitor = (axiosInstance: AxiosInstance) => {
  if (!isDevelopment) {
    return;
  }
  const interceptorsResponse = axiosInstance.interceptors.response as any;
  interceptorsResponse.handlers = [
    {
      fulfilled: (response: AxiosResponse) => {
        return response;
      },
      rejected: (error: any) => {
        const apiResponse = reactotronApiResponse(error.response);
        if (!apiResponse) {
          Sentry.withScope((scope: Sentry.Scope) => {
            scope.setExtras({
              currentUrl: isBrowser ? window.location.href : "",
              localDatetime: toDateTimeText(today()),
            });
            Sentry.captureException(error);
          });
          return Promise.reject(error);
        }
        const [request, response] = apiResponse;
        const errorCode = String(
          _.get(
            error,
            ["response", "data", "error", "code"],
            ErrorCode.UNKOWN_ERROR,
          ),
        ) as any;
        const errorMessage = _.get(
          error,
          ["response", "data", "error", "message"],
          error.message,
        );
        const apiAccessToken = getRootStore(false).authStore.apiAccessToken();
        if (
          !availableErrorCode({
            errorCode,
            apiAccessToken,
          }) ||
          ignoreSentryErrorCodes(errorCode)
        ) {
          return Promise.reject(error);
        }
        Sentry.withScope((scope: Sentry.Scope) => {
          const data = _.get(request, "data", ""); // 3번째 인자는 default value
          scope.setExtras({
            currentUrl: isBrowser ? window.location.href : "",
            localDatetime: toDateTimeText(today()),
            request:
              typeof request === "number"
                ? request
                : {
                    ..._.omit(request, "data"),
                    headers: _.omit(request.headers, "Authorization"),
                    params: JSON.stringify(_.get(request, "params")),
                  },
            data: isJSON(data)
              ? _.omit(JSON.parse(data), [
                  "password",
                  "passphrase",
                  "newPassphrase",
                ])
              : {},
            response: response,
            response_body_error: _.get(response, ["body", "error"]),
            code: errorCode,
            message: errorMessageByCode(errorCode, errorMessage),
          });
          Sentry.captureException(
            errorCode === ErrorCode.UNKOWN_ERROR
              ? error
              : makeWalletErrorByCode(errorCode, errorMessage),
          );
        });
        return Promise.reject(error);
      },
    },
    ...interceptorsResponse.handlers,
  ];
};
