import _ from "lodash";
import React from "react";
import { useSnackbar } from "notistack";
import styled from "@emotion/styled";
import { NextPage, NextPageContext } from "next";
import { inject, observer } from "mobx-react";
import dynamic from "next/dynamic";

import { palette } from "src/themes/palette";
import { Props as StepIndicatorItemProps } from "src/components/Indicator/StepIndicatorItem";
import ErrorSnackbar from "src/components/Snackbar/ErrorSnackbar";
import { useTranslation } from "next-i18next";
import { H6BoldSpan } from "src/components/Typography/Typography";
import SetAccountNewPasswordForm from "src/components/Form/Account/SetAccountNewPasswordForm";
import OTPForm from "src/components/Form/OTPForm";
import { IAuthStore } from "src/stores/AuthStore";
import { IStore } from "src/stores/Store";
import SetAccountNewPasswordCompleteForm from "src/components/Form/Account/SetAccountNewPasswordCompleteForm";
import {
  changeAccountPassphrase,
  verifyAccountPassphrase,
} from "src/apis/accounts";
import { ErrorCode, makeWalletErrorByCode } from "src/libs/error";

type Inject = {
  authStore: IAuthStore;
};

type Props = Inject;

const StepByStepLayout = dynamic(
  () => import("src/components/Layout/StepByStepLayout"),
  { ssr: false },
);

export const getAccountExpiredPasswordPath = () => {
  return `/account/expired-password`;
};

const AppbarLeftCaption = styled(H6BoldSpan)`
  color: ${palette.darkgrey.main};
`;

const AccountExpiredPassword: NextPage<Props> = inject(
  ({ store }: { store: IStore }): Inject => ({
    authStore: store.authStore,
  }),
)(
  observer((props: Props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [stepIndex, setStepIndex] = React.useState<number>(0);
    const [password, setPassword] = React.useState<string>("");
    const [oldPassword, setOldPassword] = React.useState<string>("");

    const nextStep = () => {
      setStepIndex((prev) => prev + 1);
    };

    const onPassword = async (formStates: {
      oldPassword: string;
      password: string;
    }) => {
      const accountId = props.authStore.user.id;
      const { oldPassword, password } = formStates;

      const isVerifyOldPassword = await verifyAccountPassphrase({
        accountId,
        passphrase: oldPassword,
      });
      if (!isVerifyOldPassword) {
        throw makeWalletErrorByCode(ErrorCode.INVALID_PASSWORD);
      }
      setOldPassword(oldPassword);
      setPassword(password);
      nextStep();
    };

    const otpSubmit = async (otpCode: string) => {
      const accountId = props.authStore.user.id;

      try {
        await changeAccountPassphrase({
          accountId,
          passphrase: oldPassword,
          newPassphrase: password,
          otpCode,
        });
        nextStep();
      } catch (error: any) {
        if (error.status === ErrorCode.OTP_AUTHENTICATION_FAILED) {
          throw error;
        }
        enqueueSnackbar(<ErrorSnackbar message={error.message} />);
      }
    };

    const navigateDashboard = async () => {
      window.location.replace("/");
    };

    const steps: Array<StepIndicatorItemProps & { children: JSX.Element }> =
      (() => {
        const {
          email,
          passphraseLastModifiedDayBefore,
          passphraseExpiredPeriodDays,
        } = props.authStore.user;
        return [
          {
            stepNumber: "1",
            name: "새로운 비밀번호 입력",
            children: (
              <SetAccountNewPasswordForm
                email={email}
                passphraseLastModifiedDayBefore={
                  passphraseLastModifiedDayBefore ?? 0
                }
                passphraseExpiredPeriodDays={passphraseExpiredPeriodDays ?? 0}
                onSubmit={onPassword}
              />
            ),
          },
          {
            stepNumber: "2",
            name: "OTP 인증",
            children: (
              <OTPForm
                submitName={"다음"}
                onSubmit={otpSubmit}
                ActionBottomLeftComponent={null}
              />
            ),
          },
          {
            stepNumber: "3",
            name: "변경 완료",
            children: (
              <SetAccountNewPasswordCompleteForm onSubmit={navigateDashboard} />
            ),
          },
        ].map((item, index) => {
          return {
            ...item,
            status:
              stepIndex === index
                ? "active"
                : index < stepIndex
                  ? "visited"
                  : "normal",
          };
        });
      })();

    return (
      <StepByStepLayout
        open={true}
        onClose={_.identity}
        appBarLeftComponent={
          <AppbarLeftCaption>{`새로운 비밀번호 설정`}</AppbarLeftCaption>
        }
        appBarRightComponent={null}
        indicatorData={steps}
        TransitionComponent={undefined}
      >
        {steps[stepIndex].children}
      </StepByStepLayout>
    );
  }),
);

AccountExpiredPassword.getInitialProps = async (
  ctx: NextPageContext & { store: IStore },
) => {
  const { authStore } = ctx.store;

  try {
    await authStore.initialize();
  } catch (error) {
    // NOTHING
  }
  return {} as Props;
};

export default AccountExpiredPassword;
