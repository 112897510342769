/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, DependencyList } from "react";

function useLoading<T extends (...args: any[]) => any>({
  func,
  deps = [],
}: {
  func: T;
  deps?: DependencyList;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const wrapperLoading = useCallback(
    async (...args: Parameters<T>) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      try {
        await func(...args);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, func, ...deps],
  );
  return {
    wrapperLoading: wrapperLoading as T,
    isLoading,
  };
}

export default useLoading;
