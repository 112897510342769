import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M7.106 9.82L5.44 7.008a.335.335 0 01.125-.462.352.352 0 01.474.122l1.686 2.845c1.889-.868 3.787-.906 4.275-.9.488-.006 2.386.027 4.275.9l1.686-2.845a.352.352 0 01.474-.122.335.335 0 01.125.462L16.894 9.82c2.372 1.226 4.797 3.887 5.106 7.68H2c.31-3.793 2.734-6.454 5.106-7.68zm9.498 4.897c.459 0 .83-.364.83-.812a.821.821 0 00-.83-.811.821.821 0 00-.831.811c0 .448.372.812.83.812zm-9.208 0c.46 0 .831-.364.831-.812a.821.821 0 00-.83-.811.821.821 0 00-.832.811c0 .448.372.812.831.812z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
