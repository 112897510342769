import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4665_16231)">
        <path
          d="M8.76774 6.77821L7.05855 5.78868L11.8263 3L16.594 5.78868L14.8848 6.77821L11.8263 5.06902L8.76774 6.77821ZM17.8534 8.4874V10.5564L19.5626 9.56689V7.49787L17.8534 6.50834L16.1442 7.49787L17.8534 8.4874ZM10.1171 7.49787L11.8263 8.4874L13.5355 7.49787L11.8263 6.50834L10.1171 7.49787ZM16.594 9.20706L14.8848 8.21753L11.8263 9.92671L8.76774 8.21753L7.05855 9.20706V11.2761L10.1171 12.9853V16.4936L11.8263 17.4831L13.5355 16.4936V12.9853L16.594 11.2761V9.20706ZM17.8534 15.5041L14.8848 17.2133V19.2823L19.6526 16.4936V11.0062L17.8534 11.9957V15.5041ZM14.8848 15.7739L16.594 14.7844V12.7154L14.8848 13.7049V15.7739ZM10.1171 17.9329V20.0019L11.8263 20.9915L13.5355 20.0019V17.9329L11.8263 18.9224L10.1171 17.9329ZM4 9.56689L5.70919 10.5564V8.4874L7.50834 7.49787L5.79915 6.50834L4 7.49787V9.56689ZM5.79915 11.9957L4.08996 11.0062V16.4936L8.8577 19.2823V17.2133L5.79915 15.5041V11.9957ZM8.76774 13.7049L7.05855 12.7154V14.7844L8.76774 15.7739V13.7049Z"
          fill="url(#paint0_linear_4665_16231)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4665_16231"
          x1="11.8263"
          y1="3"
          x2="11.8263"
          y2="20.9915"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_4665_16231">
          <rect
            width="15.5626"
            height="17.9915"
            fill="white"
            transform="translate(4 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
