import { Blockchain, CoinType, CoinDto } from "src/__generate__/api";

export const dummyCoin: CoinDto = {
  id: "1",
  name: `이더리움`,
  symbol: "ETH",
  decimal: 18,
  type: CoinType.Token,
  address: "0x0",
  blockchain: Blockchain.Ethereum,
};
