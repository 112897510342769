import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M17.1436 8.72493C16.7781 8.5026 16.3081 8.5026 15.8903 8.72493L12.9661 10.5591L10.9817 11.7263L8.10966 13.5606C7.74413 13.7829 7.27415 13.7829 6.8564 13.5606L4.61097 12.1154C4.24543 11.8931 3.98433 11.4484 3.98433 10.9482V8.16911C3.98433 7.72446 4.19321 7.2798 4.61097 7.00189L6.8564 5.61235C7.22193 5.39002 7.69191 5.39002 8.10966 5.61235L10.3551 7.05747C10.7206 7.2798 10.9817 7.72446 10.9817 8.22469V10.0589L12.9661 8.83609V6.94631C12.9661 6.50166 12.7572 6.057 12.3394 5.77909L8.16188 3.16675C7.79634 2.94442 7.32637 2.94442 6.90862 3.16675L2.62663 5.83467C2.20888 6.057 2 6.50166 2 6.94631V12.171C2 12.6157 2.20888 13.0603 2.62663 13.3382L6.8564 15.9506C7.22193 16.1729 7.69191 16.1729 8.10966 15.9506L10.9817 14.172L12.9661 12.9491L15.8381 11.1705C16.2037 10.9482 16.6736 10.9482 17.0914 11.1705L19.3368 12.5601C19.7023 12.7824 19.9634 13.2271 19.9634 13.7273V16.5064C19.9634 16.951 19.7546 17.3957 19.3368 17.6736L17.1436 19.0632C16.7781 19.2855 16.3081 19.2855 15.8903 19.0632L13.6449 17.6736C13.2794 17.4513 13.0183 17.0066 13.0183 16.5064V14.7278L11.0339 15.9506V17.7848C11.0339 18.2294 11.2428 18.6741 11.6606 18.952L15.8903 21.5643C16.2559 21.7867 16.7258 21.7867 17.1436 21.5643L21.3734 18.952C21.7389 18.7297 22 18.285 22 17.7848V12.5045C22 12.0598 21.7911 11.6152 21.3734 11.3373L17.1436 8.72493Z"
      fill="black"
    />
  </svg>
);

export default SvgComponent;
