import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={90} height={20} viewBox="0 0 90 20" fill="none" {...props}>
    <path d="M24.544 0H0v20h24.544V0z" fill="#000" />
    <path
      d="M15.504 2.162h-3.74L1.67 17.845h3.746L15.504 2.162zM22.873 2.162h-3.745l-5.633 8.813h3.746l5.632-8.813zM12.86 17.904c1.169 0 2.117-.926 2.117-2.069 0-1.143-.948-2.07-2.117-2.07s-2.116.927-2.116 2.07c0 1.143.947 2.07 2.116 2.07z"
      fill="#00E774"
    />
    <path
      d="M31.893 3.008h3.15V9.13l5.809-6.122h3.806L38.83 8.952l6.099 8.066h-3.786L36.7 11.054l-1.656 1.68v4.284h-3.15V3.008z"
      fill="#000"
    />
    <path
      d="M44.408 10.05v-.04c0-3.98 3.212-7.246 7.634-7.246 4.422 0 7.593 3.226 7.593 7.206v.04c0 3.98-3.212 7.246-7.634 7.246-4.421-.006-7.593-3.226-7.593-7.206zm11.934 0v-.04c0-2.4-1.798-4.403-4.34-4.403-2.536 0-4.3 1.963-4.3 4.363v.04c0 2.4 1.798 4.403 4.34 4.403s4.3-1.963 4.3-4.363zM61.886 3.008h5.585c4.503 0 7.614 3.021 7.614 6.962v.04c0 3.94-3.11 7.001-7.614 7.001h-5.585V3.008zm5.585 11.22c2.576 0 4.32-1.7 4.32-4.185v-.04c0-2.48-1.737-4.225-4.32-4.225h-2.434v8.443h2.434v.007zM80.953 2.876h2.907L90 16.979h-3.293l-1.312-3.141h-6.058l-1.312 3.14h-3.211l6.139-14.102zm3.32 8.245l-1.9-4.543-1.9 4.543h3.8z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
