import React from "react";
import styled from "@emotion/styled";
import { WithTranslation, withTranslation } from "next-i18next";

import MaterialForm from "src/components/Form/MaterialForm";
import { palette } from "src/themes/palette";
import { spacing } from "src/themes/spacing";
import MaterialTextField from "src/components/TextField/MaterialTextField";
import BottomButton from "src/components/Button/BottomButton";
import useLoading from "src/hooks/useLoading";
import { H3Bold, Body1Regular } from "src/components/Typography/Typography";
import { translate } from "src/locales";

import useOTP from "src/hooks/useOTP";

export type Props = {
  className?: string;
  submitName: string;
  defaultOTP?: string;
  onSubmit: (otp: string) => Promise<void>;
  ActionBottomLeftComponent: React.ReactNode;
} & WithTranslation;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(H3Bold)``;

const Description = styled(Body1Regular)`
  color: ${palette.darkgrey.main};
  margin-top: ${spacing.small4};
`;

const Content = styled(Body1Regular)`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${palette.darkgrey.main};
`;

const OTPForm = (props: Props) => {
  const { className, submitName, ActionBottomLeftComponent, t } = props;
  const { otpForm, disabledOTP, onChangeOTP, onSubmitOTP } = useOTP({
    defaultOTP: props.defaultOTP,
    onSubmit: props.onSubmit,
  });

  const { isLoading: isSubmitLoading, wrapperLoading: submit } = useLoading({
    func: onSubmitOTP,
    deps: [otpForm.otp],
  });

  return (
    <MaterialForm
      className={className}
      size={"large"}
      HeaderComponent={
        <Header>
          <Title>{translate(["otpForm", "title"], t)}</Title>
          <Description>{translate(["otpForm", "description"], t)}</Description>
        </Header>
      }
      ContentComponent={
        <Content>
          <MaterialTextField
            label={translate(["otpForm", "otpLabel"], t)}
            autoFocus={true}
            placeholder={translate(["otpForm", "otpPlaceholder"], t)}
            onChange={onChangeOTP}
            onEnter={submit}
            value={otpForm.otp}
            helperText={otpForm.errors.otp}
          />
        </Content>
      }
      FooterComponent={
        <BottomButton
          LeftComponent={ActionBottomLeftComponent}
          buttonName={submitName}
          buttonProps={{
            variant: "contained",
            color: "primary",
            size: "large",
            onClick: submit,
            disabled: disabledOTP,
            isLoading: isSubmitLoading,
          }}
        />
      }
    />
  );
};

export default withTranslation()(OTPForm);
