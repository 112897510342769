import { css } from "@emotion/react";
import styled from "@emotion/styled";
import typography from "src/themes/typography";
import { styleToCSS } from "src/utils/string";

export const Pre = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: keep-all;
`;

export const H1Bold = styled.div`
  ${styleToCSS(typography.h1)}
`;

export const H2Bold = styled.div`
  ${styleToCSS(typography.h2)}
`;

export const h3BoldCSS = css`
  ${styleToCSS(typography.h3)}
`;

export const H3Bold = styled.div`
  ${styleToCSS(typography.h3)}
`;

export const H3BoldSpan = styled.span`
  ${styleToCSS(typography.h3)}
`;

export const h4BoldCSS = css`
  ${styleToCSS(typography.h4)}
`;

export const H4Bold = styled.div`
  ${styleToCSS(typography.h4)}
`;

export const H4BoldPre = styled(Pre)`
  ${styleToCSS(typography.h4)}
`;

export const H5Bold = styled.div`
  ${styleToCSS(typography.h5)}
`;

export const H6Bold = styled.div`
  ${styleToCSS(typography.h6)}
`;

export const H6BoldSpan = styled.span`
  ${styleToCSS(typography.h6)}
`;

export const body1RegularCSS = css`
  ${styleToCSS(typography.body1)}
`;

export const Body1Regular = styled.div`
  ${styleToCSS(typography.body1)}
`;

export const Body1RegularA = styled.a`
  ${styleToCSS(typography.body1)}
`;

export const Body1RegularPre = styled(Pre)`
  ${styleToCSS(typography.body1)}
  white-space: pre-wrap;
  word-break: break-all;
`;

export const Body1RegularSpan = styled.span`
  ${styleToCSS(typography.body1)}
`;

export const body1MediumCSS = css`
  ${styleToCSS(typography.body1Medium)}
`;

export const Body1Medium = styled.div`
  ${styleToCSS(typography.body1Medium)}
`;

export const Body1MediumSpan = styled.span`
  ${styleToCSS(typography.body1Medium)}
`;

export const body1BoldCSS = css`
  ${styleToCSS(typography.body1Bold)}
`;

export const Body1Bold = styled.div`
  ${styleToCSS(typography.body1Bold)}
`;

export const Body1BoldSpan = styled.span`
  ${styleToCSS(typography.body1Bold)}
`;

export const Body1BoldPre = styled(Pre)`
  ${styleToCSS(typography.body1Bold)}
`;

export const body2RegularCSS = css`
  ${styleToCSS(typography.body2)}
`;

export const Body2Regular = styled.div`
  ${styleToCSS(typography.body2)}
`;

export const Body2RegularSpan = styled.span`
  ${styleToCSS(typography.body2)}
`;

export const Body2RegularLi = styled.li`
  ${styleToCSS(typography.body2)}
`;

export const Body2RegularPre = styled(Pre)`
  ${styleToCSS(typography.body2)}
`;

export const body2MediumCSS = css`
  ${styleToCSS(typography.body2Medium)}
`;

export const Body2Medium = styled.div`
  ${styleToCSS(typography.body2Medium)}
`;

export const Body2MediumSpan = styled.span`
  ${styleToCSS(typography.body2Medium)}
`;

export const Body2MediumPre = styled(Pre)`
  ${styleToCSS(typography.body2Medium)}
`;

export const Body2Bold = styled.div`
  ${styleToCSS(typography.body2Bold)}
`;

export const Body2BoldSpan = styled.span`
  ${styleToCSS(typography.body2Bold)}
`;

export const body3RegularCSS = css`
  ${styleToCSS(typography.body3)}
`;

export const Body3Regular = styled.div`
  ${styleToCSS(typography.body3)}
`;

export const Body3Medium = styled.div`
  ${styleToCSS(typography.body3Medium)}
`;

export const Body3Bold = styled.div`
  ${styleToCSS(typography.body3Bold)}
`;

export const captionRegularCSS = css`
  ${styleToCSS(typography.caption)}
`;

export const CaptionRegular = styled.div`
  ${styleToCSS(typography.caption)}
`;

export const captionMediumCSS = css`
  ${styleToCSS(typography.captionMedium)}
`;

export const CaptionMedium = styled.div`
  ${styleToCSS(typography.captionMedium)}
`;

export const CaptionMediumPre = styled(Pre)`
  ${styleToCSS(typography.captionMedium)}
`;

export const CaptionMediumSpan = styled.span`
  ${styleToCSS(typography.captionMedium)}
`;

export const captionBoldCSS = css`
  ${styleToCSS(typography.captionBold)}
`;

export const CaptionBold = styled.div`
  ${styleToCSS(typography.captionBold)}
`;

export const OverlineRegular = styled.div`
  ${styleToCSS(typography.overline)}
`;

export const overlineMediumCSS = css`
  ${styleToCSS(typography.overlineMedium)}
`;

export const OverlineMedium = styled.div`
  ${styleToCSS(typography.overlineMedium)}
`;

export const OverlineMediumSpan = styled.span`
  ${styleToCSS(typography.overlineMedium)}
`;

export const overlineBoldCSS = css`
  ${styleToCSS(typography.overlineBold)}
`;

export const OverlineBold = styled.div`
  ${styleToCSS(typography.overlineBold)}
`;

export const buttonBoldCSS = css`
  ${styleToCSS(typography.button)}
`;

export const ButtonBold = styled.div`
  ${styleToCSS(typography.button)}
`;

export const button2BoldCSS = css`
  ${styleToCSS(typography.button2)}
`;

export const Button2Bold = styled.div`
  ${button2BoldCSS}
`;

export const Button3Bold = styled.div`
  ${styleToCSS(typography.button3)}
`;
