import _ from "lodash";
import { flow, getParent, Instance, types } from "mobx-state-tree";

import { accounts } from "src/apis/wallet/accounts";
import { accounts as accountsV2 } from "src/apis/v2/wallet/accounts";
import User from "src/stores/model/User";
import { AccountStatus, Blockchain, ResourceType } from "src/__generate__/api";
import { getRootStore } from "src/stores/StoreHelper";
import { filterEmpty } from "src/utils/array";

const DEFAULT_PAGE_SIZE = 15;

export const EMPTY_SYMBOL = "EMPTY";

const WalletMembers = types
  .model("WalletMembers", {
    data: types.optional(types.map(User), {}),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      get walletMemberViews() {
        return filterEmpty(
          Array.from(self.data.values()).filter(
            (item) => item.status === AccountStatus.Active,
          ),
        );
      },
      get sortedWalletMemberViews() {
        const parent = getParent<{
          id: string;
        }>(self);
        const walletId = parent.id;
        const myUserId = getRootStore(self).authStore.user.id;
        const me = [self.data.get(myUserId)];
        const memberViewsExcludedMe = this.walletMemberViews.filter(
          (user) => user.id !== myUserId,
        );
        const owners = memberViewsExcludedMe.filter((user) =>
          user.isWalletOwner(walletId),
        );
        const memberViewsExcludedOwner = memberViewsExcludedMe.filter(
          (user) => !user.isWalletOwner(walletId),
        );
        const managers = memberViewsExcludedOwner.filter((user) =>
          user.isWalletManager(walletId),
        );
        const memberViewsExcludedManager = memberViewsExcludedOwner.filter(
          (user) => !user.isWalletManager(walletId),
        );
        const members = memberViewsExcludedManager.filter((user) =>
          user.isWalletMember(walletId),
        );
        const memberViewsExcludedMember = memberViewsExcludedManager.filter(
          (user) => !user.isWalletMember(walletId),
        );
        return filterEmpty([
          ...me,
          ...owners,
          ...managers,
          ...members,
          ...memberViewsExcludedMember,
        ]);
      },
      get walletMemberPaginationViews() {
        return _.slice(
          this.sortedWalletMemberViews,
          self.page * self.size,
          (self.page + 1) * self.size,
        );
      },
      walletMemberById(id: string) {
        return self.data.has(id);
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.data.replace({});
      self.page = 0;
    };

    const fetch = flow(function* (blockchain: Blockchain) {
      const parent = getParent<{
        id: string;
      }>(self);
      let accountsFn;
      if (getRootStore(self).blockchains?.checkV2Chain(blockchain)) {
        accountsFn = accountsV2;
      } else {
        accountsFn = accounts;
      }
      const response: RetrieveAsyncFunc<typeof accountsFn> = yield accountsFn({
        walletId: parent.id,
      });
      for (const account of response) {
        const { id, walletId, authority, ...rest } = account;
        self.data.put(
          User.create({
            ...rest,
            id,
            roles: [
              {
                id: `role${id}`,
                resource: {
                  id: walletId,
                  type: ResourceType.Wallet,
                },
                authority: {
                  id: `roleAuthority${id}`,
                  name: authority,
                  resourceType: ResourceType.Wallet,
                },
              },
            ],
          }),
        );
      }
      self.totalCount = response.length;
    });

    const setPage = ({ page, size }: { page: number; size?: number }) => {
      self.page = page;
      self.size = size ?? self.size;
    };

    const initialize = flow(function* (blockchain: Blockchain) {
      clear();
      yield fetch(blockchain);
    });

    const deleteByAccountId = (accountId: string) => {
      self.data.delete(accountId);
    };

    return {
      clear,
      initialize,
      setPage,
      deleteByAccountId,
    };
  });

export type IWalletMembers = Instance<typeof WalletMembers>;
export default WalletMembers;
