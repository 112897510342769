import React from "react";
import styled from "@emotion/styled";

import LoadingButton, {
  Props as LoadingButtonProps,
} from "src/components/Button/LoadingButton";

export type Props = {
  className?: string;
  LeftComponent?: React.ReactNode;
  buttonName: string;
  buttonProps: Omit<LoadingButtonProps, "children">;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const BottomButton = (props: Props) => {
  const { className, LeftComponent, buttonName, buttonProps } = props;

  return (
    <Container className={className}>
      <Left>{LeftComponent}</Left>
      <LoadingButton progressProps={{ size: "small" }} {...buttonProps}>
        {buttonName}
      </LoadingButton>
    </Container>
  );
};

export default BottomButton;
