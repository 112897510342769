import { Style } from "@react-pdf/types";

export const koFontFamily = "Noto Sans KR";
const enFontFamily = "Inter";
const defaultFontFamily =
  "apple sd gothic neo, 맑은 고딕, malgun gothic, sans-serif";
const FontFamily = `${enFontFamily}, ${koFontFamily}, ${defaultFontFamily}`;
const sourceFontFamily = "Source Code Pro";

// 700 Bold, 400 Normal
const defaultTypography = {
  h1: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "70px",
    letterSpacing: "-0.01em",
  },
  h2: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "34px",
    lineHeight: "50px",
    letterSpacing: "0px",
  },
  h3: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0px",
  },
  h4: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
  },
  h5: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.001em",
  },
  h6: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.005em",
  },
  body1: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.001em",
  },
  body2: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.005em",
  },
  body3: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19px",
    letterSpacing: "0.005em",
  },
  caption: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.01em",
  },
  button: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.04em",
    ...({
      textTransform: "unset",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any),
  },
  button2: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.04em",
  },
  button3: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "11px",
    lineHeight: "20px",
    letterSpacing: "0.04em",
  },
  overline: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "0.015em",
  },
};

const typography = {
  code: {
    fontFamily: sourceFontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19px",
    letterSpacing: "-0.25px",
  },
  body1Medium: {
    ...defaultTypography.body1,
    fontWeight: 500,
  },
  body1Bold: {
    ...defaultTypography.body1,
    fontWeight: 700,
  },
  body2Medium: {
    ...defaultTypography.body2,
    fontWeight: 500,
  },
  body2Bold: {
    ...defaultTypography.body2,
    fontWeight: 700,
  },
  body3Medium: {
    ...defaultTypography.body3,
    fontWeight: 500,
  },
  body3Bold: {
    ...defaultTypography.body3,
    fontWeight: 700,
  },
  captionMedium: {
    ...defaultTypography.caption,
    fontWeight: 500,
  },
  captionBold: {
    ...defaultTypography.caption,
    fontWeight: 700,
  },
  overlineMedium: {
    ...defaultTypography.overline,
    fontWeight: 500,
  },
  overlineBold: {
    ...defaultTypography.overline,
    fontWeight: 700,
  },
  ...defaultTypography,
};

export const typographyForPDF: Record<string, Style> = {
  h1: {
    fontFamily: "SpoqaHanSansNeo-Bold",
    fontSize: 48,
  },
  h2: {
    fontFamily: "SpoqaHanSansNeo-Bold",
    fontSize: 34,
  },
  h3: {
    fontFamily: "SpoqaHanSansNeo-Bold",
    fontSize: 24,
  },
  h4: {
    fontFamily: "SpoqaHanSansNeo-Bold",
    fontSize: 20,
  },
  h5: {
    fontFamily: "SpoqaHanSansNeo-Bold",
    fontSize: 16,
  },
  h6: {
    fontFamily: "SpoqaHanSansNeo-Bold",
    fontSize: 14,
  },
  body1: {
    fontFamily: "SpoqaHanSansNeo-Regular",
    fontSize: 16,
  },
  body1Bold: {
    fontFamily: "SpoqaHanSansNeo-Bold",
    fontSize: 16,
  },
  body2: {
    fontFamily: "SpoqaHanSansNeo-Regular",
    fontSize: 14,
  },
  body3: {
    fontFamily: "SpoqaHanSansNeo-Regular",
    fontSize: 13,
  },
  caption: {
    fontFamily: "SpoqaHanSansNeo-Regular",
    fontSize: 12,
  },
};

export default typography;
