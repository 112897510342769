import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#00B1FF"
      />
      <g clipPath="url(#clip0_2007_13)">
        <path
          d="M12 4C16.4187 4 20 7.58133 20 12C20 16.4187 16.4187 20 12 20C7.58133 20 4 16.4187 4 12C4 7.58133 7.58133 4 12 4Z"
          fill="white"
        />
        <path
          d="M13.1395 12.5811L12.5768 13.1437L16.4627 17.0296L17.0253 16.4669L13.1395 12.5811ZM7.52987 6.9696L6.9672 7.53227L10.8528 11.4181L11.4155 10.8555L7.52987 6.9696ZM16.4616 6.9712L12.5787 10.8595L13.1419 11.4219L17.0248 7.53333L16.4616 6.9712ZM10.8533 12.5808L6.97067 16.4693L7.5336 17.0317L11.4168 13.1432L10.8533 12.5808Z"
          fill="#00B1FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2007_13">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
