import { AccountResponse } from "src/apis/accounts";
import { requestAPI } from "src/apis/requestApi";
import {
  CustodyRequestControllerApiFactory,
  CustodyRequestStatus,
  DeleteOrganizationRequest,
  DetailedWalletCustodyRequestDto,
  DetailedWithdrawalCustodyRequestDto,
  PaginationWalletCustodyRequestDto,
  PaginationWithdrawalCustodyRequestDto,
  SimpleBalanceDto,
  RegisterOrganizationRequest,
  UpdateOrganizationDetailRequest,
  UpdateOrganizationCustodyFeeRequest,
  UpdateOrganizationAmlStatusRequest,
} from "src/__generate__/api";
import { Pageable } from "src/apis/interface";

const custodyRequestControllerApiFactory = () =>
  CustodyRequestControllerApiFactory(undefined, "", requestAPI());

export type CustodyTransferType = "wallet" | "withdrawal";

export type Signing = {
  account: AccountResponse;
  logs: SigningLog[];
};

export type SigningLog = {
  type: "QR_CREATION" | "CONFIRMATION";
  createdAt: number;
};

export type Approval = {
  account: AccountResponse;
  type: "ALLOW";
  memo: string | null;
  authority: string;
  createdAt: number;
};

export const requestTransfers = async (request: {
  statuses: CustodyRequestStatus[];
  search?: string;
  pageable: Pageable;
}): Promise<PaginationWithdrawalCustodyRequestDto> => {
  const { statuses, search, pageable } = request;
  const { page, size, sort } = pageable;
  const response =
    await custodyRequestControllerApiFactory().getWithdrawalCustodyRequests1(
      undefined,
      statuses,
      search,
      page,
      size,
      sort,
    );
  return response.data;
};

export const requestTransferById = async (request: {
  requestId: string;
}): Promise<DetailedWithdrawalCustodyRequestDto> => {
  const { requestId } = request;

  const response =
    await custodyRequestControllerApiFactory().getWithdrawalCustodyRequest1(
      requestId,
    );
  return response.data;
};

export const requestWallets = async (request: {
  statuses: CustodyRequestStatus[];
  search?: string;
  pageable: Pageable;
}): Promise<PaginationWalletCustodyRequestDto> => {
  const { statuses, search, pageable } = request;
  const { page, size, sort } = pageable;

  const response =
    await custodyRequestControllerApiFactory().getWalletCustodyRequests1(
      undefined,
      statuses,
      search,
      page,
      size,
      sort,
    );
  return response.data;
};

export const requestWalletById = async (request: {
  requestId: string;
}): Promise<DetailedWalletCustodyRequestDto> => {
  const { requestId } = request;

  const response =
    await custodyRequestControllerApiFactory().getWalletCustodyRequest1(
      requestId,
    );
  return response.data;
};

export const getCustodyBalances = async (request: {
  date: string;
}): Promise<SimpleBalanceDto[]> => {
  const { date } = request;

  const response =
    await custodyRequestControllerApiFactory().getCustodyBalances1(date);
  return response.data;
};

export const getCustodyBalancesCsv = async (request: { date: string }) => {
  const { date } = request;

  const response =
    await custodyRequestControllerApiFactory().getCustodyBalancesCsv1(date);
  return response.data;
};

export const requestOrganizationDeletion = async (
  request: DeleteOrganizationRequest,
) => {
  const { otpCode, passphrase, orgId } = request;

  const response =
    await custodyRequestControllerApiFactory().requestOrganizationDeletion({
      otpCode,
      passphrase,
      orgId,
    });
  return response.data;
};

export const requestOrganizationRegistration = async (
  data: RegisterOrganizationRequest,
) => {
  try {
    await custodyRequestControllerApiFactory().requestOrganizationRegistration(
      data,
    );
    return true;
  } catch (error) {
    throw error;
  }
};

export const requestUpdateOrganizationDetail = async (
  data: UpdateOrganizationDetailRequest,
) => {
  try {
    await custodyRequestControllerApiFactory().requestUpdateOrganizationDetail(
      data,
    );
    return true;
  } catch (error) {
    throw error;
  }
};

export const requestUpdateOrganizationCustodyFee = async (
  data: UpdateOrganizationCustodyFeeRequest,
) => {
  try {
    await custodyRequestControllerApiFactory().requestUpdateOrganizationCustodyFee(
      data,
    );
    return true;
  } catch (error) {
    throw error;
  }
};

export const requestUpdateOrganizationAmlStatus = async (
  data: UpdateOrganizationAmlStatusRequest,
) => {
  try {
    await custodyRequestControllerApiFactory().requestUpdateOrganizationAmlStatus(
      data,
    );
    return true;
  } catch (error) {
    throw error;
  }
};
