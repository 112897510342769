import React from "react";
import MaterialButton, {
  Props as MaterialButtonProps,
} from "src/components/Button/MaterialButton";
import Progress, { ProgressProps } from "src/components/Progress/Progress";
import { palette } from "src/themes/palette";

export type Props = MaterialButtonProps & {
  isLoading: boolean;
  progressProps?: Partial<ProgressProps>;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const LoadingButton = (props: Props) => {
  const {
    children,
    isLoading,
    disabled,
    size,
    startIcon,
    progressProps = { size: "small" },
    ...rest
  } = props;

  return (
    <MaterialButton
      {...rest}
      disabled={disabled || isLoading}
      startIcon={
        isLoading ? (
          <Progress color={palette.primary.main} {...progressProps} />
        ) : (
          startIcon
        )
      }
      size={size}
    >
      {children}
    </MaterialButton>
  );
};

export default LoadingButton;
