/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import _ from "lodash";
import React from "react";
import { AxiosInstance, AxiosResponse } from "axios";

import {
  env,
  isNodeDevelopment,
  isDevelopmentAndStaging,
  isKodaAdmin,
} from "src/libs/env";
import { IStore } from "src/stores/Store";
import { isBrowser } from "src/utils/browser";
import { storage as cookieStorage } from "src/libs/cookieStorage";
import { storage as localStorage } from "src/libs/localStorage";
import { reactotronApiResponse } from "src/utils/uri";
import { Blockchain } from "./__generate__/api";

let setupReactotron: any = _.identity;

export const isReactotron =
  (isBrowser && isNodeDevelopment) ||
  (isBrowser && isDevelopmentAndStaging && localStorage().showReactotron());

if (isReactotron) {
  const Reactotron = require("reactotron-react-js").default;

  const { mst } = require("reactotron-mst");

  const { trackGlobalErrors } = require("reactotron-react-js");
  setupReactotron = (store: IStore) => {
    Reactotron.configure({
      name: `koda ${isKodaAdmin ? "admin" : ""} dashboard ${env.VERSION} (${
        env.REACT_ENV
      })`,
      secure: false,
    })
      .use(trackGlobalErrors({}))
      .use(mst())
      .connect();

    (Reactotron as any).trackMstNode(store);
    (console as any).tron = Reactotron;

    Reactotron.onCustomCommand({
      command: "clearCookieStorage",
      description: "clearCookieStorage",
      handler: () => {
        cookieStorage().clear();
      },
      title: "clearCookieStorage",
    });

    Reactotron.onCustomCommand({
      args: [
        {
          name: "accessToken",
        },
        {
          name: "secret",
        },
      ],
      command: "dev login",
      description: "dev login",
      handler: async (args: any) => {
        const accessToken = args.accessToken || "";
        const secret = args.secret || "";
        store.authStore.setServerTokenAndIp({
          serverAccessToken: accessToken,
          serverSecret: secret,
        });
        cookieStorage().saveAccessToken(accessToken);
        cookieStorage().saveSecret(secret);
        isBrowser && window.location.replace("/");
      },
      title: "dev login",
    });
  };
}

export const addMonitor = (axiosInstance: AxiosInstance) => {
  if (!isReactotron) {
    return;
  }
  const showURLPath = (url: string) => {
    const walletsIdLength = 41;
    if (url.startsWith("/wallets/") && url.length === walletsIdLength) {
      return false;
    }
    return true;
  };
  const interceptorsResponse = axiosInstance.interceptors.response as any;
  interceptorsResponse.handlers = [
    {
      fulfilled: (response: AxiosResponse) => {
        if ((console as any).tron && showURLPath(response.config.url ?? "")) {
          const apiResponse = reactotronApiResponse(response as any);
          if (!apiResponse) {
            return;
          }
          (console as any).tron.apiResponse(...apiResponse);
        }
        return response;
      },
      rejected: (error: any) => {
        if ((console as any).tron) {
          const apiResponse = reactotronApiResponse(error.response);
          if (!apiResponse) {
            return;
          }
          (console as any).tron.apiResponse(...apiResponse);
        }
        return Promise.reject(error);
      },
    },
    ...interceptorsResponse.handlers,
  ];
};

export { setupReactotron };
