import _ from "lodash";
import { flow, Instance, types } from "mobx-state-tree";

import { Blockchain, CoinDto } from "src/__generate__/api";
import { createCoin, getCoins } from "src/apis/coins";
import { getCoins as getCoinsV2 } from "src/apis/v2/coins";
import { TSort } from "src/interfaces/admin-sort";

const DEFAULT_PAGE_SIZE = 15;

export type TCoinsSort = {
  registeredAt?: TSort;
  blockchain?: TSort;
  name?: TSort;
  address?: TSort;
};

const Coins = types
  .model("Coins", {
    version: types.optional(types.number, 0), // 0: 둘다, 1: v1, 2: v2
    data: types.optional(types.map(types.frozen<CoinDto>()), {}),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    sort: types.optional(types.frozen<TCoinsSort>(), {}),
    totalCount: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      get coinViews() {
        return _.orderBy(
          Array.from(self.data.values()),
          ["id", "blockchain", "name"],
          ["desc", "asc", "asc"],
        );
      },
      get registeredCoinViews() {
        let fields = [];
        let fieldOrders: Array<"desc" | "asc"> = [];
        for (const key of ["registeredAt", "blockchain", "name", "address"]) {
          const value = self.sort[key as keyof TCoinsSort];
          if (!value) continue;
          if (["blockchain", "name"].includes(value)) {
            fields.push((item: CoinDto) =>
              item[key as keyof TCoinsSort]?.toLowerCase(),
            );
          } else {
            fields.push(key);
          }
          fieldOrders.push(value);
        }
        return _.orderBy(Array.from(self.data.values()), fields, fieldOrders);
      },
      getCoinById(coinId: string) {
        return self.data.get(coinId);
      },
      getCoinBySymbol(symbol: string) {
        return this.coinViews.find((item) => item.symbol === symbol);
      },
      get coinPaginationViews() {
        return _.slice(
          this.coinViews,
          self.page * self.size,
          (self.page + 1) * self.size,
        );
      },
      get registeredCoinPaginationViews() {
        return _.slice(
          this.registeredCoinViews,
          self.page * self.size,
          (self.page + 1) * self.size,
        );
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
    };

    const initialize = flow(function* () {
      clear();
      if (self.version === 0 || self.version === 1) {
        const response: RetrieveAsyncFunc<typeof getCoins> = yield getCoins();
        for (const coin of response) {
          self.data.set(coin.id, coin);
        }
      }
      if (self.version === 0 || self.version === 2) {
        const response: RetrieveAsyncFunc<typeof getCoinsV2> =
          yield getCoinsV2();
        for (const coin of response) {
          self.data.set(coin.id, coin);
        }
      }
      self.totalCount = Array.from(self.data).length;
    });

    const setPage = flow(function* ({
      page,
      size,
    }: {
      page: number;
      size?: number;
    }) {
      self.page = page;
      self.size = size ?? self.size;
    });

    const setSort = flow(function* (sort: TCoinsSort) {
      self.sort = sort;
    });

    const addCoin = flow(function* ({
      blockchain,
      symbol,
    }: {
      blockchain: Blockchain;
      symbol: string;
    }) {
      const coin: RetrieveAsyncFunc<typeof createCoin> = yield createCoin({
        blockchain,
        symbol,
      });
      self.data.set(coin.id, coin);
      self.totalCount += 1;
    });

    return {
      initialize,
      setPage,
      setSort,
      addCoin,
    };
  });

export type ICoins = Instance<typeof Coins>;
export default Coins;
