import _ from "lodash";
import { Instance, flow, types } from "mobx-state-tree";
import { AuthorityName } from "src/__generate__";
import { getCoinRegistrationRequests } from "src/apis/custody/coin-registrations";
import { getRootStore } from "./StoreHelper";
import { transformCoinReigstrationRequestStatusByAuthorityName } from "src/interfaces/coin-registrations";

const CoinRegistrationsCountStore = types
  .model("CoinRegistrationsCountStore", {
    count: types.optional(types.number, 0),
  })
  .actions((self) => {
    const clear = () => {
      self.count = 0;
    };

    const fetch = flow(function* () {
      const custodyAuthorityName = _.first(
        getRootStore(self).authStore.user.custodyAuthorityNames,
      );
      const statuses = transformCoinReigstrationRequestStatusByAuthorityName(
        custodyAuthorityName?.authority?.name ?? AuthorityName.CustodyViewer,
      );
      if (!statuses) {
        return;
      }
      const response: RetrieveAsyncFunc<typeof getCoinRegistrationRequests> =
        yield getCoinRegistrationRequests({
          statuses,
          pageable: {
            page: 0,
            size: 1,
          },
        });

      self.count = response.pagination.totalCount;
    });

    return {
      clear,
      fetch,
    };
  });

export type ICoinRegistrationsCountStore = Instance<
  typeof CoinRegistrationsCountStore
>;
export default CoinRegistrationsCountStore;
