import { flow, Instance, types } from "mobx-state-tree";

import OrganizationMembers from "src/stores/OrganizationMembers";
import {
  updateOrganizationName,
  organizationInitialized,
} from "src/apis/organizations";

const Organization = types
  .model("Organization", {
    id: types.identifier,
    name: types.string,
    initialized: types.optional(types.boolean, false),
    organizationMembers: types.optional(
      types.late(() => OrganizationMembers),
      {},
    ),
  })
  .actions((self) => {
    const initialize = (name: string) => {
      self.name = name;
    };

    const changeName = flow(function* (request: {
      name: string;
      passphrase: string;
      otpCode: string;
    }) {
      const { name, passphrase, otpCode } = request;
      yield updateOrganizationName({
        orgId: self.id,
        name,
        passphrase,
        otpCode,
      });
      self.name = name;
    });

    const orgInitialized = () => {
      organizationInitialized(self.id);
    };

    return {
      initialize,
      changeName,
      orgInitialized,
    };
  });

export type IOrganization = Instance<typeof Organization>;
export default Organization;
