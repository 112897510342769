import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="url(#paint0_linear_6769_8356)"
      />
      <path
        d="M15.649 14.7225L19.2186 12.6617C19.4074 12.5526 19.5248 12.3494 19.5248 12.1311V8.0089C19.5248 7.79098 19.4074 7.58746 19.2186 7.47834L15.649 5.41754C15.4602 5.30842 15.2253 5.30874 15.0365 5.41754L11.4669 7.47834C11.2781 7.58746 11.1607 7.79066 11.1607 8.0089V15.375L8.65762 16.8198L6.15458 15.375V12.4844L8.65762 11.0396L10.3088 11.9926V10.0537L8.96386 9.27738C8.87106 9.22394 8.76514 9.19578 8.65762 9.19578C8.5501 9.19578 8.44418 9.22394 8.35138 9.27738L4.78146 11.3385C4.59266 11.4476 4.47522 11.6508 4.47522 11.8691V15.991C4.47522 16.2089 4.59266 16.4124 4.78146 16.5216L8.35106 18.5826C8.53986 18.6914 8.77442 18.6914 8.96354 18.5826L12.5331 16.5219C12.7219 16.4128 12.8394 16.2092 12.8394 15.9913V8.62522L12.8845 8.5993L15.3424 7.18042L17.8454 8.62554V11.5158L15.3424 12.9609L13.6938 12.0092V13.9481L15.0362 14.7234C15.225 14.8323 15.4599 14.8323 15.6487 14.7234L15.649 14.7225Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6769_8356"
          x1="0"
          y1="24"
          x2="24"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A726C1" />
          <stop offset="0.88" stopColor="#803BDF" />
          <stop offset="1" stopColor="#7B3FE4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
