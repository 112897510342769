import { requestAPI } from "src/apis/requestApi";
import {
  ClientWalletControllerApiFactory,
  AccountWalletAuthorityDto,
  AuthorityDto,
  AuthorityName,
} from "src/__generate__/api";

const clientWalletControllerApiFactory = () =>
  ClientWalletControllerApiFactory(undefined, "", requestAPI());

export const accounts = async (params: {
  walletId: string;
}): Promise<AccountWalletAuthorityDto[]> => {
  const { walletId } = params;
  const response =
    await clientWalletControllerApiFactory().getWalletAccounts1(walletId);
  return response.data;
};

export const inviteWalletMembers = async (request: {
  walletId: string;
  accountIds: string[];
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { walletId, accountIds, passphrase, otpCode } = request;
  await clientWalletControllerApiFactory().inviteWalletAccount1(walletId, {
    accountIds,
    passphrase,
    otpCode,
  });
};

export const deleteWalletMember = async (request: {
  walletId: string;
  accountId: string;
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { walletId, accountId, passphrase, otpCode } = request;
  await clientWalletControllerApiFactory().deleteWalletAccount1(
    walletId,
    accountId,
    {
      passphrase,
      otpCode,
    },
  );
};

export const updateWalletMemberRole = async (request: {
  walletId: string;
  accountId: string;
  authorityName: AuthorityName;
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { walletId, accountId, authorityName, passphrase, otpCode } = request;
  await clientWalletControllerApiFactory().changeWalletAccountAuthority1(
    walletId,
    accountId,
    {
      authorityName,
      passphrase,
      otpCode,
    },
  );
};

export const getRoles = async (walletId: string): Promise<AuthorityDto[]> => {
  const response =
    await clientWalletControllerApiFactory().getWalletAuthorities1(walletId);
  return response.data;
};
