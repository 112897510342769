import { IncomingMessage } from "http";
import { isTestnetAndDevelopment } from "src/libs/env";

import { isBrowser } from "src/utils/browser";

const localhostIps = ["::1", "127.0.0.1"];
export const getXForwardedForIps = (req: IncomingMessage) => {
  if (isBrowser) {
    return;
  }
  const xForwardedFor = req.headers["x-forwarded-for"] as string;
  // next v14부터 req.header의 x-forwarded-for 값이 local 환경에서 undefined가 아닌 ::1을 보내줘서 허용된 ip에 포함되지 않아 자동 로그아웃되므로 localhost인지 아닌지 확인하는 과정이 필요
  if (isTestnetAndDevelopment && localhostIps.includes(xForwardedFor)) {
    return;
  }
  return req.headers["x-forwarded-for"] as string;
};
