import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import _ from "lodash";
import https from "https";

import { env } from "src/libs/env";
import {
  ErrorCode,
  errorMessageByCode,
  errorMessageByInvalidPasswordErrorCode,
} from "src/libs/error";
import { getRootStore } from "src/stores/Store";
import { todayFormat } from "src/utils/datetime";
import { addMonitor } from "src/ReactotronConfig";
import { addMonitor as addSentryMonitor } from "src/libs/sentry";
import { isBrowser } from "src/utils/browser";
import { getAccountExpiredPasswordPath } from "src/pages/account/expired-password";

export type RequestAPIErrorProps = {
  stack: any;
  message: string;
  originMessage: string;
  status: string;
  httpStatus: string;
  httpStatusText: string;
  errorAt: string;
  errorData: Record<string, any>;
};

export const requestAPI = (options?: {
  accessToken: string;
}): AxiosInstance => {
  const accessToken: string =
    options?.accessToken ?? getRootStore(false).authStore.apiAccessToken();
  const ips = getRootStore(false).authStore.ips;

  const headers = {
    ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
    ...(ips && !isBrowser ? { "x-forwarded-for": ips } : {}),
  };
  const configs: AxiosRequestConfig = {
    headers,
    /**
     * Context:
     * 24년도 ISMS 조치사항으로 인트라넷에서 TLS 통신을 해야합니다. 이를 위해 저희가 직접 CA 인증서를 만들었습니다.
     * 내부 대시보드에서 axios 통해 API 요청 보낼 때 해당 인증서를 같이 실어서 보내야합니다.
     *
     * 셋업 노션 문서: https://www.notion.so/kodax/TLS-SSL-fa7bc0f0e3c54584803cc3414605506b?pvs=4
     * */
    httpsAgent: new https.Agent({
      ca: env.INTERNAL_CA_CERTS !== null ? env.INTERNAL_CA_CERTS : undefined,
    }),
  };

  const client = axios.create({
    baseURL: env.API_URL,
    ...configs,
  });

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.error({ apiError: requestApiIfError({ error }), error });
      passphraseExpiredIfErrorStatus({ error });
      sessionTimeoutIfErrorStatus({ error });
      return Promise.reject(requestApiIfError({ error }));
    },
  );
  addMonitor(client);
  addSentryMonitor(client);
  return client;
};

export const requestAuthAPI = _.partial(requestAPI, { accessToken: "" });

const passphraseExpiredIfErrorStatus = (params: { error: any }) => {
  const { error } = params;
  const errorResponse = _.get(error, ["response", "data", "error"]);
  const code: ErrorCode = String(errorResponse?.code) as any;

  if (code === ErrorCode.PASSPHRASE_EXPIRED && isBrowser) {
    window.location.href = getAccountExpiredPasswordPath();
    return;
  }
};

const sessionTimeoutIfErrorStatus = (params: { error: any }) => {
  const { error } = params;
  const errorResponse = _.get(error, ["response", "data", "error"]);
  const code: ErrorCode = String(errorResponse?.code) as any;

  if (
    code === ErrorCode.SESSION_TIME_OUT &&
    !getRootStore(false).dialog.isShowSessionTimeoutDialog
  ) {
    getRootStore(false).dialog.showSessionTimeoutDialog();
    return;
  }
};

const requestApiIfError = (params: { error: any }) => {
  const { error } = params;
  const errorResponse = _.get(error, ["response", "data", "error"]);
  const httpStatus = _.get(error, ["response", "status"]);
  const httpStatusText = _.get(error, ["response", "statusText"]);
  const errorAt = todayFormat();
  const errorData: Record<string, any> = _.get(error, [
    "response",
    "data",
    "error",
    "data",
  ]);

  if (!Boolean(errorResponse)) {
    const errorLogs = {
      stack: _.get(error, "stack"),
      message: error?.message,
      status: ErrorCode.UNKOWN_ERROR,
      httpStatus,
      httpStatusText,
      errorAt,
      errorData,
    };
    return errorLogs;
  }
  const code: ErrorCode = String(errorResponse?.code) as any;
  const message: string = errorResponse.message;

  const makeErrorMessage = (code: ErrorCode, message: string) => {
    if (code === ErrorCode.INVALID_PASSWORD) {
      return errorMessageByInvalidPasswordErrorCode(code, message);
    }
    return errorMessageByCode(code, message);
  };

  const errorLogs = {
    stack: _.get(error, "stack"),
    message: makeErrorMessage(code, message),
    originMessage: message,
    status: code,
    httpStatus,
    httpStatusText,
    errorAt,
    errorData,
  };
  return errorLogs;
};
