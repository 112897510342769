import { i18n, TFunction } from "next-i18next";
import { translate } from "src/locales";

export enum MessageCode {
  VERIFIED_IP = "1",
}

export const messageByCode = (
  messageCode: MessageCode,
  defaultMessage = "",
) => {
  const t = i18n?.t.bind(i18n) as TFunction;
  const messages: { [key in MessageCode]: string } = {
    [MessageCode.VERIFIED_IP]: translate(["libs", "message", "VERIFIED_IP"], t),
  };

  return messages[messageCode] || defaultMessage;
};
