import _ from "lodash";
import { flow, Instance, types } from "mobx-state-tree";

import User, { GUEST_ID } from "src/stores/model/User";
import {
  login as accountLogin,
  me as accountMe,
  verifyEmail as accountVerifyEmail,
  recoverPassphrase as accountRecoverPassphrase,
  resetPassphraseWithResetToken as accountResetPassphraseWithResetToken,
  refreshShortAccessToken,
} from "src/apis/accounts";
import { storage } from "src/libs/cookieStorage";
import { storage as localStorage } from "src/libs/localStorage";
import { gTagUser } from "src/libs/analytics";
import { isBrowser } from "src/utils/browser";
import { setSentryUser } from "src/libs/sentry";
import { makeWalletErrorByCode, ErrorCode } from "src/libs/error";
import { isApiAccessTokenExpired } from "src/utils/datetime";
import {
  OtpDto,
  PassphraseRecoverRequest,
  PassphraseResetRequest,
} from "src/__generate__/api";

export const MAX_ATTEPMT_PASSWORD = 5;

const AuthStore = types
  .model("AuthStore", {
    user: types.optional(User, { id: GUEST_ID }),
    otp: types.frozen<OtpDto | null>(null),
    ips: types.frozen<string | undefined>(undefined),
    serverAccessToken: types.optional(types.string, ""),
    serverSecret: types.optional(types.string, ""),
  })
  .views((self) => {
    return {
      apiAccessToken() {
        if (isBrowser) {
          return storage().accessToken();
        }
        return self.serverAccessToken;
      },
      get apiSecret() {
        if (isBrowser) {
          return storage().secret();
        }
        return self.serverSecret;
      },
      get isLogin() {
        return self.user.email && self.user.password;
      },
      get existOTP() {
        return Boolean(self.otp);
      },
    };
  })
  .actions((self) => {
    const afterCreate = () => {
      setSentryUser({
        username: `GUEST`,
      });
    };

    const clear = () => {
      self.serverSecret = "";
      self.serverAccessToken = "";
      storage().saveSecret("");
      storage().saveAccessToken("");
    };

    const initialize = flow(function* () {
      yield me();
    });

    const checkAccessToken = () => {
      if (_.isEmpty(self.apiAccessToken())) {
        throw makeWalletErrorByCode(ErrorCode.ACCESS_GUEST);
      }
      if (isApiAccessTokenExpired(self.apiAccessToken())) {
        clear();
        throw makeWalletErrorByCode(ErrorCode.FORBIDDEN_ERROR_HOUR);
      }
      return;
    };

    const checkExpiredPassword = () => {
      if (self.user.isPassphraseExpired) {
        throw makeWalletErrorByCode(ErrorCode.EXPIRED_PASSWORD);
      }
    };

    const me = flow(function* () {
      const response: RetrieveAsyncFunc<typeof accountMe> = yield accountMe();
      self.user = User.create(response);
      const { email, name } = response;
      setSentryUser({
        email,
        username: name ?? "",
      });
    });

    const setServerTokenAndIp = (params: {
      serverAccessToken: string;
      serverSecret: string;
      ips?: string;
    }) => {
      const { serverAccessToken, serverSecret, ips } = params;
      self.serverAccessToken = serverAccessToken;
      self.serverSecret = serverSecret;
      self.ips = ips;
    };

    const firstLogin = flow(function* ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) {
      const response: RetrieveAsyncFunc<typeof accountLogin> =
        yield accountLogin({ email, passphrase: password });
      const { otp } = response;
      self.user = User.create({
        id: response.id,
        email,
        password,
      });
      self.otp = otp ?? null;
      return response;
    });

    const loginWithOtp = flow(function* ({ otp }: { otp?: string }) {
      const { email, name, password } = self.user;
      const response: RetrieveAsyncFunc<typeof accountLogin> = yield login({
        email,
        password,
        otp,
      });
      setSentryUser({
        email,
        username: name,
      });
      return response;
    });

    const login = flow(function* ({
      email,
      password,
      otp,
    }: {
      email: string;
      password: string;
      otp?: string;
    }) {
      const response: RetrieveAsyncFunc<typeof accountLogin> =
        yield accountLogin({ email, passphrase: password, otpCode: otp });
      const { otp: responseOTP, ...rest } = response;
      self.user = User.create({ ...rest, password });
      localStorage().setOTPInitialized(!Boolean(responseOTP));
      saveAccessToken(response?.accessToken ?? "");
      gTagUser(response.email, response.roles.join(","));
      return response;
    });

    const refreshAccessToken = flow(function* (otpCode: string) {
      const response: RetrieveAsyncFunc<typeof refreshShortAccessToken> =
        yield refreshShortAccessToken({ accountId: self.user.id, otpCode });
      saveAccessToken(response.accessToken ?? "");
      self.serverAccessToken = response.accessToken ?? "";
    });

    const saveAccessToken = (accessToken: string) => {
      storage().saveAccessToken(accessToken);
    };

    const logout = () => {
      self.user = User.create({ id: GUEST_ID });
      setSentryUser({
        username: GUEST_ID,
      });
      clear();
    };

    const verifyEmail = flow(function* (email: string) {
      const response: RetrieveAsyncFunc<typeof accountVerifyEmail> =
        yield accountVerifyEmail(email);
      return response.isExists;
    });

    const recoverPassphrase = flow(function* (
      params: PassphraseRecoverRequest,
    ) {
      yield accountRecoverPassphrase(params);
    });

    const resetPassphraseWithResetToken = flow(function* (
      params: {
        accountId: string;
      } & PassphraseResetRequest,
    ) {
      yield accountResetPassphraseWithResetToken(params);
    });

    return {
      afterCreate,
      initialize,
      checkAccessToken,
      checkExpiredPassword,
      firstLogin,
      login,
      loginWithOtp,
      logout,
      refreshAccessToken,
      me,
      setServerTokenAndIp,
      verifyEmail,
      recoverPassphrase,
      resetPassphraseWithResetToken,
    };
  });

export type IAuthStore = Instance<typeof AuthStore>;
export default AuthStore;
