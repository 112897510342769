import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#BFF009"
      />
      <mask
        id="mask0_6946_14784"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="4"
        width="12"
        height="16"
      >
        <path d="M17.0021 4H6V19.939H17.0021V4Z" fill="white" />
      </mask>
      <g mask="url(#mask0_6946_14784)">
        <path
          d="M11.6246 7.98425C11.6246 7.35074 12.143 6.83462 12.7795 6.83462H14.846V4.0376H13.8127H12.7795C10.5901 4.0376 8.81478 5.80485 8.81478 7.98425C8.81478 8.55295 8.93643 9.09354 9.15388 9.58148C7.45351 10.297 6.32442 11.8172 6.06151 13.7409C5.75436 15.8531 6.60454 18.1316 8.45604 19.2323C10.1085 20.2682 12.6922 20.1997 14.0879 18.7626V19.7166H16.9641V9.13386H12.7807C12.1443 9.13386 11.6246 8.61779 11.6246 7.98425ZM14.1518 11.9321V14.5885C14.1518 16.0561 12.9564 17.2449 11.4833 17.2449C10.0102 17.2449 8.81478 16.0549 8.81478 14.5885C8.81478 13.1221 10.0102 11.9321 11.4833 11.9321H14.1518Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
