import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4637_3769)">
        <rect
          width="24"
          height="24"
          rx="4"
          fill="url(#paint0_linear_4637_3769)"
        />
        <path
          d="M17.614 7.99315H2.58398L6.38648 4.2019H21.4165L17.614 7.99315ZM17.614 19.7982H2.58398L6.38648 16.0088H21.4165M6.38648 13.8957H21.4165L17.614 10.1044H2.58398"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4637_3769"
          x1="22.5"
          y1="2"
          x2="1"
          y2="22.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <clipPath id="clip0_4637_3769">
          <rect width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
