import { requestAPI } from "src/apis/requestApi";
import {
  Blockchain,
  CoinControllerApiFactory,
  CreateCoinRequest,
} from "src/__generate__/api";

const coinControllerApi = () =>
  CoinControllerApiFactory(undefined, "", requestAPI());

export const getCoins = async (isApproved = true) => {
  const response = await coinControllerApi().getCoins1(isApproved);
  return response.data;
};

export const createCoin = async (request: CreateCoinRequest) => {
  const response = await coinControllerApi().createCoin(request);
  return response.data;
};

export const checkCoinWithBlockchainAndAddressRegistered = async (
  blockchain: Blockchain,
  address: string,
) => {
  const response =
    await coinControllerApi().checkCoinWithBlockchainAndAddressRegistered1(
      blockchain,
      address,
    );
  return response.data;
};
