import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";
import { WithTranslation, withTranslation } from "next-i18next";

import MaterialForm from "src/components/Form/MaterialForm";
import { palette } from "src/themes/palette";
import { spacing } from "src/themes/spacing";
import BottomButton from "src/components/Button/BottomButton";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import {
  H3Bold,
  Body1Regular,
  Body1RegularPre,
  Body1BoldSpan,
} from "src/components/Typography/Typography";

import useLoading from "src/hooks/useLoading";

export type Props = {
  className?: string;
  onSubmit: () => Promise<void>;
} & WithTranslation;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckIcon = styled(MaterialIcon)`
  margin-right: 11px;
`;

const Title = styled(H3Bold)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Description = styled(Body1RegularPre)`
  color: ${palette.darkgrey.main};
  margin-top: ${spacing.small4};
`;

const SetAccountNewPasswordCompleteForm = (props: Props) => {
  const { className, onSubmit } = props;
  const { isLoading: isSubmitLoading, wrapperLoading: submit } = useLoading({
    func: async () => {
      await onSubmit();
    },
    deps: [onSubmit],
  });

  return (
    <MaterialForm
      className={className}
      size={"large"}
      HeaderComponent={
        <Header>
          <Title>
            <CheckIcon
              name="check_circle"
              fontSize={30}
              color={palette.green.main}
            />
            새로운 비밀번호가 설정되었습니다.
          </Title>
          <Description>계정에 새로운 비밀번호가 적용됩니다.</Description>
        </Header>
      }
      FooterComponent={
        <BottomButton
          buttonName={"확인"}
          buttonProps={{
            variant: "contained",
            color: "primary",
            size: "large",
            onClick: submit,
            isLoading: isSubmitLoading,
          }}
        />
      }
    />
  );
};

export default withTranslation()(SetAccountNewPasswordCompleteForm);
