import { flow, Instance, types } from "mobx-state-tree";

import { inbox } from "src/apis/requests";
import { ClientRequestStatus } from "src/__generate__/api";
import Inbox from "src/stores/Inbox";
import Outbox from "src/stores/Outbox";
import Proceeding from "src/stores/Proceeding";

const RequestStore = types
  .model("RequestStore", {
    inbox: types.optional(
      types.late(() => Inbox),
      {},
    ),
    outbox: types.optional(
      types.late(() => Outbox),
      {},
    ),
    proceeding: types.optional(
      types.late(() => Proceeding),
      {},
    ),
    pendingApprovalInboxCount: types.optional(types.number, 0),
    inboxTotalCount: types.optional(types.number, 0),
  })
  .actions((self) => {
    const fetchPendingApprovalInboxCount = flow(function* () {
      const response: RetrieveAsyncFunc<typeof inbox> = yield inbox({
        request: {
          status: ClientRequestStatus.PendingApproval,
        },
        pageable: {
          page: 0,
          size: 1,
        },
      });
      self.pendingApprovalInboxCount = response.pagination.totalCount;
    });

    const fetchInboxTotalCount = flow(function* () {
      const response: RetrieveAsyncFunc<typeof inbox> = yield inbox({
        request: {},
        pageable: {
          page: 0,
          size: 1,
        },
      });
      self.inboxTotalCount = response.pagination.totalCount;
    });

    return {
      fetchPendingApprovalInboxCount,
      fetchInboxTotalCount,
    };
  });

export type IRequestStore = Instance<typeof RequestStore>;
export default RequestStore;
