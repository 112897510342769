import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import MaterialPaper from "src/components/Paper/MaterialPaper";
import { spacing } from "src/themes/spacing";
import { evaluationCSS } from "src/themes/evaluation";
import { palette } from "src/themes/palette";
import { shouldForwardProp } from "src/themes/config";

type SizeType = "medium" | "large" | "full";

export type Props = {
  className?: string;
  size?: SizeType;
  HeaderComponent?: React.ReactNode;
  ContentComponent?: React.ReactNode;
  FooterComponent?: React.ReactNode;
};

const sizeMap: Record<SizeType, any> = {
  medium: css`
    width: 480px;
  `,
  large: css`
    width: 560px;
  `,
  full: css`
    width: 100%;
  `,
};

const Container = styled(MaterialPaper, {
  shouldForwardProp,
})<{
  $size?: SizeType;
}>`
  padding: 48px;
  border-radius: 2px;
  outline: none;
  border: 1px solid ${palette.mediumgrey.sub};
  ${evaluationCSS.level1}
  ${({ $size }) => ($size ? sizeMap[$size] : css``)}
`;

const Header = styled.div`
  padding-bottom: ${spacing.small6};
`;

const Content = styled.div`
  padding-top: ${spacing.small6};
  padding-bottom: ${spacing.small6};
`;

const Footer = styled("div", {
  shouldForwardProp,
})<{ $isEmptyContent: boolean }>`
  ${({ $isEmptyContent }) =>
    $isEmptyContent
      ? css`
          padding-top: ${spacing.medium2};
        `
      : css`
          padding-top: ${spacing.small6};
        `}
`;

const MaterialForm = (props: Props) => {
  const {
    className,
    size,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
  } = props;
  return (
    <Container $size={size} className={className}>
      {HeaderComponent ? <Header>{HeaderComponent}</Header> : null}
      {ContentComponent ? <Content>{ContentComponent}</Content> : null}
      {FooterComponent ? (
        <Footer $isEmptyContent={Boolean(ContentComponent)}>
          {FooterComponent}
        </Footer>
      ) : null}
    </Container>
  );
};

export default MaterialForm;
