import { CustodyRequestV2Status } from "src/__generate__";

export const ALL_CUSTODY_REQUEST_V2_REGISTER_WALLET_STATUSES = [
  CustodyRequestV2Status.Requested,
  CustodyRequestV2Status.PendingSigning,
  CustodyRequestV2Status.PendingApproval,
  CustodyRequestV2Status.PendingFinalApproval,
];
export const ALL_CUSTODY_REQUEST_V2_TRANSFER_STATUSES = [
  CustodyRequestV2Status.Requested,
  CustodyRequestV2Status.PendingSigning,
  CustodyRequestV2Status.PendingApproval,
  CustodyRequestV2Status.PendingFinalApproval,
];
