import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2a4.382 4.382 0 014.387 4.387A4.382 4.382 0 0112 10.775a4.382 4.382 0 01-4.387-4.388A4.382 4.382 0 0112 2zm0 20c-3.75 0-7.065-1.861-9-4.682.045-2.893 6-4.478 9-4.478 2.985 0 8.955 1.585 9 4.478C19.065 20.14 15.75 22 12 22z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
