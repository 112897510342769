import typography from "src/themes/typography";
import spacing from "src/themes/spacing";
import { createTheme } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { palette } from "./palette";

const theme = createTheme({
  palette,
  typography,
  spacing,
  zIndex: {
    appBar: 10,
    drawer: 100,
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          marginTop: 8,
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          ...typography.body2Medium,
          color: palette.darkgrey.main,

          "&.Mui-selected": {
            ...typography.h4,
            color: palette.primary.main,
          },
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: {
          ...typography.body2Medium,
          color: palette.darkgrey.main,

          "&.Mui-selected": {
            ...typography.h4,
            color: palette.primary.main,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: palette.primary.main,
            "&:hover": {
              backgroundColor: palette.primary.main,
            },
          },
        },
      },
    },
  },
});

export default theme;
