import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <rect width={24} height={24} rx={4} fill="#4F68E7" />
      <path
        d="M11.998 15.732l5.252-2.72-5.252 6.488-5.248-6.488 5.248 2.72z"
        fill="#fff"
      />
      <path
        d="M11.998 4.5l5.249 7.64-5.249 2.72-5.248-2.72 5.248-7.64z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
