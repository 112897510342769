import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.998 16.976L19 13.35 11.998 22 5 13.35l6.998 3.627z"
      fill="#000"
      fillOpacity={0.5}
    />
    <path
      d="M11.998 2l6.998 10.185-6.998 3.63L5 12.184 11.998 2z"
      fill="#000"
      fillOpacity={0.5}
    />
  </svg>
);

export default SvgComponent;
