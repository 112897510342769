import { Instance, flow, types } from "mobx-state-tree";
import { Country } from "src/__generate__";
import { getCountries } from "src/apis/organization-management/organizationManagement";

const CountriesStore = types
  .model("CountriesStore", {
    data: types.optional(types.array(types.frozen<Country>()), []),
    isInitialized: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get countries() {
        return Array.from(self.data);
      },
    };
  })
  .actions((self) => {
    const initialize = flow(function* () {
      const response: RetrieveAsyncFunc<typeof getCountries> =
        yield getCountries();
      self.data.replace(response);
      self.isInitialized = true;
    });
    return {
      initialize,
    };
  });

export type ICountriesStore = Instance<typeof CountriesStore>;
export default CountriesStore;
