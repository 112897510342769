import _ from "lodash";
import { flow, Instance, types } from "mobx-state-tree";

import { outbox } from "src/apis/requests";
import {
  makeDetailedClientRequestDtoToRequestItem,
  transformRequestStatusToIntegrationRequestStatus,
  IntegrationRequestStatus,
  transformClientRequestTypeToIntegrationRequestType,
  IntegrationRequestType,
} from "src/interfaces/request";
import {
  ClientRequestStatus,
  ClientRequestBoxDto,
  ClientRequestType,
} from "src/__generate__/api";
import { getRootStore } from "src/stores/StoreHelper";

type Variables = {
  walletId: string;
  status?: ClientRequestStatus;
  type?: ClientRequestType;
};

const DEFAULT_PAGE_SIZE = 15;
const INITIALIZE_VARIABLES: Variables = {
  status: undefined,
  walletId: "",
};

const Outbox = types
  .model("Outbox", {
    data: types.optional(types.array(types.frozen<ClientRequestBoxDto>()), []),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
    variables: types.frozen<Variables>(INITIALIZE_VARIABLES),
    hasMore: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get requestViews() {
        return _.orderBy(
          self.data.map((item) =>
            makeDetailedClientRequestDtoToRequestItem(
              { ...item, custodyRequest: undefined },
              getRootStore(self).walletStore,
            ),
          ),
          ["createdAt"],
          ["desc"],
        );
      },
      get integrationStatus() {
        return self.variables.status
          ? transformRequestStatusToIntegrationRequestStatus(
              self.variables.status,
            )
          : IntegrationRequestStatus.ALL;
      },
      get integrationType() {
        return self.variables.type
          ? transformClientRequestTypeToIntegrationRequestType(
              self.variables.type,
            )
          : IntegrationRequestType.ALL;
      },
      get isSearch() {
        const { walletId, status, type } = self.variables;
        return Boolean(walletId || status || type);
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
      self.hasMore = true;
      self.variables = INITIALIZE_VARIABLES;
      self.data.clear();
    };

    const fetch = flow(function* () {
      const { walletId, status, type } = self.variables;

      const response: RetrieveAsyncFunc<typeof outbox> = yield outbox({
        request: {
          walletId: walletId ? walletId : undefined,
          status,
          type,
        },
        pageable: {
          page: self.page,
          size: self.size,
        },
      });
      self.data.replace(response.results);
      self.totalCount = response.pagination.totalCount;
      self.hasMore = false;
    });

    const setPage = flow(function* ({
      page,
      size,
    }: {
      page: number;
      size?: number;
    }) {
      self.page = page;
      self.size = size ?? self.size;
      yield fetch();
    });

    const initialize = flow(function* (options: Partial<Variables>) {
      clear();
      self.variables = {
        ...options,
        walletId: options.walletId ?? "",
      };
      yield fetch();
    });

    const append = flow(function* () {
      yield setPage({
        page: self.page + 1,
      });
    });

    const search = flow(function* (options: Partial<Variables>) {
      const existVariables = self.variables;
      clear();
      self.variables = {
        ...existVariables,
        ...options,
      };
      yield fetch();
    });

    return {
      clear,
      initialize,
      setPage,
      search,
      append,
      refresh: fetch,
    };
  });

export type IOutbox = Instance<typeof Outbox>;
export default Outbox;
