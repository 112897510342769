import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 10 10" fill="none" {...props}>
      <path opacity={0.3} d="M1 0v9h9" stroke="#000" />
    </svg>
  );
}

export default SvgComponent;
