import {
  AuthorityName,
  CustodyNonSigningRequestStatus,
} from "src/__generate__";
import { CoinRegistrationRequestStatusType } from "src/components/Tab/CoinRegistrationsTab";

export const ALL_COIN_REGISTRATION_STATUSES = [
  CustodyNonSigningRequestStatus.Canceled,
  CustodyNonSigningRequestStatus.PendingApproval,
  CustodyNonSigningRequestStatus.PendingFinalApproval,
  CustodyNonSigningRequestStatus.Declined,
  CustodyNonSigningRequestStatus.Processed,
];

export const COIN_REGISTER_AVAILABLE_AUTHORITIES = [
  AuthorityName.CustodySigner,
  AuthorityName.CustodyManager,
];

export const transformCoinReigstrationRequestStatusByAuthorityName = (
  authorityName: AuthorityName,
) => {
  const map: Partial<Record<AuthorityName, CustodyNonSigningRequestStatus[]>> =
    {
      [AuthorityName.CustodyAdmin]: [
        CustodyNonSigningRequestStatus.PendingFinalApproval,
      ],
      [AuthorityName.CustodyManager]: [
        CustodyNonSigningRequestStatus.PendingApproval,
      ],
    };
  return map[authorityName];
};

export const transformCoinReigstrationRequestStatusType = (
  requestStatusType: CoinRegistrationRequestStatusType,
) => {
  const map: Record<
    CoinRegistrationRequestStatusType,
    CustodyNonSigningRequestStatus | undefined
  > = {
    all: undefined,
    pendingApproval: CustodyNonSigningRequestStatus.PendingApproval,
    pendingFinalApproval: CustodyNonSigningRequestStatus.PendingFinalApproval,
    processed: CustodyNonSigningRequestStatus.Processed,
  };
  return map[requestStatusType];
};

export const getSubmitNameByStatus = (
  status: CustodyNonSigningRequestStatus,
) => {
  const submitNameByStatus: Record<CustodyNonSigningRequestStatus, string> = {
    [CustodyNonSigningRequestStatus.PendingApproval]: "승인하기",
    [CustodyNonSigningRequestStatus.PendingFinalApproval]: "최종 승인하기",
    [CustodyNonSigningRequestStatus.Processed]: "PROCESSED",
    [CustodyNonSigningRequestStatus.Declined]: "DECLINED",
    [CustodyNonSigningRequestStatus.Canceled]: "CANCELED",
  };
  return submitNameByStatus[status];
};
