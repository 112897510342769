import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import {
  Body3Regular,
  Body3Medium,
} from "src/components/Typography/Typography";
import { palette } from "src/themes/palette";
import { evaluationCSS } from "src/themes/evaluation";
import MaterialIcon, {
  Props as MaterialIconProps,
} from "src/components/Icon/MaterialIcon";
import { PasswordRule } from "src/utils/regex";
import { shouldForwardProp } from "src/themes/config";

type Props = {
  className?: string;
} & PasswordRule;

const Container = styled.div`
  width: 305px;
  height: 195px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 8px;
  background: #ffffff;
  border-radius: 4px;
  z-index: 100;
  ${evaluationCSS.level3}
`;

const Title = styled(Body3Regular)`
  color: ${palette.darkgrey.main};
  margin-bottom: 16px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckLabel = styled(Body3Medium, {
  shouldForwardProp,
})<{ $checked: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  ${({ $checked }) =>
    $checked
      ? css`
          color: ${palette.primary.main};
        `
      : css`
          color: ${palette.red.main};
        `}
`;

const CheckIcon = styled(MaterialIcon)`
  margin-right: 8px;
`;

const PasswordValidationForm = (props: Props) => {
  const { className, hasAtLeast8Char, hasAlphabet, hasNumber, hasSpecialChar } =
    props;

  const getCheckIconPropsByChecked = (
    checked: boolean,
  ): Pick<MaterialIconProps, "name" | "color"> => {
    if (checked) {
      return {
        name: "check_circle",
        color: palette.green.main,
      };
    }
    return {
      name: "cancel",
      color: palette.red.main,
    };
  };

  return (
    <Container className={className}>
      <Title>필수 조건 만족 여부:</Title>
      <List>
        <CheckLabel $checked={hasAtLeast8Char}>
          <CheckIcon
            {...getCheckIconPropsByChecked(hasAtLeast8Char)}
            fontSize={19}
          />
          {"8자 이상"}
        </CheckLabel>
        <CheckLabel $checked={hasAlphabet}>
          <CheckIcon
            {...getCheckIconPropsByChecked(hasAlphabet)}
            fontSize={19}
          />
          {"영문 대문자 또는 소문자"}
        </CheckLabel>
        <CheckLabel $checked={hasNumber}>
          <CheckIcon {...getCheckIconPropsByChecked(hasNumber)} fontSize={19} />
          {"숫자 한 개 이상"}
        </CheckLabel>
        <CheckLabel $checked={hasSpecialChar}>
          <CheckIcon
            {...getCheckIconPropsByChecked(hasSpecialChar)}
            fontSize={19}
          />
          {"특수문자"}
        </CheckLabel>
      </List>
    </Container>
  );
};

export default PasswordValidationForm;
