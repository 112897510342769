import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 13H13V16H11V13H7.99996V11H11V8.00001H13V11H16V13ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11.9999 20C7.58992 20 3.99992 16.41 3.99992 12C3.99992 7.59002 7.58992 4.00002 11.9999 4.00002C16.4099 4.00002 19.9999 7.59002 19.9999 12C19.9999 16.41 16.4099 20 11.9999 20Z"
      fill="black"
    />
  </svg>
);

export default SvgComponent;
