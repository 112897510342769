import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_2007_5)">
        <path
          d="M16 0C22.628 0 28 5.372 28 12C28 18.628 22.628 24 16 24C9.372 24 4 18.628 4 12C4 5.372 9.372 0 16 0Z"
          fill="black"
        />
        <path
          d="M17.7092 12.8716L16.8652 13.7156L22.694 19.5444L23.538 18.7004L17.7092 12.8716ZM9.2948 4.4544L8.4508 5.2984L14.2792 11.1272L15.1232 10.2832L9.2948 4.4544ZM22.6924 4.4568L16.868 10.2892L17.7128 11.1328L23.5372 5.3L22.6924 4.4568ZM14.28 12.8712L8.456 18.704L9.3004 19.5476L15.1252 13.7148L14.28 12.8712Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2007_5"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2007_5"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2007_5"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgComponent;
