import {
  ClientWalletControllerApiFactory,
  AddressBookDto,
} from "src/__generate__/api";
import { requestAPI } from "src/apis/requestApi";

const clientWalletControllerApiFactory = () =>
  ClientWalletControllerApiFactory(undefined, "", requestAPI());

export const addressBooks = async (request: {
  walletId: string;
}): Promise<AddressBookDto[]> => {
  const { walletId } = request;
  const response =
    await clientWalletControllerApiFactory().getAddressBooks1(walletId);
  return response.data;
};
