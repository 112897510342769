import _ from "lodash";
import { flow, Instance, types } from "mobx-state-tree";

import { LoginIpDto } from "src/__generate__/api";

const DEFAULT_PAGE_SIZE = 15;

export const EMPTY_SYMBOL = "EMPTY";

const LoginIps = types
  .model("LoginIps", {
    data: types.optional(types.array(types.frozen<LoginIpDto>()), []),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      get loginIpViews() {
        return Array.from(self.data.values());
      },
      get loginIpPaginationViews() {
        return _.slice(
          this.loginIpViews,
          self.page * self.size,
          (self.page + 1) * self.size,
        );
      },
      get existsLoginIps() {
        return this.loginIpViews.length !== 0;
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
    };

    const setPage = ({ page, size }: { page: number; size?: number }) => {
      self.page = page;
      self.size = size ?? self.size;
    };

    return {
      clear,
      setPage,
      refresh: fetch,
    };
  });

export type ILoginIps = Instance<typeof LoginIps>;
export default LoginIps;
