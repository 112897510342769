import {
  AuthorityName,
  Blockchain,
  CoinRegistrationControllerApiFactory,
  CustodyNonSigningRequestStatus,
} from "src/__generate__";
import { requestAPI } from "../requestApi";
import { Pageable } from "../interface";
import { AccountAuthSubmitParams } from "src/hocs/withAccountAuth";

const coinRegistrationControllerApiFactory = () =>
  CoinRegistrationControllerApiFactory(undefined, "", requestAPI());

const ALL_COIN_REGISTRATION_STATUSES = [
  CustodyNonSigningRequestStatus.PendingApproval,
  CustodyNonSigningRequestStatus.PendingFinalApproval,
  CustodyNonSigningRequestStatus.Processed,
  CustodyNonSigningRequestStatus.Declined,
  CustodyNonSigningRequestStatus.Canceled,
];

export const getCoinRegistrationRequests = async (request: {
  statuses?: CustodyNonSigningRequestStatus[];
  pageable: Pageable;
}) => {
  const { statuses, pageable } = request;
  const { page, size, sort } = pageable;
  const response =
    await coinRegistrationControllerApiFactory().getCoinRegistrationRequests(
      undefined,
      statuses,
      page,
      size,
      sort,
    );
  return response.data;
};

export const getCoinRegistrationRequestStatusCountByStatuses = async (
  statuses: CustodyNonSigningRequestStatus[],
) => {
  const response = await getCoinRegistrationRequests({
    statuses,
    pageable: {
      page: 0,
      size: 1,
    },
  });
  return response.pagination.totalCount;
};

export const getCoinRegistrationProcessCounts = async () => {
  const [allCount, pendingApprovalCount, pendingFinalApprovalCount] =
    await Promise.all([
      getCoinRegistrationRequestStatusCountByStatuses(
        ALL_COIN_REGISTRATION_STATUSES,
      ),
      getCoinRegistrationRequestStatusCountByStatuses([
        CustodyNonSigningRequestStatus.PendingApproval,
      ]),
      getCoinRegistrationRequestStatusCountByStatuses([
        CustodyNonSigningRequestStatus.PendingFinalApproval,
      ]),
    ]);
  return {
    allCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  };
};

export const createCoinRegistration = async (request: {
  blockchain: Blockchain;
  address: string;
  name: string;
  symbol: string;
  decimal: number;
  passphrase: string;
  otpCode: string;
}) => {
  const response =
    await coinRegistrationControllerApiFactory().createCoinRegistration(
      request,
    );
  return response.data;
};

export const approveCoinRegistration = async (
  requestId: string,
  params: AccountAuthSubmitParams,
) => {
  const { passphrase, otpCode } = params;

  await coinRegistrationControllerApiFactory().approveCoinRegistration(
    requestId,
    {
      passphrase,
      otpCode,
    },
  );
};

export const finalApproveCoinRegistration = async (
  requestId: string,
  params: AccountAuthSubmitParams,
) => {
  const { passphrase, otpCode } = params;

  await coinRegistrationControllerApiFactory().finalApproveCoinRegistration(
    requestId,
    {
      passphrase,
      otpCode,
    },
  );
};
