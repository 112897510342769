import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";
import { WithTranslation, withTranslation } from "next-i18next";

import MaterialForm from "src/components/Form/MaterialForm";
import { palette } from "src/themes/palette";
import { spacing } from "src/themes/spacing";
import MaterialTextField from "src/components/TextField/MaterialTextField";
import PasswordTextField from "src/components/TextField/PasswordTextField";
import BottomButton from "src/components/Button/BottomButton";
import MaterialIcon from "src/components/Icon/MaterialIcon";
import useLoading from "src/hooks/useLoading";
import {
  H3Bold,
  Body1Regular,
  Body1RegularPre,
  Body1BoldSpan,
} from "src/components/Typography/Typography";

import { makePasswordErrorMessage, ErrorCode } from "src/libs/error";
import { isInvalidPassword } from "src/utils/regex";

type FormStates = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export type Props = {
  className?: string;
  email: string;
  passphraseLastModifiedDayBefore: number;
  passphraseExpiredPeriodDays: number;
  defaultPassword?: string;
  onSubmit: (formStates: Omit<FormStates, "confirmPassword">) => void;
} & WithTranslation;

type FormErrorStates = {
  errors: { [key in keyof FormStates]: string };
};

type States = FormStates & FormErrorStates;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckIcon = styled(MaterialIcon)`
  margin-right: 11px;
`;

const Title = styled(H3Bold)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Description = styled(Body1RegularPre)`
  color: ${palette.darkgrey.main};
  margin-top: ${spacing.small4};
`;

const HighlightDescription = styled(Body1BoldSpan)`
  color: ${palette.primary.main};
`;

const Content = styled(Body1Regular)`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${palette.darkgrey.main};
`;

const DEFAULT_ERRORS = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};

const SetAccountNewPasswordForm = (props: Props) => {
  const {
    className,
    email,
    passphraseLastModifiedDayBefore,
    passphraseExpiredPeriodDays,
    defaultPassword,
  } = props;
  const [state, setState] = useState<States>({
    oldPassword: "",
    password: defaultPassword ?? "",
    confirmPassword: "",
    errors: DEFAULT_ERRORS,
  });
  const disabledPassword = !state.password || !state.confirmPassword;

  const onOldPasswordChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setState((prevState) => {
        return {
          ...prevState,
          oldPassword: value,
        };
      });
    },
    [],
  );

  const onPasswordChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setState((prevState) => {
        return {
          ...prevState,
          password: value,
        };
      });
    },
    [],
  );

  const onConfirmPasswordChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setState((prevState) => {
        return {
          ...prevState,
          confirmPassword: value,
        };
      });
    },
    [],
  );

  const setPasswordError = (message: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          password: message,
        },
      };
    });
  };

  const setConfirmPasswordError = (message: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          confirmPassword: message,
        },
      };
    });
  };

  const { isLoading: isSubmitLoading, wrapperLoading: submit } = useLoading({
    func: async () => {
      const { oldPassword, password, confirmPassword } = state;
      if (disabledPassword) {
        return;
      }
      setState((prevState) => {
        return {
          ...prevState,
          errors: { ...DEFAULT_ERRORS },
        };
      });
      if (password === email) {
        setPasswordError(
          "계정의 이메일 주소와 동일합니다. 다른 비밀번호로 설정해주세요.",
        );
        return;
      }
      if (password === oldPassword) {
        setPasswordError(
          "기존의 비밀번호와 동일합니다. 다른 비밀번호로 설정해주세요.",
        );
        return;
      }
      if (isInvalidPassword(password)) {
        setPasswordError(makePasswordErrorMessage(password));
        return;
      }
      if (password !== confirmPassword) {
        setConfirmPasswordError(
          "입력한 비밀번호가 서로 일치하지 않습니다. 다시 확인해주세요.",
        );
        return;
      }
      try {
        await props.onSubmit(state);
      } catch (error: any) {
        if (error.status === ErrorCode.INVALID_PASSWORD) {
          setState((prevState) => {
            return {
              ...prevState,
              errors: { ...prevState.errors, oldPassword: error.message },
            };
          });
          return;
        }
        setState((prevState) => {
          return {
            ...prevState,
            errors: { ...prevState.errors, password: error.message },
          };
        });
      }
    },
    deps: [disabledPassword, state],
  });

  return (
    <MaterialForm
      className={className}
      size={"large"}
      HeaderComponent={
        <Header>
          <Title>
            <CheckIcon
              name="info_outline"
              fontSize={36}
              color={palette.bluegrey.main}
            />
            새로운 비밀번호를 설정하세요.
          </Title>
          <Description>
            {`마지막으로 `}
            <HighlightDescription>
              {`비밀번호를 변경하신지 ${passphraseLastModifiedDayBefore}일`}
            </HighlightDescription>
            {`이 지났습니다.
코다에서는 회원님의 자산을 안전하게 보호하기 위해 ${passphraseExpiredPeriodDays}일동안 비밀번호를 변경하지 않은 경우 비밀번호 변경을 안내하고 있습니다.

`}
            <HighlightDescription>
              {`영문 대문자 또는 소문자, 숫자 한 개 이상, 특수문자를 포함한 8자 이상`}
            </HighlightDescription>
            {`의 새로운 비밀번호를 설정해주세요.`}
          </Description>
        </Header>
      }
      ContentComponent={
        <Content>
          <MaterialTextField
            label={"기존 비밀번호"}
            autoFocus={true}
            style={{
              marginBottom: spacing.medium2,
            }}
            type={"password"}
            onChange={onOldPasswordChangeText}
            onEnter={submit}
            helperText={state.errors.oldPassword}
            size={"large"}
            value={state.oldPassword}
          />
          <PasswordTextField
            label={"새로운 비밀번호"}
            style={{
              marginBottom: spacing.medium2,
            }}
            type={"password"}
            onChange={onPasswordChangeText}
            onEnter={submit}
            helperText={state.errors.password}
            size={"large"}
            value={state.password}
          />
          <MaterialTextField
            label={"비밀번호 재입력"}
            style={{
              marginBottom: spacing.medium2,
            }}
            type={"password"}
            onChange={onConfirmPasswordChangeText}
            onEnter={submit}
            helperText={state.errors.confirmPassword}
            size={"large"}
            value={state.confirmPassword}
          />
        </Content>
      }
      FooterComponent={
        <BottomButton
          buttonName={"설정하기"}
          buttonProps={{
            variant: "contained",
            color: "primary",
            size: "large",
            onClick: submit,
            disabled: disabledPassword,
            isLoading: isSubmitLoading,
          }}
        />
      }
    />
  );
};

export default withTranslation()(SetAccountNewPasswordForm);
