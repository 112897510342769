import {
  AccountWalletAuthorityDto,
  AuthorityDto,
  AuthorityName,
  ClientWalletV2ControllerApiFactory,
} from "src/__generate__";
import { requestAPI } from "src/apis/requestApi";

const clientWalletV2ControllerApiFactory = () =>
  ClientWalletV2ControllerApiFactory(undefined, "", requestAPI());

export const accounts = async (params: {
  walletId: string;
}): Promise<AccountWalletAuthorityDto[]> => {
  const { walletId } = params;
  const response =
    await clientWalletV2ControllerApiFactory().getWalletAccounts(walletId);
  return response.data;
};

export const inviteWalletMembers = async (request: {
  walletId: string;
  accountIds: string[];
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { walletId, accountIds, passphrase, otpCode } = request;
  await clientWalletV2ControllerApiFactory().inviteWalletAccount(walletId, {
    accountIds,
    passphrase,
    otpCode,
  });
};

export const deleteWalletMember = async (request: {
  walletId: string;
  accountId: string;
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { walletId, accountId, passphrase, otpCode } = request;
  await clientWalletV2ControllerApiFactory().deleteWalletAccount(
    walletId,
    accountId,
    {
      passphrase,
      otpCode,
    },
  );
};

export const updateWalletMemberRole = async (request: {
  walletId: string;
  accountId: string;
  authorityName: AuthorityName;
  passphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { walletId, accountId, authorityName, passphrase, otpCode } = request;
  await clientWalletV2ControllerApiFactory().changeWalletAccountAuthority(
    walletId,
    accountId,
    {
      authorityName,
      passphrase,
      otpCode,
    },
  );
};

export const getRoles = async (walletId: string): Promise<AuthorityDto[]> => {
  const response =
    await clientWalletV2ControllerApiFactory().getWalletAuthorities(walletId);
  return response.data;
};
