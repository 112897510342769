import React, { useEffect, useCallback, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import MaterialTextField, {
  Props as MaterialTextFieldProps,
} from "src/components/TextField/MaterialTextField";
import { palette } from "src/themes/palette";
import PasswordValidationForm from "src/components/Form/Account/PasswordValidationForm";
import { testPassword } from "src/utils/regex";

type Props = { value?: string } & Omit<MaterialTextFieldProps, "value">;

const Container = styled.div`
  position: relative;
`;

const Content = styled(MaterialTextField)``;

const PasswordValidationContainer = styled.div`
  position: absolute;
  top: ${`calc(100% + ${12}px)`};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const PasswordValidationFormView = styled(PasswordValidationForm)`
  min-width: 305px;
`;

const PasswordTextField = (props: Props) => {
  const { style, className, value, ...rest } = props;
  const [isFocus, setFocus] = useState(false);

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  return (
    <Container style={style} className={className}>
      <Content
        type={"password"}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        {...rest}
      />
      {isFocus ? (
        <PasswordValidationContainer>
          <PasswordValidationFormView {...testPassword(value ?? "")} />
        </PasswordValidationContainer>
      ) : null}
    </Container>
  );
};

export default PasswordTextField;
