import { getRoot } from "mobx-state-tree";
import { ProviderContext } from "notistack";

import { IAuthStore } from "src/stores/AuthStore";
import { IWalletStore } from "src/stores/WalletStore";
import { IDialog } from "src/stores/Dialog";
import { IOrganizationStore } from "src/stores/OrganizationStore";
import { IRequestStore } from "src/stores/RequestStore";
import { IBlockchains } from "./Blockchains";

export interface IStoreType {
  blockchains: IBlockchains;
  authStore: IAuthStore;
  walletStore: IWalletStore;
  organizationStore: IOrganizationStore;
  requestStore: IRequestStore;
  dialog: IDialog;
  snackbar: ProviderContext | null;
}

export interface IStores {
  store: IStoreType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRootStore = (self: any): IStoreType => getRoot<any>(self);
