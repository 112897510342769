// tslint:disable:object-literal-sort-keys
const ko = {
  test: "테스트",
  developmentBannerAlert:
    "이 대시보드는 개발 환경에서 동작하며, 테스트넷, 메인넷 대시보드와는 별도의 계정을 사용합니다.",
  testnetBannerAlert:
    "이 대시보드는 테스트넷에서 동작하며, 메인넷 대시보드와는 별도의 계정을 사용합니다.",
  expectedFeeAmount: "예상 수수료",
  amount: "수량",
  receivedAddress: "받는 주소",
  withdrawMethod: "출금 방법",
  loginLabel: "로그인 정보",
  time: "시간",
  utc: "(UTC+9)",
  requester: "요청자",
  wallet: "지갑",
  withdrawWallet: "출금 지갑",
  targetWallet: "해당 지갑",
  addressName: "주소 이름",
  address: "주소",
  withdrawalAddress: "출금 주소",
  coinToken: "코인/토큰",
  coinTokenName: "코인/토큰명",
  allCoinAndToken: "모든 코인/토큰",
  username: "계정",
  ipAddress: "IP주소",
  ipAddress1: "IP 주소",
  registeredIpAddress: "등록한 IP주소",
  browser: "브라우저",
  location: "접속 위치",
  confirmDashbord: "대시보드로 확인하러 가기",
  masterWallet: "마스터 지갑",
  userWallet: "사용자 지갑",
  withdrawal: "출금",
  deposit: "입금",
  next: "다음",
  password: "비밀번호",
  confirm: "확인",
  name: "이름",
  ifHelp: "도움이 필요하시다면",
  contact: "로 연락해 주세요.",
  copyMessage: "가 복사되었습니다.",
  authButton: "인증하기",
  transactionHash: "트랜잭션 해시",
  deposit1: "입금하기",
  status: "상태",
  warningWithdrawalLimit1:
    "1일 출금 한도는 한국 시간 기준 00:00시에 초기화 됩니다.",
  warningWIthdrawalLimit2:
    "‘출금 가능’ 권한의 팀원은 출금 한도 이내에서만 출금 가능합니다.",
  copyWalletAddressMessage: "의 지갑 주소가 복사되었습니다.",
  reissue: "재발급하기",
  depositAddress: "입금 주소",
  activate: "활성화하기",
  withdrawalLimit: "출금 한도",
  gasFeeWalletAddressCopy: "수수료 지갑 주소 복사",
  oldPassword: "기존 비밀번호",
  newPasswordInput: "새로운 비밀번호 입력",
  newPasswordConfirmInput: "새로운 비밀번호 재입력",
  verifyNewAndNewConfirmDiff:
    "입력한 비밀번호가 서로 일치하지 않습니다. 다시 확인해주세요.",
  create: "생성하기",
  cancel: "취소",
  depositAddressCopyMessage: "입금 주소가 클립보드에 복사되었습니다.",
  previousStep: "이전 단계로",
  content: "내역",
  addIpAddress: "IP 주소 추가하기",
  select: "선택하세요",
  qrCode: "QR코드",
  depositWarning: "입금 주의사항",
  withdraw: "출금하기",
  failedCreateWallet: "지갑 생성 실패",
  ifAbnormalAct: "비정상적인 활동이라면",
  delete: "삭제하기",
  contactEmail: "이메일로 연락주시면 도와드리겠습니다.",
  close: "닫기",
  createMasterWallet: "마스터 지갑 생성",
  forgottenOTP: "OTP를 분실하셨나요?",
  forgottenPassword: "비밀번호를 잊어버리셨나요?",
  requestWithdraw: "출금 요청",
  withdrawAddressWhitelist: "출금 주소 화이트리스팅",
  inactiveOnlyAdmin: "관리자만 비활성화할 수 있습니다.",
  activeOnlyAdmin: "관리자만 활성화할 수 있습니다.",
  searchNameAndSymbol: "이름 / 심볼로 검색",
  emailAddress: "이메일 주소",
  save: "저장하기",
  newPassword: "새로운 비밀번호",
  verifyWrongPassword: "비밀번호를 잘못 입력하였습니다.",
  apiSync: "API 연동",
  searchUserAndIDAndAddress: "계정 / ID / 주소로 검색",
  verifyDuplicateLabel: "중복되는 이름을 설정할 수 없습니다.",
  security: "보안",
  walletName: "지갑 이름",
  walletPassword: "지갑 비밀번호",
  setNewWalletPasswordMessage: "새로운 지갑 비밀번호가 설정 되었습니다.",
  mismatchWalletPassword: "계정 비밀번호가 일치하지 않습니다.",
  retryGasPriceCompleteMessage: "실시간 적정 가스비로 다시 전송되었습니다.",
  setRecoverkitInfo: "복구 키트 정보 입력하기",
  setNewPassword: "새로운 비밀번호 설정하기",
  setOTP: "OTP 입력하기",
  setName: "이름 설정하기",
  setWalletPassword: "지갑 비밀번호 설정하기",
  cannotRecoverWallet: "지갑 복구가 불가능",
  createMasterWallet1: "마스터 지갑 생성하기",
  total: "총",
  count: "개",
  oneDaysWithdrawalLimit: "1일 출금 한도",
  oneTimeWithdrawalLimit: "1회 출금 한도",
  ID: "ID",
  creating: "생성중",
  createUserWallet: "사용자 지갑 추가하기",
  createDepositAddress: "입금 주소 추가하기",
  authority: "권한",
  lessThanWithralLimit: "한도 이하의 출금",
  delete1: "삭제",
  addAddress: "주소 추가하기",
  teamManager: "팀 관리",
  sixDigits: "6자리 숫자",
  directInput: "직접 입력",
  sendingCoin: "보낼 코인",
  willWithdrawAmount: "출금 가능",
  requestWithdrawMessage:
    "마지막 결재 이후 확정된 잔액입니다. 처리 대기, 또는 처리 중인 수량은 포함하지 않습니다.",
  withdrawAmount: "출금 수량",
  minimalExpectedAmount: "최소 예치 금액",
  minimalExpectedAmountMessage: `수수료가 실시간으로 변할 수 있으므로
최소 예치 금액 이상 입금하셔야 합니다.`,
  myGasFee: "보유한 수수료",
  gasFeePayment: "수수료 대납 이벤트 적용",
  depositCoinAndToken: "지갑에 코인/토큰을 입금하세요",
  chargeGasFee: "가스비 충전하기",
  inactiveWhitelist: "화이트리스팅이 비활성화되어 있습니다.",
  ifAbnormalAct1: "비정상적인 시도라면",
  reissueAccessToken: "대시보드에서 해당 사용자의 Access Token을 재발급하세요.",
  withdrawInfo: "출금 정보",
  ifAbnormalAct2: "비정상적인 출금 시도라면",
  completeActiveWhitelist: "화이트리스팅 활성화 완료",
  isActiveWhitelisting: "화이트리스팅을 활성화하시겠습니까?",
  completeInactiveWhitelist: "화이트리스팅 비활성화 완료",
  isInactiveWhitelisting: "화이트리스팅을 비활성화하시겠습니까?",
  inactive: "비활성화하기",
  emptyCoin: "코인이 없습니다.",
  empty: "없음",
  availableCurrentRegisteredIp:
    "지금부터 등록된 IP 주소로만 API 호출이 가능합니다.",
  change: "변경하기",
  retryGasFeeMessage:
    "이더리움 실시간 적정 가스비가 급등하여 채굴이 지연되고 있습니다. 5분 이내에 채굴되기를 원하신다면 실시간 적정 가스비로 다시 전송하세요.",
  retryRealGasFee: "실시간 가스비로 다시 전송하기",
  gasPrice: "가스비 (Gas Price)",
  fee: "수수료",
  retryRealGasFeeMessage: "실시간 적정 가스비로 다시 전송합니다",
  newTransactionMessage: "새로운 트랜잭션이 발생합니다.",
  realGasFee: "실시간 적정 가스비",
  realNetworkBasedGasFee:
    "실시간 네트워크 기준으로 5분 이내에 트랜잭션이 채굴될 수 있는 가스비",
  doNotBeSurprised: "놀라지 마세요!",
  basedTransactionMessage:
    "가스비가 더 낮게 설정된 기존 트랜잭션이 먼저 채굴될 수도 있습니다.",
  sameTransactionMessage: `동일한 트랜잭션이 여러 개 발생되지만, 그 중에 하나가 채굴되면 나머지
트랜잭션은 취소됩니다.`,
  dealDuplicateConfirmMessage:
    "거래가 중복으로 확정되는 일은 절대 발생하지 않습니다.",
  retryCancel: "재전송 취소",
  retry: "재전송하기",
  searchTransactionHash: "트랜잭션 해시로 검색할 수 있습니다.",
  setPassword1: "의 비밀번호를 입력하세요.",
  masterWalletUnderUserWalletMessage:
    "마스터 지갑 하위에 있는 사용자 지갑에 일괄 적용됩니다.",
  applyEachUserWalletMessage: "개별 사용자 지갑에 해당 한도가 각각 적용됩니다.",
  withdrawalLimitMessage:
    "'출금 가능' 권한의 팀원은 출금 한도 이내에서만 출금 가능합니다.",
  warningConfirm1: "번의 컨펌 발생 후 지갑에 반영됩니다.",
  warningConfirm2: "반드시 입금 주소를 확인 후 전송하시기 바랍니다.",
  warningConfirm3: "가스비는 해당 지갑이 아닌 가스비 지갑에 입금하셔야 합니다.",
  warningConfirm4: "만 입금 가능합니다.",
  libs: {
    message: {
      VERIFIED_IP: "IP주소가 인증되었습니다. 로그인을 진행해주세요.",
    },
    error: {
      UNKOWN_ERROR: "알 수 없는 에러가 발생하였습니다. 다시 시도해주세요",
      WRONG_WALLET_NAME: "올바르지 않은 지갑 이름입니다.",
      EXISTS_WALLET_NAME:
        "다른 마스터 지갑과 중복되는 이름을 설정할 수 없습니다.",
      WRONG_ADDRESS: "올바른 주소를 입력해주세요.",
      LACK_BALANCE_AMOUNT: "잔액이 부족합니다.",
      NETWORK_ERROR: "알 수 없는 에러가 발생하였습니다. 다시 시도해주세요",
      WRONG_RECOVERKIT_INPUT: "데이터가 일치하지 않습니다.",
      ACCESS_GUEST: "잘못된 접근",
      FORBIDDEN_ERROR_HOUR: `로그인 후 30분이 지나 보안을 위해 자동 로그아웃 되었습니다.
다시 로그인 해주세요.`,
      GASFEE_INSUFFICIENT_FUNDS_CREATE_WALLET:
        "가스비가 부족해 지갑 생성에 실패했습니다.",
      GASFEE_INSUFFICIENT_WITHDRAW_WALLET: "가스비 지갑 잔액이 부족합니다.",
      EMPTY_ALLOWED_ADDRESS_NAME: "이름을 입력해주세요.",
      EXISTS_ALLOWED_ADDRESS: "이미 등록된 주소입니다.",
      ALREADY_EXISTS_ALLOWED_ADDRESS_NAME:
        "다른 주소와 중복되는 이름을 설정할 수 없습니다.",
      SAME_GAS_PRICE: "이미 동일한 가스비로 재전송 되었습니다.",
      UTXO_LOCKED: `앞선 트랜잭션 전송이 완료되지 않아 출금에 실패하였습니다. 앞선 트랜잭션 전송을 완료하시거나, 40초 후에 재시도하세요.`,
      UNAUTHORIZED: `로그인 후 30분이 지나 보안을 위해 자동 로그아웃 되었습니다.
다시 로그인 해주세요.`,
      EMAIL_DOES_NOT_EXISTS: "존재하지 않는 이메일입니다.",
      INVALID_PASSWORD: "비밀번호를 잘못 입력하였습니다.",
      OTP_AUTHENTICATION_FAILED: "인증 코드를 잘못 입력하셨습니다.",
      NOT_VERIFIED_IP: `새로운 IP주소에서 로그인을 시도하였습니다.
%email%으로 전송된 메일을 확인하시고 해당 IP 주소를 인증해주세요.`,
      TIMEOUT_IP: `24시간이 지나 인증번호의 유효기간이 만료되었습니다.
새로운 인증 메일을 받으시려면 다시 로그인해주세요.`,
      ALREADY_VERIFIED_IP: "이미 확인되었습니다.",
      INVALID_IP_VERIFY_REQUEST: `로그인을 시도했던 IP주소와 다릅니다.
동일한 장소와 네트워크 환경에서 인증해주세요.
만약 동일한 문제가 반복된다면 고객센터로 연락해주세요.`,
      FAIL_TO_REPLACE_TRANSACTION:
        "실시간 적정 가스비가 해당 트랜잭션의 가스비보다 낮아 재전송하지 않습니다.",
      ALREADY_PROCESSED_TRANSACTION: "이미 채굴된 트랜잭션입니다.",
      ALREADY_REPLACED_TRANSACTION: "이미 교체된 트랜잭션입니다.",
      INVALID_MINIMUM_BALANCE: "가스비가 부족해 지갑 생성에 실패했습니다.",
    },
  },
  utils: {
    blockchain: {
      getBlockchainItemBy: {
        bitcoin: "비트코인",
        bitcoin_deprecated: "비트코인_구버전",
        ethereum: "이더리움",
        ethereum_deprecated: "이더리움_구버전",
        kaia: "카이아",
        kaia_deprecated: "카이아_구버전",
        polygon: "폴리곤",
        polygon_deprecated: "폴리곤_구버전",
        amoy: "폴리곤",
        overProtocol: "오버프로토콜",
        bsc: "바이낸스 스마트 체인",
        wemix: "위믹스",
        xpla: "엑스플라",
        bizauto: "비즈오토",
      },
      makeDepositWarnings: {
        ethWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
          line2: "$t(warningConfirm2)",
          line3: "$t(warningConfirm3)",
        },
        kaiaWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        polygonWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        overWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        bscWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        wemixWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        xplaWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        bizautoWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        bitcoinWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
          line2: "$t(warningConfirm2)",
        },
        common: {
          token: " 토큰",
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
        },
      },
      makeGasFeeDepositFormWarnings: {
        ethWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        kaiaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        polygonWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        overWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        bscWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        wemixWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        xplaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        bizautoWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        common: {
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
          line2:
            "해당 주소는 마스터 지갑 주소와는 다른 별도의 지갑 주소입니다.",
        },
      },
      makeGasFeeDepositWarnings: {
        ethWarning: {
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
          line2Prefix: "",
          line2Postfix: "$t(warningConfirm1)",
          line3: "$t(warningConfirm2)",
        },
        kaiaWarning: {
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        polygonWarning: {
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        overWarning: {
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        bscWarning: {
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        wemixWarning: {
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        xplaWarning: {
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        bizautoWarning: {
          line1Prefix: ``,
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
      },
      walletNameByBlockchain: {
        masterWallet: "$t(masterWallet)",
        userWallet: "$t(userWallet)",
        wallet: "$t(wallet)",
      },
    },
    number: {
      getOverFloatingAndNotNumber: {
        regexMessage1: "소수점 ",
        regexMessage2: "자리까지만 입력할 수 있습니다.",
        regexMessage3: "숫자를 입력해주세요.",
      },
    },
  },
  stores: {
    model: {
      user: {
        transformRoleName: {
          admin: "관리자",
          spender: "출금 가능",
          viewer: "조회 가능",
          haechi: "해치랩스",
        },
      },
    },
    balances: {
      selectedAllBalanceName: "$t(allCoinAndToken)",
    },
    gasFeeUsages: {
      contentByTransactionType: {
        withdrawal: "$t(withdrawal)",
        deposit: "$t(deposit)",
        unknownExternalCall: "알 수 없는 외부 호출",
        smartContractCall: "스마트컨트랙트 호출",
        masterWalletDeployment: "$t(createMasterWallet)",
        userWalletDeployment: "사용자 지갑 생성",
      },
    },
    recoverWallet: {
      verifyPassphaseDiff: "데이터가 일치하지 않습니다.",
    },
    withdrawPolicies: {
      policyDescription: {
        and: ` 외 `,
        count: `개`,
      },
    },
  },
  pages: {
    "401": {
      title: "접근할 수 없는 페이지입니다.",
      description: `관리자만 해당 페이지를 확인할 수 있습니다.`,
    },
    "404": {
      title: "페이지를 찾을 수 없습니다.",
      description: `에러가 계속 된다면 아래 이메일로 연락주세요.`,
    },
    account: {
      otp: {
        guide: {
          title: "OTP 설정하기",
          description: `최초 로그인시 OTP 설정이 필요합니다. 
Google Authenticator에서 QR코드를 스캔해 주세요.`,
          androidDownloadButton: "안드로이드폰 다운로드",
          iosDownloadButton: "아이폰 다운로드",
          nextButton: "$t(next)",
        },
        register: {
          submitButton: "$t(authButton)",
          forgottenOtp: "$t(forgottenOTP)",
        },
      },
      login: {
        title: "로그인하기",
        emailLabel: "이메일",
        passwordLabel: "$t(password)",
        forgottenPassword: "$t(forgottenPassword)",
        loginButton: "로그인",
      },
    },
    dashboard: {
      operation: {
        authority: {
          appbarLeftCaption: "권한 관리",
          title: "더 쉽고 안전하게 협업하세요.",
          description: `관리자는 팀원의 역할을 설정할 수 있습니다.
각 팀원에게 알맞은 역할과 권한을 부여하세요.`,
          authorityInfoButton: `각 역할은 어떤 권한을 가지나요?`,
        },
      },
      request: {
        withdraw: {
          appbarLeftCaption: "$t(requestWithdraw)",
          title: "출금 요청을 확인하세요.",
          description: `한도를 초과한 출금은 관리자의 승인이 필요합니다.
요청을 확인하고 출금을 승인하세요.`,
          withdrawApprovalCompleteTitle: "출금 승인 완료",
          withdrawApprovalCompleteSubmitButton: "$t(confirm)",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
      },
      wallet: {
        "[blockchain]": {
          "[id]": {
            user: {
              "[userWalletId]": {
                userAddressCopyMessage: "사용자 지갑 주소 복사하기",
                copyAddressCompleteMessage: "$t(copyMessage)",
              },
            },
            allowedAddress: {
              title: "미리 허용한 주소로만 안전하게 출금하세요.",
              description: `화이트리스팅을 활성화하면 허용되지 않은 주소로의 출금은 차단됩니다.
출금을 허용할 주소를 등록하세요.`,
              contentTitle: "$t(withdrawAddressWhitelist)",
              whitelistInactivated: "$t(inactiveOnlyAdmin)",
              whitelistActivated: "$t(activeOnlyAdmin)",
              canNotLoadMasterWallet: "마스터 지갑을 불러오지 못하였습니다.",
            },
            index: {
              walletAddressCopyMessage: "지갑 주소 복사하기",
              copyAddressCompleteMessage: "$t(copyMessage)",
            },
            policy: {
              title: "$t(withdrawalLimit)",
              description: "코인/토큰별 출금 한도를 설정할 수 있습니다.",
              policyInfoButton: "한도가 어떻게 적용되나요?",
              searchPlaceholder: "$t(searchNameAndSymbol)",
            },
          },
          gasFee: {
            gasFeeName: "수수료 지갑",
            gasFeePayment: "수수료 이벤트 적용",
            gasFeeAddressCopyMessage: "$t(gasFeeWalletAddressCopy)",
            copyAddressCompleteMessage: "$t(copyMessage)",
          },
        },
      },
      account: {
        appbarLeftCaption: "계정 정보",
        usernameLabel: "사용자 이름",
        firstName: "성",
        lastName: "$t(name)",
        emailLabel: "$t(emailAddress)",
        saveButton: "$t(save)",
        passwordLabel: "$t(password)",
        oldPasswordLabel: "$t(oldPassword)",
        oldPasswordPlaceholder: "$t(oldPassword)",
        newPasswordLabel: "$t(newPassword)",
        newPasswordPlaceholder: "$t(newPasswordInput)",
        newPasswordConfirmPlaceholder: "$t(newPasswordConfirmInput)",
        forgottenPassword: "비밀번호를 잊어 버리셨나요?",
        otpButton: "$t(authButton)",
        closeButton: "$t(close)",
        verifyUsernameRule: "영문, 혹은 한글만 입력 가능합니다.",
        verifyNewAndNewConfirmDiff: "$t(verifyNewAndNewConfirmDiff)",
        verifyWrongPassword: "$t(verifyWrongPassword)",
      },
      apiSetting: {
        appbarLeftCaption: "$t(apiSync)",
        title: "API로 쉽고 안전하게 지갑과 연동하세요.",
        description: `API를 통해서도 헤네시스 지갑과 통신할 수 있습니다.
HMAC 암호화, 권한 분리, IP 주소 화이트리스팅을 통해 안전하게 통신하세요.`,
        developmentDocsButton: "개발 문서 확인하기",
      },
    },
  },
  organizations: {
    apiSetting: {
      tab: {
        token: {
          apiSecretCopy: "API Secret 복사",
          refreshApiSecret: "$t(reissue)",
          accessTokenCopy: "Access Token 복사",
          refreshAccessToken: "$t(reissue)",
          createdAccessToken: "$t(create)",
          accessTokenMessage: "Access Token이 클립보드에 복사되었습니다.",
          apiSecretMessage: "API Secret이 클립보드에 복사되었습니다.",
          cancelButton: "$t(cancel)",
          closeButton: "$t(close)",
        },
        allowedIp: {
          title: "허용된 IP 주소에서만 API 호출하기",
          whitelistInactivated: "$t(inactiveOnlyAdmin)",
          whitelistActivated: "$t(activeOnlyAdmin)",
          verifyDuplicateLabel:
            "다른 주소와 중복되는 이름을 설정할 수 없습니다.",
          verifyInvalidIpAddress: "올바른 형식의 IP 주소가 아닙니다.",
          existsIpAddress: "이미 등록된 주소입니다.",
        },
      },
    },
    dashboard: {
      account: {
        tab: {
          account: {},
          locale: {
            languageLabel: "언어",
            timezoneLabel: "타임존",
            saveButton: "$t(save)",
          },
        },
      },
      tab: {
        gasFee: {
          deposit: {
            depositAddressCopyMessage: "$t(depositAddressCopyMessage)",
          },
          usage: {
            searchPlaceholder: "트랜잭션 해시 검색",
          },
        },
        balance: {
          searchPlaceholder: "$t(searchNameAndSymbol)",
        },
        deposit: {
          title: "$t(deposit1)",
          description1: "을 ",
          description2: "를 ",
          description3: "입금할 수 있습니다.",
          depositAddressCopyMessage: "$t(depositAddressCopyMessage)",
        },
        depositAddress: {
          searchPlaceholder: "$t(searchUserAndIDAndAddress)",
          verifyTargetEthWallet: "해당 지갑은 이더지갑입니다.",
          verifyDuplicateLabel: "$t(verifyDuplicateLabel)",
        },
        policy: {
          title: "$t(security)",
          description: `지갑별 보안 정책으로 자산을 안전하게 관리할 수 있습니다.`,
          policyLabel: "$t(withdrawalLimit)",
          allowedAddressLabel: "$t(withdrawAddressWhitelist)",
          active: "활성화",
          inactive: "비활성화",
        },
        setting: {
          walletName: "$t(walletName)",
          walletId: "지갑 ID",
          saveButton: "$t(save)",
          walletPassword: "$t(walletPassword)",
          oldPassword: "$t(oldPassword)",
          newPassword: "$t(newPassword)",
          newPasswordInput: "$t(newPasswordInput)",
          newPasswordInputConfirm: "$t(newPasswordConfirmInput)",
          forgottenPassword: "지갑 비밀번호를 잊어 버리셨나요?",
          walletNameCompleteTitle: "지갑 이름 변경 완료",
          walletNameCompleteDescription: "새로운 지갑 이름이 설정 되었습니다.",
          walletPasswordCompleteTitle: "지갑 비밀번호 변경 완료",
          walletPasswordCompleteDescription: "$t(setNewWalletPasswordMessage)",
          forgottenPasswordCompleteTitle: "지갑 비밀번호 복구 완료",
          forgottenPasswordCompleteDescription:
            "$t(setNewWalletPasswordMessage)",
          authButton: "$t(authButton)",
          closeButton: "$t(close)",
          verifyDuplicateWalletName: "중복된 지갑 이름이 있습니다.",
          verifyWrongPassword: "$t(verifyWrongPassword)",
          verifyOldAndNewSame:
            "새로운 비밀번호가 기존 비밀번호와 동일합니다. 다른 비밀번호를 입력해주세요.",
          verifyNewAndNewConfirmDiff: "$t(verifyNewAndNewConfirmDiff)",
        },
        smartcontract: {
          searchPlaceholder: "스마트컨트랙트 주소 / 트랜잭션 해시 검색",
          retryGasPriceCompleteMessage: "$t(retryGasPriceCompleteMessage)",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
        transfer: {
          coinAndTokenEmptyLabel: "출금 가능한 코인/토큰이 없습니다.",
          searchPlaceholder: "내역 / 트랜잭션 해시 검색",
          retryGasPriceCompleteMessage: "$t(retryGasPriceCompleteMessage)",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
        user: {
          searchPlaceholder: "$t(searchUserAndIDAndAddress)",
          btcWalletMessage: "해당 지갑은 btc지갑입니다.",
          verifyDuplicateLabel: "$t(verifyDuplicateLabel)",
        },
        withdraw: {
          isNotRegisterWhitelistAddress:
            "화이트리스팅에 등록된 주소가 아닙니다.",
          notExistsCoin: "코인이 존재하지 않습니다.",
          notExistsTransaction: "트랜잭션이 존재하지 않습니다.",
          withdrawCompleteTitle: "출금 신청 완료",
          withdrawCompleteSubmitButton: "출금 내역 확인하기",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
      },
    },
    wallet: {
      recover: {
        guide: {
          title: "$t(setRecoverkitInfo)",
          description1: `지갑을 생성할 때 다운로드받은 복구 키트를 이용하여, `,
          description2: `D 영역에 있는 데이터`,
          description3: `를 복사해 아래 텍스트 박스에 붙여넣기 하세요.

만약 출력해서 보관하고 있다면, QR 코드 리더기를 활용하여 
D 영역에 있는 QR코드를 인식하세요.`,
          encryptedPassphrasePlaceholder: `D 영역의 Data를 입력하세요.`,
          submitButton: "$t(next)",
        },
        index: {
          title: "지갑 비밀번호 복구하기",
          step1Name: "$t(setRecoverkitInfo)",
          step2Name: "$t(setNewPassword)",
          step3Name: "$t(setOTP)",
        },
        otp: {
          submitButton: "$t(authButton)",
          previousButton: "$t(previousStep)",
        },
        passphrase: {
          title: "$t(setNewPassword)",
          description: "새로 사용할 지갑 비밀번호를 입력하세요.",
          passwordPlaceholder: "$t(newPasswordInput)",
          confirmPasswordPlaceholder: "$t(newPasswordConfirmInput)",
        },
      },
      register: {
        index: {
          title: " 마스터 지갑 만들기",
          step1Name: "$t(setName)",
          step2Name: "$t(setWalletPassword)",
          step3Name: "복구 키트 다운로드 받기",
        },
        name: {
          title: "$t(setName)",
          description: "새로 만들 지갑의 이름을 설정하세요.",
          walletName: "$t(walletName)",
          submitButton: "$t(next)",
        },
        passphrase: {
          title: "$t(setWalletPassword)",
          description: `새로 만들 마스터 지갑의 비밀번호를 설정하세요.
지갑 입출금 및 사용자 지갑 생성시 비밀번호가 필요합니다.`,
          passwordPlaceholder: "비밀번호 입력",
          confirmPasswordPlaceholder: "비밀번호 재입력",
        },
        recoverkit: {
          title: "복구 키트 다운로드 및 보관",
          description1: `분실을 대비해 키 파일이 담긴 복구 키트를 다운로드해주세요.
복구 키트를 분실하면 `,
          description2: "$t(cannotRecoverWallet)",
          description3: `합니다.`,
          pdfDownloadButton: "다운로드 받기",
          pdfDescription1: "인쇄하여 안전한 곳에 보관",
          pdfDescription2: `컴퓨터에 보관하면 해킹의 위험이 있으니 반드시 인쇄하여 
타인의 손이 닿지 않는 안전한 곳에 보관해주세요.`,
          pdfDescription3: "인쇄하여 안전한 곳에 보관했습니다.",
          pdfDescription4: "파일을 다운로드 폴더에서 삭제",
          pdfDescription5:
            "보안을 위해 다운로드 폴더에서 복구 키트 파일을 삭제해 주세요.",
          pdfDescription6: "파일을 다운로드 폴더에서 삭제했습니다.",
          previousButton: "$t(previousStep)",
          createWalletButton: "$t(createMasterWallet1)",
          createWalletFailedMessage:
            "지갑 생성에 실패했습니다. 다시 시도해주세요.",
        },
      },
    },
  },
  amountStatusBadge: {
    expected: "예상",
    applyEvent: "이벤트",
  },
  dateToolbar: {
    month: "월",
  },
  transferAddressText: {
    toDeposit: "에 입금",
    toWithdraw: "로 출금",
    fromDeposit: "로부터 입금",
  },
  totalCountText: {
    total: "$t(total)",
    count: "$t(count)",
    person: "명",
  },
  withdrawPolicyTable: {
    columns: {
      coinAndToken: "$t(coinToken)",
      walletName: "$t(wallet)",
      oneDayWithdrawAmount: "$t(oneDaysWithdrawalLimit)",
      oneTimeWithdrawAmount: "$t(oneTimeWithdrawalLimit)",
      change: "변경",
    },
    addPolicyButton: "코인/토큰 추가하기",
  },
  userWalletTable: {
    columns: {
      name: "$t(name)",
      id: "$t(ID)",
      address: "$t(address)",
    },
    rows: {
      status: {
        creating: "$t(creating)",
        inactive: "$t(creating)",
        failed: "생성 실패",
      },
      addressRefresh: "다시 시도",
    },
    addUserWalletButton: "$t(createUserWallet)",
  },
  transferTable: {
    userWalletMenusName: "해당 지갑으로 이동",
    masterWalletMenusName: "해당 마스터 지갑으로 이동",
    columns: {
      createdAt: "$t(time)",
      sendUser: "$t(wallet)",
      receiveUser: "$t(content)",
      balance: "$t(amount)",
      txHash: "$t(transactionHash)",
      status: "$t(status)",
    },
    onlyMasterWalletName: "마스터 지갑 내역만 보기",
    onlyMasterWalletNameTooltip:
      "사용자 지갑을 제외한 해당 마스터 지갑의 입출금 내역을 확인할 수 있습니다.",
  },
  smartContractTable: {
    columns: {
      createdAt: "$t(time)",
      address: "스마트 컨트랙트 주소",
      txHash: "$t(transactionHash)",
      status: "$t(status)",
    },
  },
  gasFeeUsageTable: {
    columns: {
      createdAt: "$t(time) $t(utc)",
      wallet: "$t(wallet)",
      content: "$t(content)",
      amount: "사용 수수료",
      txHash: "$t(transactionHash)",
      status: "$t(status)",
    },
    exportCSVButton: "CSV로 내보내기",
  },
  depositAddressTable: {
    columns: {
      name: "$t(name)",
      id: "$t(ID)",
      address: "$t(address)",
    },
    addDepositAddressButton: "$t(createDepositAddress)",
  },
  balanceTable: {
    columns: {
      type: "종류",
      name: "$t(name)",
      userWalletSum: "$t(amount)",
      masterWallet: "$t(masterWallet)",
      userWallet: "사용자 지갑 합산",
      sum: "$t(total)",
    },
    myCoinName: "보유한 코인만 보기",
  },
  authorityTable: {
    me: "나",
    columns: {
      name: "$t(name)",
      email: "$t(emailAddress)",
      role: "$t(authority)",
      loginIp: "$t(ipAddress1)",
      loginIpTooltip: "이메일 인증을 거쳐 로그인이 가능한 IP 주소 목록입니다.",
    },
    rows: {
      loginIps: " 외 ",
      loginIpCount: "$t(count)",
    },
    addMemberButton: "팀원 추가하기",
  },
  authorityInfoByRoleTable: {
    columns: {
      role: "항목",
      admin: "관리자 (Admin)",
      spender: "출금 가능 (Spender)",
      viewer: "조회 가능 (Viewer)",
    },
    rows: {
      transfer: "입출금 내역 및 잔고 조회",
      gasFee: "가스비 조회 및 충전",
      deposit: "지갑 입금",
      limitWithdraw: "$t(lessThanWithralLimit)",
      approvalLimitWithdraw: "한도를 초과한 출금 승인/취소",
      limitWithdrawSetting: "지갑 출금 한도 설정",
      createMasterWallet: "$t(createMasterWallet)",
      createDepositAddressAndUserWallet: "입금 주소/사용자 지갑 생성 ",
      smartContractCall: "스마트 컨트랙트 함수 호출 ",
      changePassword: "지갑 비밀번호 변경/복구",
      changeTeamAuth: "팀원 권한 변경",
      apiSecret: "API Secret 재발급",
    },
    footerTitle: " 현재는 API로만 지원되는 기능입니다.",
  },
  allowedIpTable: {
    columns: {
      name: "$t(name)",
      address: "$t(ipAddress1)",
      delete: "$t(delete1)",
    },
    addIpButton: "$t(addIpAddress)",
  },
  allowedAddressTable: {
    columns: {
      name: "$t(name)",
      coinAndToken: "$t(coinToken)",
      address: "$t(address)",
      delete: "$t(delete1)",
    },
    rows: {
      userNameCaption: " 및 하위의 모든 사용자 지갑",
      allCoinAndToken: "$t(allCoinAndToken)",
      userAdddressCaption: " 외 여러 개",
    },
    addAddressButton: "$t(addAddress)",
  },
  walletTab: {
    transferTitle: "입출금 내역",
    balanceTitle: "잔고",
    depositTitle: "$t(deposit)",
    withdrawTitle: "$t(withdrawal)",
    settingTitle: "설정",
    userWalletTitle: "$t(userWallet)",
    depositAddressTitle: "$t(depositAddress)",
    smartcontractTitle: "스마트컨트랙트 호출 내역",
    policyTitle: "$t(security)",
  },
  gasFeeWalletTab: {
    gasFeeDepositTitle: "$t(deposit)",
    gasFeeUsageTitle: "사용 내역",
  },
  accountTab: {
    accountTitle: "계정 설정",
    languageTitle: "언어",
  },
  apiSettingTab: {
    tokenTitle: "키",
    allowedIpTitle: "IP 주소 화이트리스팅",
  },
  custodyRequestWithdrawTab: {
    allTitle: "전체",
    requestTitle: "요청",
    signingTitle: "서명 대기",
    pendingApproveTitle: "승인 대기",
    finalPendingApproveTitle: "최종 승인 대기",
  },
  needGasFeeSnackbar: {
    message1: `가스비 지갑 잔액이 부족합니다.
잔액이 `,
    message2: " 이상이어야 합니다.",
    submitButton: "가스비 입금하기",
  },
  withdrawCoinSelectbox: {
    groupName: "보유한 코인",
    depositButton: "$t(deposit1)",
    placeholder: "$t(select)",
  },
  walletSelectbox: {
    userWalletAddressName: " 및 하위의 사용자 지갑",
    emptyMenuItemLabel: "다른 지갑이 없습니다.",
    selectedMenuItemLabel: "$t(select)",
  },
  walletAddressSelectbox: {
    userWalletAddressName: "의 사용자 지갑",
    emptyMenuItemLabel: "등록된 주소가 없습니다.",
    selectedMenuItemLabel: "주소를 선택하세요.",
  },
  dateSelectbox: {
    format: "YYYY년 M월",
  },
  coinSelectbox: {
    placeholder: "$t(select)",
  },
  depositProgress: {
    title: "입금 탐색중",
  },
  createWalletLoading: {
    title: "지갑 생성 중",
    ethAndBtcDescription: `지갑 생성을 위해 가스비가 사용됩니다.
생성이 완료될 때까지 약 3분 가량 소요됩니다.`,
    klayDescription: `지갑 생성을 위해 가스비가 사용됩니다.`,
  },
  userWalletAddressList: {
    emptyListLabel: "사용자 지갑이 없습니다.",
  },
  dashboardLayout: {
    userAccountMenus: {
      account: "계정 정보",
      logout: "로그아웃",
    },
    gasFeeAddressCopyMemssage: "가스비 지갑 주소가 클립보드에 복사되었습니다.",
    depositAddressCopyMemssage: "$t(depositAddressCopyMessage)",
  },
  gasFeesInterval: {
    chargeFeeMessage: "수수료 지갑이 충전되었습니다.",
  },
  checkWalletsInterval: {
    activeMasterWalletMessage: "지갑이 생성되었습니다",
  },
  checkExpiredInterval: {
    otpSubmitName: "$t(authButton)",
    otpCancelButton: "$t(cancel)",
  },
  roleGroupIcon: {
    retrieve: "조회",
    thanLessWithdraw: "$t(lessThanWithralLimit)",
    thanMoreWithdraw: "한도 이상의 출금 승인",
    walletManager: "지갑 관리",
    teamManager: "$t(teamManager)",
  },
  eventStatusIcon: {
    pendingApproval: "승인 대기",
    reject: "거절됨",
    requested: "전송중",
    pending: "채굴 대기",
    mined: "채굴됨",
    confirmed: "완료",
    failed: "전송 실패",
    replaced: "취소됨",
    reverted: "실패",
    internalReverted: "실패",
  },
  setPasswordForm: {
    confirmPasswordError: "$t(verifyNewAndNewConfirmDiff)",
    passwordLabel: "$t(walletPassword)",
    previousButton: "$t(previousStep)",
    nextButton: "$t(next)",
  },
  otpForm: {
    title: "$t(setOTP)",
    description: "Google Authenticator에 보이는 6자리 인증 코드를 입력하세요.",
    otpLabel: "인증 코드",
    otpPlaceholder: "$t(sixDigits)",
  },
  otpGuideForm: {
    title: "OTP 설정하기",
    description1: `최초 로그인시 OTP 설정이 필요합니다. 
`,
    description2: `Google Authenticator에서 QR코드를 스캔`,
    description3: `해 주세요.`,
    androidDownloadButton: "안드로이드폰 다운로드",
    iosDownloadButton: "아이폰 다운로드",
    nextButton: "$t(next)",
  },
  depositAddressForm: {
    depositAddress: "$t(depositAddress)",
    depositAddressTooltip: "입금 주소 복사",
    qrCode: "$t(qrCode)",
    depositWarningTitle: "$t(depositWarning)",
    closeButton: "$t(close)",
  },
  withdrawForm: {
    title: "$t(withdraw)",
    toLabel: "$t(receivedAddress)",
    toAddressLabel: "주소 선택",
    toInputLabel: "$t(directInput)",
    toCoinLabel: "$t(sendingCoin)",
    emptyCoinLabel: "보유한 코인이 없습니다.",
    amountPlaceholder: "수량 입력",
    submitButton: "$t(withdraw)",
    btcAmountWarning: "출금 가능 잔액이 부족합니다.",
    ethAndKlayAmountWarning: "잔액이 부족합니다.",
    eosAmountWarning: "잔액이 부족합니다.",
    minWithdrawableAmount:
      "{{name}}은 {{amount}} {{symbol}} 이상만 출금 가능합니다.",
  },
  withdrawCalculatorForm: {
    title: "$t(willWithdrawAmount)",
    withdrawAmountLabel: "$t(withdrawAmount)",
  },
  btcWithdrawCalculatorForm: {
    title: "$t(willWithdrawAmount)",
    withdrawAmountLabel: "$t(withdrawAmount)",
    estimatedFeeLabel: "$t(expectedFeeAmount)",
    withdrawTotalAmountLabel: "총 출금 금액",
  },
  gasFeeDepositForm: {
    title: "수수료 입금하기",
    description1: "",
    description2: "을 입금할 수 있습니다.",
    minimalDepositTitle: "$t(minimalExpectedAmount)",
    minimalDepositTooltip: "$t(minimalExpectedAmountMessage)",
    myGasFeeTitle: "$t(myGasFee)",
    gasFeePayment: "$t(gasFeePayment)",
    neededGasFeeWarning: "수수료가 부족합니다.",
    depositAddressTitle: "$t(depositAddress)",
    depositAddress: "$t(address)",
    depositQRCode: "$t(qrCode)",
    depositAddressCopyTooltip: "$t(gasFeeWalletAddressCopy)",
    gasFeeWarningTitle: "$t(depositWarning)",
  },
  withdrawPolicyEmptyStates: {
    title: "출금 한도를 설정하세요",
    description: `관리자는 마스터 지갑과 사용자 지갑의
출금 한도를 설정할 수 있습니다.`,
    descriptionForBitcoin: `관리자는 지갑의 출금 한도를 설정할 수 있습니다.`,
    submitButton: "코인 추가하기",
  },
  walletEmptyStates: {
    title: "아직 지갑이 없어요",
    descriptionForViewer: `관리자만 마스터 지갑을 만들 수 있습니다.`,
    description: `새로운 마스터 지갑을 만들어보세요.`,
    submitButton: "마스터 지갑 만들기",
  },
  userWalletsEmptyStates: {
    ethAndKlay: {
      title: "발급된 사용자 지갑이 없어요.",
      description: `새로운 사용자 지갑을 생성해보세요.`,
      submitButton: "$t(createUserWallet)",
    },
    btc: {
      title: "발급된 입금 주소가 없어요.",
      description: `새로운 입금 주소를 생성해보세요.`,
      submitButton: "$t(createDepositAddress)",
    },
  },
  transferEmptyStates: {
    title: "입출금 내역이 없어요",
    description: "$t(depositCoinAndToken)",
    submitButton: "$t(deposit1)",
  },
  smartContractEmptyStates: {
    title: "스마트 컨트랙트 호출 내역이 없어요",
    description: `SDK 또는 API로 스마트 컨트랙트 함수를 호출해보세요.`,
    submitButton: "문서 확인하기",
  },
  searchEmptyStates: {
    title: "검색 결과가 없습니다.",
    description: `다른 조건으로 다시 검색해주세요.`,
    submitButton: "초기화",
  },
  requestWithdrawEmptyStates: {
    title: "출금 요청이 없습니다.",
    description: `승인이 필요한 출금이 표시됩니다.`,
  },
  registerWalletNeededGasFeeEmptyStates: {
    title: "$t(failedCreateWallet)",
    description: `가스비가 부족하여 지갑 생성에 실패했습니다.
가스비를 충전한 후 다시 시도해주세요.`,
    submitButton: "$t(chargeGasFee)",
  },
  registerWalletFailedEmptyStates: {
    title: "$t(failedCreateWallet)",
    description1: `지갑 생성이 실패했습니다. 다시 시도하세요.
문제가 지속될 경우`,
    description2: `로 문의해 주세요.`,
    submitButton: "다시 시도하기",
  },
  gasFeeUsageEmptyStates: {
    title: "사용 내역이 없어요",
    description: `수수료 지갑에 코인을 입금하세요`,
    submitButton: "$t(deposit1)",
  },
  balanceEmptyStates: {
    title: "잔고가 없어요",
    description: "$t(depositCoinAndToken)",
    submitButton: "$t(deposit1)",
  },
  allowedIpListEmptyStates: {
    title: "등록된 IP 주소가 없습니다.",
    description: `API 호출을 허용할 IP 주소를 등록하세요.`,
    submitButton: "$t(addIpAddress)",
  },
  allowedIpInactiveListEmptyStates: {
    title: "$t(inactiveWhitelist)",
    description: `활성화하면 허용된 IP 주소에서만 API를 호출할 수 있습니다.`,
    submitButton: "$t(activate)",
  },
  allowedAddressListEmptyStates: {
    title: "$t(inactiveWhitelist)",
    description: `활성화하면 허용된 주소로만 출금할 수 있습니다.`,
    submitButton: "$t(activate)",
  },
  registerAllowedIpEmail: {
    title: `새로운 IP 주소가 화이트리스트에 
등록되었습니다.`,
    description1: ``,
    description2: `님, 안녕하세요.
새로운 IP 주소가 화이트리스트에 등록되었습니다.
지금부터 해당 IP 주소에서 API 호출이 가능합니다.`,
    registerAt: "$t(time)",
    username: "$t(username)",
    registeredIpAddress: "$t(registeredIpAddress)",
    registerAllowedIpWarning: {
      ifNotDirectly: "$t(ifAbnormalAct)",
      removeIpAddress: "대시보드에서 즉시 해당 IP 주소를 삭제하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  registerAllowedAddressEmail: {
    title: `새로운 출금 주소가 화이트리스트에
등록되었습니다.`,
    description1: ``,
    description2: `님, 안녕하세요.
새로운 출금 주소가 화이트리스트에 등록되었습니다.
지금부터 해당 주소로 출금이 가능합니다.`,
    registerAt: "$t(time)",
    username: "$t(username)",
    targetWalletName: "$t(targetWallet)",
    addressName: "$t(addressName)",
    coinToken: "$t(coinToken)",
    walletAddress: "$t(address)",
    registerAllowedAddressWarning: {
      ifNotDirectly: "$t(ifAbnormalAct1)",
      removeWithdrawAddress: "대시보드에서 즉시 해당 출금 주소를 삭제하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  notAllowedIpCallEmail: {
    title: `허용되지 않은 IP 주소에서 
API 호출이 시도되어 차단하였습니다.`,
    description1: ``,
    description2: `님, 안녕하세요.
`,
    highlightDescription: `허용되지 않은 IP 주소에서 API 호출이 시도`,
    description3: `되어 차단하였습니다.
정상적인 시도라면 대시보드에서 해당 IP 주소를 등록해주세요.`,
    registerAt: "$t(time)",
    ipAddress: "$t(ipAddress)",
    location: "$t(location)",
    ipAccessTokenWarning: {
      ifNotDirectly: "$t(ifAbnormalAct1)",
      refreshAccessToken: "$t(reissueAccessToken)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "IP 주소 화이트리스트 확인하러 가기",
  },
  notAllowedAddressCallEmail: {
    title: `화이트리스팅되지 않은 주소로 
출금이 시도되어 차단하였습니다.`,
    description1: ``,
    description2: `님, 안녕하세요.
`,
    highlightDescription: `화이트리스팅되지 않은 주소로 출금이 시도`,
    description3: `되어 차단하였습니다.
정상적인 출금 시도라면 대시보드에서 해당 주소를 등록해주세요.`,
    withdrawTitle: "$t(withdrawInfo)",
    registerAt: "$t(time)",
    username: "$t(username)",
    withdrawMethod: "$t(withdrawMethod)",
    coinTokenName: "$t(coinTokenName)",
    targetWalletName: "$t(targetWallet)",
    toAddress: "$t(receivedAddress)",
    count: "$t(amount)",
    withdrawAccessTokenWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct2)",
      refreshAccessToken: "$t(reissueAccessToken)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "출금 주소 화이트리스트 확인하러 가기",
  },
  limitedRequestWithdrawEmail: {
    title: "한도를 초과한 출금 요청이 발생했습니다.",
    description1: ``,
    description2: `님, 안녕하세요.
한도를 초과한 출금이 발생하였습니다.
정상적인 출금 요청이라면 대시보드에서 요청을 승인해주세요.`,
    withdrawTitle: "$t(withdrawInfo)",
    registerAt: "$t(time)",
    requester: "$t(requester)",
    coinTokenName: "$t(coinTokenName)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    count: "$t(amount)",
    expectedFeeAmount: "$t(expectedFeeAmount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct2)",
      rejectRequestWithdraw: "대시보드에서 즉시 출금 요청을 거절하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "출금 요청 확인하기",
  },
  deleteAllowedIpEmail: {
    title: "IP 주소가 화이트리스트에서 삭제되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요.
IP 주소가 화이트리스트에서 삭제되었습니다.
지금부터 해당 IP 주소에서는 API 호출이 차단됩니다.`,
    registerAt: "$t(time)",
    username: "$t(username)",
    deletedIpAddress: "삭제한 IP주소",
    confirmButton: "$t(confirmDashbord)",
  },
  deleteAllowedAddressEmail: {
    title: "출금 주소가 화이트리스트에서 삭제되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요.
출금 주소가 화이트리스트에서 삭제되었습니다.
지금부터 해당 주소로는 출금하실 수 없습니다.`,
    registerAt: "$t(time)",
    username: "$t(username)",
    targetWalletName: "$t(targetWallet)",
    addressName: "$t(addressName)",
    coinToken: "$t(coinToken)",
    walletAddress: "$t(address)",
    confirmButton: "$t(confirmDashbord)",
  },
  allowedIpOnEmail: {
    title: "IP 주소 화이트리스팅이 활성화되었습니다.",
    description: `님, 안녕하세요.
IP 주소 화이트리스팅이 활성화되었습니다.
이제 허용된 IP 주소에서만 API 호출이 가능합니다.`,
    registerAt: "$t(time)",
    username: "$t(username)",
    confirmButton: "$t(confirmDashbord)",
  },
  allowedIpOffEmail: {
    title: "IP 주소 화이트리스팅이 비활성화되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요.
IP 주소 화이트리스팅이 비활성화되었습니다.
이제 모든 IP 주소에서 API 호출이 가능합니다.`,
    registerAt: "$t(time)",
    username: "$t(username)",
    allowedIpWarning: {
      ifNotDirectlyActivity: "$t(ifAbnormalAct)",
      allowedIp: "대시보드에서 즉시 IP 주소 화이트리스팅을 활성화하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  allowedAddressOnEmail: {
    title: "출금 주소 화이트리스팅이 활성화되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요.
출금 주소 화이트리스팅이 활성화되었습니다.
이제 화이트리스트에 등록된 주소로만 출금이 가능합니다.`,
    registerAt: "$t(time)",
    walletName: "$t(wallet)",
    confirmButton: "$t(confirmDashbord)",
  },
  allowedAddressOffEmail: {
    title: "출금 주소 화이트리스팅이 비활성화되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요.
출금 주소 화이트리스팅이 비활성화되었습니다.
이제 모든 주소로 출금 가능합니다.`,
    registerAt: "$t(time)",
    walletName: "$t(wallet)",
    allowedAddressWarning: {
      ifNotDirectlyActivity: "$t(ifAbnormalAct)",
      allowedAddress:
        "대시보드에서 즉시 해당 지갑의 출금 주소 화이트리스팅을 활성화하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  verifyAddressEmail: {
    title: "새로운 IP주소에서 로그인이 시도되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요.
새로운 IP주소에서 시도된 로그인을 차단하였습니다.
로그인 차단을 해제하려면 해당 IP주소를 인증해주세요.`,
    loginLabel: "$t(loginLabel)",
    loginAt: "$t(time)",
    ipAddress: "$t(ipAddress)",
    browser: "$t(browser)",
    location: "$t(location)",
    loginWarning: {
      ifNotDirectlyLogin: "직접 로그인 하신 것이 아니라면",
      changePassword: "보안을 위해 즉시 계정 비밀번호를 변경하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    verifyAddressButton: "IP 주소 인증하기",
  },
  custodyRequestWithdrawEmail: {
    title: "커스터디 출금이 요청되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요. 
고객사로부터 커스터디 출금이 요청되었습니다.`,
    withdrawTitle: "$t(withdrawInfo)",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 요청 목록 확인하기",
  },
  custodyRequestWithdrawApproveEmail: {
    title: "커스터디 출금이 승인되었습니다. (1/2)",
    description1: ``,
    description2: `님, 안녕하세요. 
커스터디 출금이 승인 되었습니다.`,
    withdrawTitle: "$t(withdrawInfo)",
    approveName: "승인",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 요청 목록 확인하기",
  },
  custodyRequestWithdrawQRCodeEmail: {
    title: "커스터디 출금 요청 QR 코드가 발급되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요. 
커스터디 출금 요청 QR 코드가 발급되었습니다.`,
    withdrawTitle: "$t(withdrawInfo)",
    qrCodeName: "QR 코드 생성",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 요청 목록 확인하기",
  },
  custodyRequestWithdrawApproveCompleteEmail: {
    title: "커스터디 출금 승인이 완료 되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요. 
커스터디 최종 출금 승인이 완료되었습니다.`,
    withdrawTitle: "$t(withdrawInfo)",
    approveName: "최종 승인",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 내역 확인하기",
  },
  custodyRegisterWalletEmail: {
    title: "커스터디 지갑 생성이 요청되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요. 
고객사로부터 커스터디 지갑 생성이 요청되었습니다.`,
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 요청 목록 확인하기",
  },
  custodyRegisterWalletApproveEmail: {
    title: "커스터디 지갑 생성이 승인되었습니다. (2/2)",
    description1: ``,
    description2: `님, 안녕하세요. 
커스터디 지갑 생성이 승인되었습니다.`,
    approveName: "승인",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 요청 목록 확인하기",
  },
  custodyRegisterWalletQRCodeEmail: {
    title: "커스터디 지갑 생성 요청 QR 코드가 발급되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요. 
커스터디 지갑 생성 요청 QR 코드가 발급되었습니다.`,
    qrCodeName: "QR 코드 생성",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 요청 목록 확인하기",
  },
  custodyRegisterWalletApproveCompleteEmail: {
    title: "커스터디 지갑 생성 최종 승인이 완료 되었습니다.",
    description1: ``,
    description2: `님, 안녕하세요. 
커스터디 지갑 생성 최종 승인이 완료되었습니다.`,
    approveName: "최종 승인",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 내역 확인하기",
  },
  walletPolicyCard: {
    title: "설정하기",
  },
  allowedAddressActiveCompleteDialog: {
    title: "$t(completeActiveWhitelist)",
    description: `지금부터 등록된 주소로만 출금이 가능합니다.
출금 제한을 해제하려면 화이트리스팅을 비활성화하세요.`,
  },
  allowedAddressActiveConfirmDialog: {
    title: "$t(isActiveWhitelisting)",
    description1: `활성화하면 `,
    description2: `허용된 주소로만 출금`,
    description3: `할 수 있습니다.
정말로 활성화하시겠습니까?`,
    closeButton: "$t(close)",
    submitButton: "$t(activate)",
  },
  allowedAddressInactiveCompleteDialog: {
    title: "$t(completeInactiveWhitelist)",
    description: `지금부터 모든 주소로 출금이 가능합니다.`,
  },
  allowedAddressInactiveConfirmDialog: {
    title: "$t(isInactiveWhitelisting)",
    description1: `비활성화하면 앞으로 `,
    description2: `모든 주소로 출금이 가능`,
    description3: `합니다.
정말로 비활성화하시겠습니까?`,
    closeButton: "$t(close)",
    submitButton: "$t(inactive)",
  },
  deleteAllowedAddressCompleteDialog: {
    title: "주소 삭제 완료",
    description: `지금부터 해당 주소로 출금이 불가능합니다.`,
  },
  deleteAllowedAddressConfirmDialog: {
    title: "주소를 삭제하시겠습니까?",
    description: `을 삭제합니다.`,
    closeButton: "$t(close)",
    submitButton: "$t(delete)",
  },
  registerAllowedAddressCompleteDialog: {
    title: "주소 등록 완료",
    description: `지금부터 해당 주소로 출금이 가능합니다.`,
  },
  registerAllowedAddressDialog: {
    title: "$t(addAddress)",
    directInputLabel: "$t(directInput)",
    selectedWalletLabel: "지갑 선택",
    nameLabel: "$t(name)",
    coinAndTokenLabel: "$t(coinToken)",
    emptyCoinLabel: "$t(emptyCoin)",
    addressLabel: "$t(address)",
    walletLabel: "$t(wallet)",
    closeButton: "$t(close)",
    addButton: "추가하기",
  },
  allowedIpActiveCompleteDialog: {
    title: "$t(completeActiveWhitelist)",
    description: "$t(availableCurrentRegisteredIp)",
  },
  allowedIpInactiveCompleteDialog: {
    title: "$t(completeInactiveWhitelist)",
    description: `지금부터 모든 IP 주소에서 API 호출이 가능합니다.`,
  },
  allowedIpInactiveConfirmDialog: {
    title: "$t(isInactiveWhitelisting)",
    description1: `비활성화하면 `,
    description2: `모든 IP 주소에서 API 호출`,
    description3: `을 할 수 있습니다.
정말로 비활성화하시겠습니까?`,
    closeButton: "$t(close)",
    submitButton: "$t(inactive)",
  },
  deleteAllowedIpCompleteDialog: {
    title: "IP 주소 삭제 완료",
    description: `지금부터 해당 IP 주소에서는 API 호출이 차단됩니다.`,
  },
  deleteAllowedIpConfirmDialog: {
    title: "IP 주소를 삭제하시겠습니까?",
    description: `을 삭제합니다.`,
    closeButton: "$t(close)",
    submitButton: "$t(delete)",
  },
  registerAllowedIpCompleteDialog: {
    title: "IP 주소 등록 완료",
    description: `지금부터 해당 IP 주소에서 API 호출이 가능합니다.`,
  },
  registerAllowedIpDialog: {
    title: "$t(addIpAddress)",
    description: `API 호출을 허용할 IP 주소를 등록할 수 있습니다.`,
    nameLabel: "$t(name)",
    ipAddressLabel: "$t(ipAddress1)",
    ipAddressPlaceholder: "IPv4 주소 (0.0.0.0~255.255.255.255)",
    closeButton: "$t(close)",
    submitButton: "등록하기",
  },
  registerAndAllowedIpActiveCompleteDialog: {
    title: "화이트리스팅 활성화 및 주소 등록 완료",
    description: "$t(availableCurrentRegisteredIp)",
  },
  registerAndAllowedIpActiveConfirmDialog: {
    title: "$t(isActiveWhitelisting)",
    description1: `활성화하면 등록되지 않은 IP 주소에서 API 호출이 차단됩니다.
이미 지갑과 연동된 응용 프로그램이 API를 호출하고 있는 경우,
IP 주소 등록 후 활성화를 해주세요. 그렇지 않으면 `,
    description2: `응용 프로그램의 동
`,
    description3: `작이 멈출 수`,
    description4: `있습니다.
          
활성화하시겠습니까?`,
    closeButton: "$t(close)",
    submit1Button: "IP 주소 등록 후 활성화",
    submit2Button: "$t(activate)",
  },
  createAccessTokenCompleteDialog: {
    title: "Access Token 생성 완료",
    description: `새로운 Access Token이 생성되었습니다.`,
    submitButton: "$t(confirm)",
  },
  createAccessTokenDialog: {
    title: "Access Token 생성하기",
    description: `새롭게 생성할 Access Token의 유효 기간을 설정하세요.`,
    expiresInLabel: "유효 기간 (초)",
  },
  createApiSecretCompleteDialog: {
    title: "API Secret 재발급 완료",
    description: `새로운 API Secret이 생성되었습니다.`,
    submitButton: "$t(confirm)",
  },
  refreshAccessTokenDialog: {
    title: "Access Token 재발급하기",
    description: `기존에 생성하였던 Access Token은 더이상 사용할 수 없습니다.
재발급하시겠습니까?`,
    submitButton: "$t(reissue)",
  },
  refreshApiSecretDialog: {
    title: "API Secret 재발급하기",
    description: `기존에 생성하였던 API Secret은 더이상 사용할 수 없습니다.
재발급하시겠습니까?`,
    submitButton: "$t(reissue)",
  },
  authorityInfoByRoleDialog: {
    title: "권한 안내",
    description: `역할별로 사용 가능한 권한은 다음과 같습니다.`,
    submitButton: "$t(confirm)",
  },
  authorityIpAddressesDialog: {
    ipAddressLabel: "$t(ipAddress1)",
    createdAtLabel: "등록 시간 $t(utc)",
    submitButton: "$t(confirm)",
  },
  createAuthorityDialog: {
    title: "팀원을 추가하고 싶으신가요?",
    description: "$t(contactEmail)",
    submitButton: "$t(confirm)",
  },
  deleteAuthorityCompleteDialog: {
    title: "권한 삭제 완료",
    description: `성공적으로 권한이 삭제되었습니다.`,
    submitButton: "$t(confirm)",
  },
  deleteAuthorityDialog: {
    title: "권한 삭제하기",
    description: `의 권한을 삭제합니다.`,
    closeButton: "$t(close)",
    submitButton: "$t(delete)",
  },
  updateAuthorityCompleteDialog: {
    title: "권한 변경 완료",
    description: `성공적으로 권한이 변경 완료되었습니다.`,
    submitButton: "$t(confirm)",
  },
  updateAuthorityDialog: {
    title: "권한 변경하기",
    description1: ``,
    description2: `의 권한을
다음과 같이 변경합니다.`,
    authorityLabel: "$t(authority)",
    closeButton: "$t(close)",
    submitButton: "$t(change)",
  },
  registerDepositAddressCompleteDialog: {
    title: "입금 주소 생성 완료",
    description: ` 가 생성되었습니다.`,
  },
  registerDepositAddressDialog: {
    title: "새로운 입금 주소 만들기",
    description1: ``,
    description2: ` 지갑의 입금 주소를 생성합니다.`,
    nameLabel: "$t(name)",
    closeButton: "$t(close)",
    submitButton: "$t(create)",
  },
  exportGasFeeTransferDialog: {
    title: "수수료 지갑 사용 내역 내보내기",
    description: `월별 내역을 CSV 파일로 다운로드할 수 있습니다.`,
    warningAlert1: "전송중 혹은 채굴 대기 상태일 경우 ",
    warningAlert2: "미확정된 수량으로 표기",
    warningAlert3: "됩니다.",
    closeButton: "$t(close)",
    submitButton: "CSV 다운로드",
  },
  smartContractDetailDialog: {
    retryGasPriceDescription: "$t(retryGasFeeMessage)",
    retryGasPriceButton: "$t(retryRealGasFee)",
    gasPriceLabel: "$t(gasPrice)",
    title: "스마트컨트랙트 호출 상세 내역",
    createdAtLabel: "$t(time)",
    smartContractAddressLabel: "스마트컨트랙트 주소",
    statusLabel: "$t(status)",
    gasFeeLabel: "$t(fee)",
    transactionHashLabel: "$t(transactionHash)",
    closeButton: "$t(close)",
  },
  smartContractTransactionConfirmDialog: {
    title: "$t(retryRealGasFeeMessage)",
    description: "$t(newTransactionMessage)",
    currentGasPriceLabel: "$t(realGasFee)",
    currentGasPriceTooltipMessage: "$t(realNetworkBasedGasFee)",
    warningTitle: "$t(doNotBeSurprised)",
    warningAlert1:
      "가스비가 더 낮게 설정된 기존 트랜잭션이 먼저 채굴될 수도 있습니다.",
    warningAlert2: `동일한 트랜잭션이 여러 개 발생되지만, 그 중에 하나가 채굴되면 나머지
트랜잭션은 취소됩니다.`,
    warningAlert3: "거래가 중복으로 확정되는 일은 절대 발생하지 않습니다.",
    closeButton: "재전송 취소",
    submitButton: "재전송하기",
  },
  transferRetryTransactionConfirmDialog: {
    title: "$t(retryRealGasFeeMessage)",
    description: "$t(newTransactionMessage)",
    currentGasPriceLabel: "$t(realGasFee)",
    currentGasPriceTooltipMessage: "$t(realNetworkBasedGasFee)",
    warningTitle: "$t(doNotBeSurprised)",
    warningAlert1:
      "가스비가 더 낮게 설정된 기존 트랜잭션이 먼저 채굴될 수도 있습니다.",
    warningAlert2: `동일한 트랜잭션이 여러 개 발생되지만, 그 중에 하나가 채굴되면 나머지
트랜잭션은 취소됩니다.`,
    warningAlert3: "거래가 중복으로 확정되는 일은 절대 발생하지 않습니다.",
    closeButton: "재전송 취소",
    submitButton: "재전송하기",
  },
  transferSearchOptionDialog: {
    btcName: {
      description: `입금된 지갑, 입금 주소의 이름, 입금된 주소
혹은 출금한 주소로 검색할 수 있습니다.`,
    },
    btcTransactionHash: {
      description: `트랜잭션 해시로 검색할 수 있습니다.`,
    },
    ethKlayName: {
      description: `입금을 보낸 주소 또는 출금한 주소로 검색할 수 있습니다.`,
    },
    ethKlayTransactionHash: {
      description: `트랜잭션 해시로 검색할 수 있습니다.`,
    },
    eosName: {
      description: "입금을 보낸 주소 또는 출금한 주소로 검색할 수 있습니다.",
    },
    eosTransactionHash: {
      description: "트랜잭션 해시로 검색할 수 있습니다.",
    },
    title: "검색 옵션",
    description: `다음과 같은 검색 옵션을 사용할 수 있습니다.
현재 부분 검색은 지원하지 않습니다.`,
    contentLabel: "$t(content)",
    transactionHashLabel: "$t(transactionHash)",
    submitButton: "$t(confirm)",
  },
  transferStatusDialog: {
    PENDINGAPPROVAL: {
      description: `출금 한도를 초과하여 관리자에게 출금 승인을 요청한 상태`,
    },
    REJECTED: {
      description: `관리자가 출금 요청을 거절하여 출금이 취소된 상태`,
    },
    REQUESTED: {
      description: `트랜잭션이 발생되기 이전인 상태`,
    },
    FAILED: {
      description: `트랜잭션 발생이 실패한 상태`,
    },
    PENDING: {
      description: `트랜잭션이 블록에 담기기를 기다리는 상태`,
    },
    MINED: {
      description: `트랜잭션이 블록에 담긴 상태`,
    },
    CONFIRMED: {
      description1: `트랜잭션이 블록에 담긴 이후 `,
      description2: `컨펌이 지난 상태`,
    },
    REVERTED: {
      description: `트랜잭션이 실패한 상태`,
    },
    INTERNALREVERTED: {
      description: `트랜잭션은 성공했으나 일부 이벤트가 실패한 상태`,
    },
    REPLACED: {
      description: `트랜잭션이 다른 트랜잭션으로 교체된 상태`,
    },
    title: "트랜잭션 상태",
    submitButton: "$t(confirm)",
  },
  transferWithdrawDetailDialog: {
    titleByTransferType: {
      DEPOSIT: {
        title: "입금 상세 내역",
      },
      WITHDRAWAL: {
        title: "출금 상세 내역",
      },
    },
    retryGasPriceDescription: "$t(retryGasFeeMessage)",
    retryGasPriceButton: "$t(retryRealGasFee)",
    gasPriceLabel: "$t(gasPrice)",
    createdAtLabel: "$t(time)",
    toAddressLabel: "보낸 주소",
    fromAddressLabel: "$t(receivedAddress)",
    amountLabel: "$t(amount)",
    statusLabel: "$t(status)",
    gasFeeLabel: "$t(fee)",
    transactionHashLabel: "$t(transactionHash)",
    closeButton: "$t(close)",
  },
  registerUserWalletCompleteDialog: {
    title: "사용자 지갑 생성 요청 완료",
    description: ` 가 생성되었습니다.`,
  },
  registerUserWalletDialog: {
    title: "새로운 사용자 지갑 만들기",
    description1: ``,
    description2: ` 마스터 지갑 하위에 새로운 사용자 지갑을 생성합니다.`,
    nameLabel: "$t(name)",
    closeButton: "$t(close)",
    submitButton: "$t(create)",
  },
  requestWithdrawCompleteDialog: {
    title: "관리자에게 출금 요청 완료",
    description1: `한도를 초과한 출금`,
    description2: `입니다.
관리자가 승인하면 출금이 진행됩니다.`,
    closeButton: "$t(close)",
    submitButton: "출금 상태 확인하기",
  },
  withdrawCompleteDialog: {
    description1: ``,
    description2: `의 출금 신청이 완료 되었습니다.
트랜잭션이 확정되려면 일정 시간이 소요될 수 있습니다.`,
    closeButton: "$t(close)",
  },
  withdrawOTPDialog: {
    title: "다음과 같이 출금합니다.",
    description1: `마지막으로 `,
    description2: `주소와 보낼 코인을 한번 더 확인`,
    description3: `하신 후,
Google Authenticator에 보이는 6자리 인증 코드를 입력하세요.`,
    fromAddressLabel: "$t(receivedAddress)",
    toCoinLabel: "$t(sendingCoin)",
    otpCodeLabel: "OTP 인증 코드",
    otpCodePlaceholder: "$t(sixDigits)",
    closeButton: "$t(close)",
    submitButton: "$t(withdraw)",
  },
  withdrawPassphraseDialog: {
    normal: {
      title: "지갑 비밀번호 입력하기",
      description: "의 비밀번호를 입력하세요.",
    },
    withdraw: {
      title: "출금 인증하기",
      description1: `출금 인증을 위해 `,
      description2: "",
      description3: "의 비밀번호를 입력하세요.",
    },
    passwordLabel: " 비밀번호",
    closeButton: "$t(close)",
    submitButton: "$t(authButton)",
  },
  withdrawRejectCompleteDialog: {
    title: "출금이 거절되었습니다.",
    description: "요청된 출금이 취소 처리되었습니다.",
    submitButton: "$t(confirm)",
  },
  withdrawRejectDialog: {
    title: "출금을 거절하시겠습니까?",
    description: "요청된 출금이 취소 처리됩니다.",
    cancelButton: "$t(cancel)",
    submitButton: "출금 거절",
  },
  withdrawPolicyCompleteDialog: {
    title: "출금 한도 설정 완료",
    description: "의 출금 한도가 설정되었습니다.",
    submitButton: "$t(confirm)",
  },
  withdrawPolicyInfoDialog: {
    masterWalletPolicy: {
      infoTitle: "$t(masterWallet)",
      infoList1: "$t(warningWIthdrawalLimit2)",
      infoList2: "$t(warningWithdrawalLimit1)",
    },
    userWalletPolicy: {
      infoTitle: "$t(userWallet)",
      infoList1: "$t(warningWIthdrawalLimit2)",
      infoList2: "$t(warningWithdrawalLimit1)",
      infoList3: "마스터 지갑 하위에 있는 사용자 지갑에 일괄 적용됩니다.",
      infoList4: "개별 사용자 지갑에 해당 한도가 각각 적용됩니다.",
    },
    bitCoin: {
      infoTitle: "$t(wallet)",
      infoList1: "$t(warningWIthdrawalLimit2)",
      infoList2: "$t(warningWithdrawalLimit1)",
    },
    title: "$t(withdrawalLimit)",
    walletType: {
      masterWallet: "$t(masterWallet)",
      userWallet: "$t(userWallet)",
    },
    submitButton: "$t(confirm)",
  },
  withdrawPolicySettingDialog: {
    update: {
      title: " 출금 한도 변경",
      description1: "의 ",
      description2: " 출금 한도를 변경합니다.",
      submitButton: "$t(change)",
    },
    create: {
      title: " 출금 한도 설정",
      description1: "의 ",
      description2: " 출금 한도를 설정합니다.",
      submitButton: "$t(next)",
    },
    oneDayAmountLabel: "$t(oneDaysWithdrawalLimit)",
    oneTimeAmountLabel: "$t(oneTimeWithdrawalLimit)",
    userWallet: {
      infoList1:
        "'출금 가능' 권한의 팀원은 출금 한도 이내에서만 출금 가능합니다.",
      infoList2: "$t(warningWithdrawalLimit1)",
      infoList3: "마스터 지갑 하위에 있는 사용자 지갑에 일괄 적용됩니다.",
      infoList4: "개별 사용자 지갑에 해당 한도가 각각 적용됩니다.",
    },
    masterWallet: {
      infoList1:
        "'출금 가능' 권한의 팀원은 출금 한도 이내에서만 출금 가능합니다.",
      infoList2: "$t(warningWithdrawalLimit1)",
    },
    closeButton: "$t(close)",
  },
  accessTokenKeepDialog: {
    title: ` 후에 로그아웃됩니다.`,
    description: `접속한 지 30분이 지나 보안을 위해 자동으로 로그아웃됩니다.
로그인 상태를 유지하려면 OTP 인증을 진행해 주세요.`,
    logoutButton: "로그아웃",
    submitButton: "로그인 유지",
  },
  changeUserNameCompleteDialog: {
    title: `사용자 이름 변경 완료`,
    description: `새로운 사용자 이름이 설정 되었습니다.`,
    submitButton: "$t(confirm)",
  },
  changeUserPasswordCompleteDialog: {
    title: `계정 비밀번호 변경 완료`,
    description: `새로운 계정 비밀번호가 설정 되었습니다.`,
    submitButton: "$t(confirm)",
  },
  findOTPDialog: {
    title: "$t(forgottenOTP)",
    description: "$t(contactEmail)",
    submitButton: "$t(confirm)",
  },
  firstDepositConfirmDialog: {
    title: `혹시 가스비를 입금하려 하시나요?`,
    description1: `모든 지갑은 가스비 지갑에 예치된 가스비를 이용합니다.
가스비는 `,
    description2: `가스비 지갑에 입금`,
    description3: `하셔야 합니다.`,
    closeButton: "다시 보지 않기",
    gasFeeButton: "가스비 지갑으로 이동",
    submitButton: "확인했습니다",
  },
  gasFeeDepositDialog: {
    common: {
      title: "수수료를 충전해 주세요.",
      description:
        "지갑을 생성하려면 수수료를 최소 예치 금액 이상 충전해야 합니다.",
      statusDone: "충전 완료",
    },
    makeWallet: {
      closeButton: "$t(close)",
      submitButton: "$t(next)",
    },
    deposit: {
      title: "수수료 충전하기",
      description: `을
입금할 수 있습니다.`,
      statusDone: "입금 되었습니다",
      closeButton: "$t(close)",
    },
    retryWallet: {
      closeButton: "$t(close)",
      submitButton: "지갑 생성 재시도하기",
    },
    retryGasFeeWallet: {
      closeButton: "취소하기",
      submitButton: "$t(createMasterWallet1)",
    },
    minimalDeposit: "$t(minimalExpectedAmount)",
    minimalDepositTooltipMessage: "$t(minimalExpectedAmountMessage)",
    myGasFeeLabel: "$t(myGasFee)",
    myGasFeeWarningList: "최소 예치 금액 이상 입금해 주세요.",
    depositAddressLabel: "$t(depositAddress)",
    depositType: {
      address: "$t(address)",
      qrCode: "$t(qrCode)",
    },
    gasFeeAddressCopy: "$t(gasFeeWalletAddressCopy)",
    depositCaption: "마스터 지갑 주소와는 다른 별도의 지갑 주소입니다.",
    depositWarning: {
      warningTitle: "$t(depositWarning)",
    },
  },
  registerGasFeeWalletFailedDialog: {
    title: `지갑 생성 실패`,
    description: `가스비가 부족해 지갑 생성에 실패했습니다.
가스비를 충전해 주세요.`,
    closeButton: "지갑 생성 취소하기",
    submitButton: "$t(chargeGasFee)",
  },
  registerWalletBlockchainDialog: {
    title: `새로운 지갑 만들기`,
    platformLabel: `메인넷`,
    closeButton: "$t(close)",
    submitButton: "$t(next)",
  },
  registerWalletCoinDialog: {
    title: `코인/토큰 선택`,
    description: `출금 한도를 적용할 코인을 선택하세요.`,
    coinAndTokenLabel: "$t(coinToken)",
    emptyCoinAndTokenLabel: "$t(emptyCoin)",
    closeButton: "$t(close)",
    submitButton: "$t(next)",
  },
  settingCompleteDialog: {
    submitButton: "$t(confirm)",
  },
  templateCompleteDialog: {
    submitButton: "$t(confirm)",
  },
  userWalletPickerDialog: {
    closeButton: "$t(close)",
    submitButton: "선택하기",
  },
  walletSafePrintDialog: {
    title: `인쇄물을 안전하게 보관하셨나요?`,
    description1: `복구 키트를 분실하면 `,
    description2: "$t(cannotRecoverWallet)",
    description3: `하며,
헤네시스에서는 분실에 대한 책임을 지지 않습니다.`,
    submitButton: `네, 확인했습니다.`,
  },
  materialDrawer: {
    requestWithdrawName: "$t(requestWithdraw)",
    apiSyncName: "$t(apiSync)",
    teamName: "$t(teamManager)",
    walletName: "$t(wallet)",
    emptyWalletName: "지갑이 없습니다.",
    gasFeeName: "수수료 지갑",
    gasFeePaymentName: "$t(gasFeePayment)",
  },
  requestWithdrawItem: {
    requestNameCaption1: "",
    requestNameCaption2: "의 요청",
    approveComplete: "승인됨",
    approveButton: "승인",
    rejectComplete: "거절됨",
    rejectButton: "거절",
  },
  languageSelectbox: {
    ko: "한국어",
    en: "English",
  },
  myMenu: {
    authority: "권한 설정",
    account: "계정 설정",
    language: "언어 변경",
    logout: "로그아웃",
  },
  requestWithdrawList: {
    column: {
      requesterAndDate: "$t(requester) / $t(time) $t(utc)",
      from: "$t(wallet)",
      to: "$t(receivedAddress)",
      amount: "$t(amount)",
      status: "$t(status)",
    },
  },
  createRequestOrganizationCompleteDialog: {
    title: "등록 요청 완료",
    description: "성공적으로 등록 요청 되었습니다.",
    submitButton: "$t(confirm)",
  },
  updateRequestOrganizationCompleteDialog: {
    title: "수정 요청 완료",
    description: "성공적으로 수정 요청 되었습니다.",
    submitButton: "$t(confirm)",
  },
  deleteRequestOrganizationCompleteDialog: {
    title: "삭제 요청 완료",
    description: "성공적으로 삭제 요청 되었습니다.",
    submitButton: "$t(confirm)",
  },
};

export default ko;
