import { RequestStatusType } from "src/components/Tab/Custody/CustodyRequestTab";
import {
  CustodyRequestStatus,
  AuthorityName,
  CustodyRequestType,
  CustodyRequestV2Status,
  LogDto,
  SigningLogType,
  SigningV2Dto,
} from "src/__generate__/api";
import { CustodyTransferType } from "src/apis/custody/requests";

export const getSubmitNameByStatus = (status: CustodyRequestStatus) => {
  const submitNameByStatus: Record<CustodyRequestStatus, string> = {
    [CustodyRequestStatus.Requested]: "서명하기",
    [CustodyRequestStatus.PendingSigning]: "서명 인증하기",
    [CustodyRequestStatus.PendingApproval]: "승인하기",
    [CustodyRequestStatus.PendingFinalApproval]: "최종 승인하기",
    [CustodyRequestStatus.Declined]: "DECLINED",
    [CustodyRequestStatus.Canceled]: "CANCELED",
    [CustodyRequestStatus.Processed]: "PROCESSED",
  };
  return submitNameByStatus[status];
};

export const getSubmitNameByStatusV2 = (status: CustodyRequestV2Status) => {
  const submitNameByStatus: Record<CustodyRequestV2Status, string> = {
    [CustodyRequestV2Status.Requested]: "서명하기",
    [CustodyRequestV2Status.PendingSigning]: "서명 인증하기",
    [CustodyRequestV2Status.PendingApproval]: "승인하기",
    [CustodyRequestV2Status.PendingFinalApproval]: "최종 승인하기",
    [CustodyRequestV2Status.Declined]: "DECLINED",
    [CustodyRequestV2Status.Canceled]: "CANCELED",
    [CustodyRequestV2Status.Processed]: "PROCESSED",
  };
  return submitNameByStatus[status];
};

export const transformCustodyRequestStatusType = (
  requestStatusType: RequestStatusType,
) => {
  const map: Record<RequestStatusType, CustodyRequestStatus | undefined> = {
    all: undefined,
    request: CustodyRequestStatus.Requested,
    signing: CustodyRequestStatus.PendingSigning,
    pendingApproval: CustodyRequestStatus.PendingApproval,
    pendingFinalApproval: CustodyRequestStatus.PendingFinalApproval,
  };
  return map[requestStatusType];
};

export const transformCustodyRequestV2StatusType = (
  requestStatusType: RequestStatusType,
) => {
  const map: Record<RequestStatusType, CustodyRequestV2Status | undefined> = {
    all: undefined,
    request: CustodyRequestV2Status.Requested,
    signing: CustodyRequestV2Status.PendingSigning,
    pendingApproval: CustodyRequestV2Status.PendingApproval,
    pendingFinalApproval: CustodyRequestV2Status.PendingFinalApproval,
  };
  return map[requestStatusType];
};

export const transformCustodyRequestStatusByAuthorityName = (
  authorityName: AuthorityName,
) => {
  const map: Partial<Record<AuthorityName, CustodyRequestStatus[]>> = {
    [AuthorityName.CustodyAdmin]: [CustodyRequestStatus.PendingFinalApproval],
    [AuthorityName.CustodyManager]: [CustodyRequestStatus.PendingApproval],
    [AuthorityName.CustodySigner]: [
      CustodyRequestStatus.Requested,
      CustodyRequestStatus.PendingSigning,
    ],
  };
  return map[authorityName];
};

export const transformCustodyRequestV2StatusByAuthorityName = (
  authorityName: AuthorityName,
) => {
  const map: Partial<Record<AuthorityName, CustodyRequestV2Status[]>> = {
    [AuthorityName.CustodyAdmin]: [CustodyRequestV2Status.PendingFinalApproval],
    [AuthorityName.CustodyManager]: [CustodyRequestV2Status.PendingApproval],
    [AuthorityName.CustodySigner]: [
      CustodyRequestV2Status.Requested,
      CustodyRequestV2Status.PendingSigning,
    ],
  };
  return map[authorityName];
};

export const transformCustodyRequestTypeToTransferType = (
  type: CustodyRequestType,
) => {
  const map: Partial<Record<CustodyRequestType, CustodyTransferType>> = {
    [CustodyRequestType.Wallet]: "wallet",
    [CustodyRequestType.Withdrawal]: "withdrawal",
  };
  return map[type];
};

export const verifyCustodyRequestV2SignType = (commitLogs?: Array<LogDto>) => {
  return (commitLogs ?? []).some((x) => x.type === SigningLogType.Confirmation)
    ? "CONFIRM"
    : "COMMIT";
};

export const getSigningStepValueBySigningLogs = <T>({
  commitLogs,
  confirmLogs,
  beforeQrCreation,
  afterQrCreation,
}: {
  commitLogs?: Array<LogDto>;
  confirmLogs?: Array<LogDto>;
  beforeQrCreation: T;
  afterQrCreation: T;
}): T => {
  const signType = verifyCustodyRequestV2SignType(commitLogs);
  const logs = signType === "CONFIRM" ? confirmLogs : commitLogs;
  return (logs ?? []).some((x) => x.type === SigningLogType.QrCreation)
    ? afterQrCreation
    : beforeQrCreation;
};

export const getSubmitNameBySigningLogs = (
  commitLogs?: Array<LogDto>,
  confirmLogs?: Array<LogDto>,
) => {
  return getSigningStepValueBySigningLogs({
    commitLogs,
    confirmLogs,
    beforeQrCreation: "서명하기",
    afterQrCreation: "서명 인증하기",
  });
};

export const verifyCustodyRequestV2CurrentSigner = ({
  id,
  commitSigning,
  confirmSigning,
}: {
  id: string;
  commitSigning?: SigningV2Dto;
  confirmSigning?: SigningV2Dto;
}) => {
  const signType = verifyCustodyRequestV2SignType(commitSigning?.logs);

  if (signType === "COMMIT") {
    return id === commitSigning?.account?.id;
  } else {
    // CONFIRM
    return (
      id === confirmSigning?.account?.id || id !== commitSigning?.account?.id
    );
  }
};
