import { ErrorCode } from "src/libs/error";
import { makeQueryParams } from "src/utils/uri";
import _ from "lodash";
import { Params as ErrorParams } from "src/hocs/withErrorSnackbar";
import { Params as MessageParams } from "src/hocs/withMessageSnackbar";
import { RequestStatusType } from "src/components/Tab/Custody/CustodyRequestTab";
import { UpgradeStatusType } from "src/components/Tab/Custody/CustodyUpgradeInfoTab";

type Params = ErrorParams &
  MessageParams & {
    redirect?: string;
  };

type CustodyParams = {
  tab?: RequestStatusType;
};
type WithUpgradeOrgParams = {
  tab?: UpgradeStatusType;
};
type transferParams = {
  orgId: string;
  walletId: string;
  orgName: string;
  walletName: string;
};

const getAccountLoginPath = (params: Params) => {
  if (params.err === ErrorCode.ACCESS_GUEST) {
    return `/account/login${makeQueryParams(_.omit(params, ["err"]))}`;
  }
  return `/account/login${makeQueryParams(params)}`;
};

const getDashboardCustodyRequestWithdrawUrlPath = () =>
  "/dashboard/custody/request/withdraw";

const getDashboardCustodyRequestWithdrawV2UrlPath = () =>
  "/dashboard/custody/v2/request/withdraw";

const getDashboardCustodyRequestWithdrawPath = (params: CustodyParams) => {
  return `/dashboard/custody/request/withdraw${makeQueryParams(params)}`;
};
const getDashboardCustodyRequestWithdrawV2Path = (params: CustodyParams) => {
  return `/dashboard/custody/v2/request/withdraw${makeQueryParams(params)}`;
};
const getDashboardCustodyTransferPath = (params?: transferParams) =>
  !params
    ? "/dashboard/custody/transfer"
    : `/dashboard/custody/transfer${makeQueryParams(params)}`;

const getDashboardCustodyTransferV2Path = (params?: transferParams) =>
  !params
    ? "/dashboard/custody/v2/transfer"
    : `/dashboard/custody/v2/transfer${makeQueryParams(params)}`;

const getDashboardCustodyCoinsPath = () => "/dashboard/custody/coins";
const getDashboardCustodyCoinsV2Path = () => "/dashboard/custody/v2/coins";

const getDashboardCustodyManagementOrganizationCreateUrlPath = () =>
  "/dashboard/custody/management-organization/create";

const getDashboardCustodyManagementOrganizationUrlPath = () =>
  "/dashboard/custody/management-organization";

const getDashboardCustodyOrganizationUpgradeInfoPath = (
  params?: WithUpgradeOrgParams,
) =>
  `/dashboard/custody/organization-upgrade-information${makeQueryParams(
    params,
  )}`;
const getDashboardCustodyManagementOrganizationPath = (params: {
  tab?: string;
}) => {
  return `/dashboard/custody/management-organization${makeQueryParams(params)}`;
};

const getDashboardCustodyOrganizationWalletsPath = () =>
  "/dashboard/custody/organization-wallets";

const getDashboardCustodyOrganizationWalletsV2Path = () =>
  "/dashboard/custody/v2/organization-wallets";

const getDashboardCustodyManagementOrganizationDetailUrlPath = () =>
  `/dashboard/custody/management-organization/detail`;

const getDashboardCustodyManagementOrganizationDetailPath = (params: {
  tab?: string;
  orgId?: string;
}) => {
  return `/dashboard/custody/management-organization/detail${makeQueryParams(
    params,
  )}`;
};
const getDashboardCustodyApproveHistoryUrlPath = () =>
  "/dashboard/custody/approve-history";

const getDashboardCustodyApproveHistoryV2UrlPath = () =>
  "/dashboard/custody/v2/approve-history";

const getDashboardCustodyApproveHistoryPath = (params: { tab?: string }) => {
  return `/dashboard/custody/approve-history${makeQueryParams(params)}`;
};
const getDashboardCustodyApproveHistoryV2Path = (params: { tab?: string }) => {
  return `/dashboard/custody/v2/approve-history${makeQueryParams(params)}`;
};
const getDashboardCustodyBalanceReportPath = () =>
  "/dashboard/custody/balance-report";

const getDashboardCustodyRequestRegisterWalletUrlPath = () =>
  "/dashboard/custody/request/register-wallet";

const getDashboardCustodyRequestRegisterWalletV2UrlPath = () =>
  "/dashboard/custody/v2/request/register-wallet";

const getDashboardCustodyRequestRegisterWalletPath = (
  params: CustodyParams,
) => {
  return `/dashboard/custody/request/register-wallet${makeQueryParams(params)}`;
};

const getDashboardCustodyRequestRegisterWalletV2Path = (
  params: CustodyParams,
) => {
  return `/dashboard/custody/v2/request/register-wallet${makeQueryParams(
    params,
  )}`;
};

export {
  getAccountLoginPath,
  getDashboardCustodyRequestWithdrawUrlPath,
  getDashboardCustodyRequestWithdrawV2UrlPath,
  getDashboardCustodyRequestWithdrawPath,
  getDashboardCustodyRequestWithdrawV2Path,
  getDashboardCustodyOrganizationUpgradeInfoPath,
  getDashboardCustodyTransferPath,
  getDashboardCustodyTransferV2Path,
  getDashboardCustodyManagementOrganizationUrlPath,
  getDashboardCustodyManagementOrganizationPath,
  getDashboardCustodyCoinsPath,
  getDashboardCustodyCoinsV2Path,
  getDashboardCustodyOrganizationWalletsPath,
  getDashboardCustodyOrganizationWalletsV2Path,
  getDashboardCustodyManagementOrganizationDetailUrlPath,
  getDashboardCustodyManagementOrganizationDetailPath,
  getDashboardCustodyApproveHistoryUrlPath,
  getDashboardCustodyApproveHistoryV2UrlPath,
  getDashboardCustodyApproveHistoryPath,
  getDashboardCustodyApproveHistoryV2Path,
  getDashboardCustodyBalanceReportPath,
  getDashboardCustodyManagementOrganizationCreateUrlPath,
  getDashboardCustodyRequestRegisterWalletUrlPath,
  getDashboardCustodyRequestRegisterWalletV2UrlPath,
  getDashboardCustodyRequestRegisterWalletPath,
  getDashboardCustodyRequestRegisterWalletV2Path,
};
