import { Blockchain, BlockchainControllerApiFactory } from "src/__generate__";
import { requestAPI } from "./requestApi";

const blockchainControllerApiFactory = () =>
  BlockchainControllerApiFactory(undefined, "", requestAPI());

export const getSupportedBlockchains = async () => {
  const response =
    await blockchainControllerApiFactory().getSupportedBlockchains();
  return response.data;
};

export const checkV2Chain = async (blockchain: Blockchain) => {
  const response: RetrieveAsyncFunc<typeof getSupportedBlockchains> =
    await getSupportedBlockchains();

  return Boolean(
    response.find(
      (item) => item.blockchain === blockchain && item.version === 2,
    ),
  );
};

export const checkTestnet = async (blockchain: Blockchain) => {
  const response: RetrieveAsyncFunc<typeof getSupportedBlockchains> =
    await getSupportedBlockchains();

  return Boolean(
    response.find((item) => item.blockchain === blockchain && item.isTestnet),
  );
};
