import { requestAPI, requestAuthAPI } from "src/apis/requestApi";
import {
  AccountControllerApiFactory,
  AccountMeDto,
  RoleDto,
  AccountStatus,
  LoginIpDto,
  VerifyInviteResponse,
  PassphraseResetRequest,
  VerifyEmailDto,
  PassphraseRecoverRequest,
  LoginResponse,
} from "src/__generate__/api";

const accountControllerApi = () =>
  AccountControllerApiFactory(undefined, "", requestAPI());

const accountControllerAuthApi = () =>
  AccountControllerApiFactory(undefined, "", requestAuthAPI());

export type AccountResponse = {
  id: string;
  email: string;
  status: AccountStatus;
  name: string;
  orgId: string;
  roles: RoleDto[];
  loginIps: LoginIpDto[];
};

export enum IpStatus {
  REQUESTED = "REQUESTED",
  VERIFIED = "VERIFIED",
}

export const verifyInvite = async (request: {
  accountId: string;
  identifier: string;
}): Promise<VerifyInviteResponse> => {
  const { accountId, ...rest } = request;
  const response = await accountControllerApi().verifyInvite(accountId, rest);
  return response.data;
};

export const verifyLoginIp = async (request: {
  accountId: string;
  loginIpId: string;
  identifier: string;
}) => {
  const { accountId, loginIpId, ...rest } = request;
  await accountControllerApi().verifyLoginIp(accountId, loginIpId, rest);
};

export const acceptInvite = async (request: {
  accountId: string;
  identifier: string;
  passphrase: string;
  name: string;
}) => {
  const { accountId, ...rest } = request;
  await accountControllerApi().acceptInvite(accountId, rest);
};

export const login = async (request: {
  email: string;
  passphrase: string;
  otpCode?: string;
}): Promise<LoginResponse> => {
  const response = await accountControllerAuthApi().login(request);
  return response.data;
};

export const me = async (): Promise<AccountMeDto> => {
  const response = await accountControllerApi().getMe1({});
  return response.data;
};

export const changeName = async (request: {
  accountId: string;
  name: string;
}) => {
  const { accountId, name } = request;
  await accountControllerApi().changeName(accountId, { name });
};

export const changeAccountPassphrase = async (request: {
  accountId: string;
  passphrase: string;
  newPassphrase: string;
  otpCode: string;
}): Promise<void> => {
  const { accountId, ...rest } = request;
  await accountControllerApi().changePassphrase(accountId, rest);
};

export const verifyAccountPassphrase = async (params: {
  accountId: string;
  passphrase: string;
}): Promise<boolean> => {
  const { accountId, passphrase } = params;
  try {
    await accountControllerApi().verifyPassphrase(accountId, {
      passphrase,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const verifyResetToken = async (params: {
  accountId: string;
  token: string;
}): Promise<boolean> => {
  const { accountId, token } = params;
  try {
    await accountControllerApi().verifyResetToken(accountId, {
      token,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const refreshShortAccessToken = async (request: {
  accountId: string;
  otpCode: string;
}): Promise<AccountMeDto> => {
  const { accountId, otpCode } = request;
  const response = await accountControllerApi().refresh(accountId, { otpCode });
  return response.data;
};

export const resetPassphraseWithResetToken = async (
  request: {
    accountId: string;
  } & PassphraseResetRequest,
): Promise<void> => {
  const { accountId, ...rest } = request;
  await accountControllerApi().resetPassphraseWithResetToken(accountId, rest);
};

export const verifyEmail = async (email: string): Promise<VerifyEmailDto> => {
  const response = await accountControllerApi().verifyEmail(email);
  return response.data;
};

export const recoverPassphrase = async (
  request: PassphraseRecoverRequest,
): Promise<void> => {
  await accountControllerApi().recoverPassphrase(request);
};
