import { TFunction } from "i18next";
import _ from "lodash";

import ko from "src/locales/ko";
import en from "src/locales/en";

const translateLocale = new Map<string, string>()
  .set("en", "EN")
  .set("ko", "KR");

const translate = (names: Paths<typeof ko>, t: TFunction): string => {
  const key = names.join(".");
  if (!t) {
    return _.get(ko, key);
  }
  const targetKey = t(key);
  return _.isNil(targetKey) || targetKey === key ? _.get(ko, key) : targetKey;
};

export { translate, translateLocale };
